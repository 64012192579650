import './map.css'

import { breakpoint } from '../../../site/js/utils/breakpoint'
import { arrayOf, closest, exists } from '../../../site/js/utils/utils'
import { filterPushpins, setLocationFilters, zoomToLocation } from './behaviours'
import { createClusterLayer } from './cluster'
import { createInfobox } from './infobox'
import { createMap } from './map'
import { createPushpins } from './pushpin'

const mediaQueryList = breakpoint('down-sm')

/**
 * Create a bing map with clusters etc..
 * @param {object} configuration
 */
const initialize = async ({
  el,
  state,
  zoomLevel = mediaQueryList.matches ? 1 : 2,
  latitude = 10,
  longitude = 10,
  clusterGridSize = 125,
  clusterMaxZoom = 10, // (when cluster is clicked)
}) => {
  // set the initial map location
  const center = new window.Microsoft.Maps.Location(latitude, longitude)

  // create map
  const map = createMap({ el, center, zoomLevel })

  // create infobox
  const infobox = createInfobox({ center, map })

  // create pushpin generator
  const makePushpins = createPushpins({ infobox, map, state })

  // create cluster layer
  const clusterLayer = await createClusterLayer({ state, map, clusterGridSize, clusterMaxZoom })

  // add clusterLayer to map
  map.layers.insert(clusterLayer)

  // flag
  let viewChanged = false

  // set mobile options when viewport change
  mediaQueryList.onchange = e => {
    e.matches ? (zoomLevel = 1) : (zoomLevel = 2)

    if (map.getZoom() === 1 || map.getZoom() === 2) {
      map.setView({ zoom: zoomLevel })
    }
  }

  /**
   * handles view chaged event
   * - when map is zoomed
   * @param {event} e
   */
  const handleViewChange = e => {
    if (e.cause === 0) {
      return
    }

    // set flag
    viewChanged = true // avoid collisions between all the different map zoom behaviours

    // set filters based on the pushpins within the current view
    setLocationFilters({
      state,
      pushpins: clusterLayer.getDisplayedPushpins(),
    })
  }

  /**
   * Updates the map
   * - when the selected filters change
   */
  const updateMap = () =>
    // make pushpins from data
    arrayOf(makePushpins()).map(pins => {
      // set filtered pushpins on the clusterLayer
      clusterLayer.setPushpins(filterPushpins({ state, pins }))

      // bails if view already changed
      if (viewChanged) {
        viewChanged = false // reset flag
        return
      }

      // zoom to location of selected filters
      zoomToLocation({ state, map, pins, center, zoomLevel })
    })

  /**
   * handles show on map event
   * @param {event} e
   */
  const showOnMapEvent = e =>
    arrayOf(e.target)
      .map(closest('.js-mapapp-show-on-map'))
      .filter(exists)
      .map(el => {
        clusterLayer
          .getPushpins()
          .filter(pin => String(pin.data.id) === String(el.dataset.id))
          .map(pin => infobox.open(pin.data.infobox, pin.getLocation()))
        // scroll map into view
        window.scrollTo(0, e.target.closest('.c-mapapp').offsetTop - document.querySelector('.cmp-header').clientHeight + 24)
      })

  // watch data changes
  state.watch('selectedFilters', updateMap)
  state.watch('mapData', updateMap)

  // bind events
  window.Microsoft.Maps.Events.addHandler(map, 'viewchangeend', handleViewChange)
  el.closest('map-app').addEventListener('click', showOnMapEvent)

  updateMap()
}

/**
 * Initialize the bing map
 * @param {object} configuration
 */
export const BingMap = configuration => {
  document.body.addEventListener(
    'bingMapsReady',
    () => {
      initialize(configuration)
    },
    true,
  )
}
