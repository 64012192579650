/* eslint-disable max-lines */
import {
  RESPONSIVE_BG_IMAGE_SELECTOR,
  RESPONSIVE_IMAGE_SELECTOR,
  ResponsiveBackgroundImage,
  ResponsiveImage,
  ResponsiveImageObserver,
} from '@components/image/responsiveimage/responsiveimage'
import { isAuthorMode } from '@site/js/utils/author-mode'
import { selectAll } from '@site/js/utils/utils'
import Splide from '@splidejs/splide'
import { Grid } from '@splidejs/splide-extension-grid'

/**
 * sliders
 */

const IS_INITIALIZED = 'is-initialized'
const SPLIDE_INIT = 'splide--init'
const SPLIDER_ON_MOUNTED_MOVE = 'mounted move'
const SPLIDER_ON_PAGINATION_MOUNTED = 'pagination:mounted'
const SPLIDER_ON_VISIBLE = 'visible'
const SPLIDER_ON_RESIZE = 'resize'

const selectors = {
  defaultSplideSwiper: '.js-cmp-slider--default .splide:not(.splide--init)',
  coverflowSplideSwiper: '.js-cmp-slider--coverflow .splide:not(.splide--init)',
  eventsCarouselSplideSwiper: '.js-cmp-slider--events-carousel .splide:not(.splide--init)',
  itemsSplideSwiper: '.js-cmp-slider--multiple .splide:not(.splide--init)',
  gallerySplideSwiper: '.js-cmp-slider--gallery .splide:not(.splide--init)',
  duplicateIframe: '.splide__slide--clone iframe',
  caption: '.slider .cmp-image__title',
  pagination: '.swiper-pagination',
  next: '.swiper-button-next',
  prev: '.swiper-button-prev',
  slide: '.swiper-slide',
  wrapper: '.swiper-wrapper',
  editorMode: '.is-editor-mode',
}

const getSlideCount = slider => slider.querySelectorAll('.splide__slide').length

const showControls = slider => {
  return getSlideCount(slider) > 1
}

const relocatePagination = (slider, data) => {
  const controls = slider.querySelector('.splide__arrows')
  const placeholder = slider.querySelector('.splide__pagination-placeholder')
  if (placeholder) {
    placeholder.appendChild(data.list)
  }

  if (showControls(slider)) {
    controls.classList.remove = 'splide__arrows--hidden'
  } else {
    controls.classList.add = 'splide__arrows--hidden'
  }
}

const updateIndicatorsWidth = slider => {
  const end = slider.Components.Controller.getEnd() + 1
  const indicators = slider.root.querySelectorAll('.splide__pagination li')
  indicators.forEach((indicator, id) => {
    indicator.querySelector('button')?.classList.toggle('is-paginationactive', slider.index >= id)
    indicator.style.width = String((100 * (slider.index + 1)) / end) + '%'
  })
}

const handleSliderResponsiveImages = slider => {
  const lazyImages = Array.from(slider.querySelectorAll(RESPONSIVE_IMAGE_SELECTOR))
  lazyImages.map(image => ResponsiveImage(image).init())
  ResponsiveImageObserver(lazyImages)
}

const handleSliderResponsiveBackgroundImages = slider => {
  const lazyBgImages = Array.from(slider.querySelectorAll(RESPONSIVE_BG_IMAGE_SELECTOR))
  lazyBgImages.map(image => ResponsiveBackgroundImage(image).init())
  ResponsiveImageObserver(lazyBgImages)
}

const defaultSpliderSwipersInit = () => {
  const defaultSplideSwipers = selectAll(selectors.defaultSplideSwiper)

  /* Default */
  defaultSplideSwipers.forEach(defaultSplideSwiper => {
    if (isAuthorMode()) {
      handleSliderResponsiveImages(defaultSplideSwiper)
      handleDuplicates(defaultSplideSwiper)
      defaultSplideSwiper.classList.add(IS_INITIALIZED)
      return
    }

    defaultSplideSwiper.classList.add(SPLIDE_INIT)
    const splider = new Splide(defaultSplideSwiper, {
      type: 'loop',
      drag: showControls(defaultSplideSwiper),
      autoplay: defaultSplideSwiper.dataset.autoplay === 'true',
      pauseOnHover: defaultSplideSwiper.dataset.autopauseDisabled === 'false',
      interval: defaultSplideSwiper.dataset.delay || 2000,
    })

    splider.on(SPLIDER_ON_MOUNTED_MOVE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_PAGINATION_MOUNTED, data => relocatePagination(defaultSplideSwiper, data))

    splider.on(SPLIDER_ON_VISIBLE, () => {
      handleSliderResponsiveImages(defaultSplideSwiper)
      handleDuplicates(defaultSplideSwiper)
    })

    splider.mount()
  })
}

const coverFlowSplideSwipersInit = () => {
  const coverflowSplideSwipers = selectAll(selectors.coverflowSplideSwiper)

  /* Coverflow */
  coverflowSplideSwipers.forEach(coverflowSplideSwiper => {
    if (isAuthorMode()) {
      handleSliderResponsiveImages(coverflowSplideSwiper)
      handleDuplicates(coverflowSplideSwiper)
      coverflowSplideSwiper.classList.add(IS_INITIALIZED)
      return
    }

    coverflowSplideSwiper.classList.add(SPLIDE_INIT)
    const splider = new Splide(coverflowSplideSwiper, {
      type: 'loop',
      drag: showControls(coverflowSplideSwiper),
      focus: 'center',
      gap: '6rem',
      padding: '30%',
      updateOnMove: true,
      autoplay: coverflowSplideSwiper.dataset.autoplay === 'true',
      pauseOnHover: coverflowSplideSwiper.dataset.autopause === 'true',
      interval: coverflowSplideSwiper.dataset.delay || 2000,
      breakpoints: {
        600: {
          gap: '3rem',
          perPage: '1',
          padding: '16%',
        },
      },
    })
    splider.on(SPLIDER_ON_MOUNTED_MOVE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_RESIZE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_PAGINATION_MOUNTED, data => relocatePagination(coverflowSplideSwiper, data))

    splider.on(SPLIDER_ON_VISIBLE, () => {
      handleSliderResponsiveImages(coverflowSplideSwiper)
      handleDuplicates(coverflowSplideSwiper)
    })

    splider.mount()
  })
}

const eventsCarouselSplideSwiperInit = () => {
  const eventsCarouselSplideSwipers = selectAll(selectors.eventsCarouselSplideSwiper)

  /* Events Carousel */
  eventsCarouselSplideSwipers.forEach(eventsCarouselSplideSwiper => {
    if (isAuthorMode()) {
      handleSliderResponsiveBackgroundImages(eventsCarouselSplideSwiper)
      handleDuplicates(eventsCarouselSplideSwiper)
      eventsCarouselSplideSwiper.classList.add(IS_INITIALIZED)
      return
    }

    eventsCarouselSplideSwiper.classList.add(SPLIDE_INIT)
    const splider = new Splide(eventsCarouselSplideSwiper, {
      type: 'loop',
      drag: showControls(eventsCarouselSplideSwiper),
      focus: 'center',
      gap: '6rem',
      perPage: '3',
      updateOnMove: true,
      breakpoints: {
        600: {
          gap: '3rem',
          perPage: '1',
          padding: '16%',
        },
      },
      autoplay: eventsCarouselSplideSwiper.dataset.autoplay === 'true',
      pauseOnHover: eventsCarouselSplideSwiper.dataset.autopause === 'true',
      interval: eventsCarouselSplideSwiper.dataset.delay || 2000,
    })

    splider.on(SPLIDER_ON_MOUNTED_MOVE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_RESIZE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_PAGINATION_MOUNTED, data => relocatePagination(eventsCarouselSplideSwiper, data))

    splider.on(SPLIDER_ON_VISIBLE, () => {
      handleSliderResponsiveBackgroundImages(eventsCarouselSplideSwiper)
      handleDuplicates(eventsCarouselSplideSwiper)
    })

    splider.mount()
  })
}

const itemsSplideSwipersInit = () => {
  const itemsSplideSwipers = selectAll(selectors.itemsSplideSwiper)

  /* Multiple Items */
  itemsSplideSwipers.forEach(itemsSplideSwiper => {
    if (isAuthorMode()) {
      handleSliderResponsiveImages(itemsSplideSwiper)
      handleDuplicates(itemsSplideSwiper)
      itemsSplideSwiper.classList.add(IS_INITIALIZED)
      return
    }

    itemsSplideSwiper.classList.add(SPLIDE_INIT)
    const parent = itemsSplideSwiper.parentElement

    const itemCount = parent.className.match(/cmp-slider--multiple-([1-9]{1})/)[1] || '3'
    const focus = itemCount === '3' ? 'center' : '0'

    const splider = new Splide(itemsSplideSwiper, {
      type: 'loop',
      drag: showControls(itemsSplideSwiper),
      focus: focus,
      perPage: itemCount,
      trimSpace: 'move',
      perMove: 1,
      breakpoints: {
        600: {
          perPage: 1,
          padding: { left: '0', right: '10%' },
        },
      },
      autoplay: itemsSplideSwiper.dataset.autoplay === 'true',
      pauseOnHover: itemsSplideSwiper.dataset.autopause === 'true',
      interval: itemsSplideSwiper.dataset.delay || 2000,
    })

    splider.on(SPLIDER_ON_MOUNTED_MOVE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_RESIZE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_PAGINATION_MOUNTED, data => relocatePagination(itemsSplideSwiper, data))

    splider.on(SPLIDER_ON_VISIBLE, () => {
      handleSliderResponsiveImages(itemsSplideSwiper)
      handleDuplicates(itemsSplideSwiper)
    })

    splider.mount()
  })
}

export const gallerySplideSwipersInit = () => {
  const gallerySplideSwipers = selectAll(selectors.gallerySplideSwiper)
  /* Gallery */
  gallerySplideSwipers.forEach(gallerySplideSwiper => {
    if (isAuthorMode()) {
      handleSliderResponsiveImages(gallerySplideSwiper)
      handleDuplicates(gallerySplideSwiper)
      gallerySplideSwiper.classList.add(IS_INITIALIZED)
      return
    }

    gallerySplideSwiper.classList.add(SPLIDE_INIT)
    const splider = new Splide(gallerySplideSwiper, {
      grid: {
        rows: gallerySplideSwiper.dataset.gallerySize === 'big' ? 3 : 1,
        cols: gallerySplideSwiper.dataset.galleryColumns || 3,
        gap: {
          row: '1.5rem',
        },
      },
      drag: showControls(gallerySplideSwiper),
      perPage: 1,
      perMove: 1,
      breakpoints: {
        600: {
          grid: {
            rows: 1,
            cols: 1,
            gap: {
              row: '0',
            },
          },
          padding: { left: '0', right: '10%' },
        },
      },
      type: 'loop',
    })

    splider.on(SPLIDER_ON_MOUNTED_MOVE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_RESIZE, () => updateIndicatorsWidth(splider))

    splider.on(SPLIDER_ON_PAGINATION_MOUNTED, data => relocatePagination(gallerySplideSwiper, data))

    splider.on(SPLIDER_ON_VISIBLE, () => {
      handleSliderResponsiveImages(gallerySplideSwiper)
      handleDuplicates(gallerySplideSwiper)
    })

    // This is needed because images are not loaded when multiple sliders are rendered in tabs component
    window.addEventListener('changetab', () => {
      handleSliderResponsiveImages(gallerySplideSwiper)
      handleDuplicates(gallerySplideSwiper)
    })

    splider.mount({ Grid })
  })
}

/**
 * Reloading iframes(videos) when they are duplicated
 */
const handleDuplicates = function (item) {
  item.querySelectorAll(selectors.duplicateIframe).forEach(iframe => {
    const rand = Math.floor(Math.random() * 1000000 + 1)
    const iframeSrc = iframe.dataset.src
    if (iframeSrc) {
      const sym = iframeSrc.indexOf('?') ? '&' : '?'
      iframe.src = iframeSrc + sym + 'uid=' + rand
    }
  })
}

export const sliders = () => {
  /* Splide */

  // Initialise Splide Sliders
  defaultSpliderSwipersInit()
  coverFlowSplideSwipersInit()
  eventsCarouselSplideSwiperInit()
  itemsSplideSwipersInit()
  gallerySplideSwipersInit()

  // fix image captions when inside slider
  selectAll(selectors.caption).forEach(item => {
    item.closest(selectors.slide)?.appendChild(item)
  })
}

// start sliders after the DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
  sliders()
})
