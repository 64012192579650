const ROW_SELECTOR = '.cmp-download__table-row'
const DOWNLOAD_COMPONENT_SELECTOR = '.cmp-download'

/**
 * Add on click event for the download rows
 */

window.addEventListener('DOMContentLoaded', () => {
  Array.from(document.querySelectorAll(DOWNLOAD_COMPONENT_SELECTOR)).forEach(component => {
    component.addEventListener('click', function (e) {
      e.preventDefault()
      const closestParent = e.target.closest(`${ROW_SELECTOR}`)
      if (closestParent && closestParent.querySelector('a')) {
        window.open(closestParent.querySelector('a').href, '_blank', 'noreferrer, noopener')
      }
    })
  })
})
