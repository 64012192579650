import { createElementFromString } from './../../site/js/utils/utils'

const VIDEO_EMBED_ACTION_SELECTOR = '.cmp-videoembed__action-link'
const VIDEO_EMBED_OVERLAY_SELECTOR = '.cmp-videoembed__poster-overlay'
const VIDEO_EMBED_ACTIVE_CLASS = 'cmp-videoembed--active'
const VIDEO_EMBED_TEMPLATE = videoKey => {
  const origin = `${window.location.protocol}//${window.location.host}`
  return `
    <iframe class='cmp-videoembed__frame absolute-center'
        width='560' height='315'
        src='https://www.youtube-nocookie.com/embed/${videoKey}?&autoplay=1&enablejsapi=1&origin=${origin}&rel=0'
        title='YouTube video player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        enablejsapi
        allowfullscreen>
    </iframe>
  `
}

export const VideoEmbed = component => {
  component.loadVideo = function () {
    if (!component?.dataset?.videoKey) {
      return
    }

    const iframeElement = createElementFromString(VIDEO_EMBED_TEMPLATE(component.dataset.videoKey))

    component.appendChild(iframeElement)

    component.classList.add(VIDEO_EMBED_ACTIVE_CLASS)
  }

  component.init = function () {
    const videoAction = component.querySelector(VIDEO_EMBED_ACTION_SELECTOR)
    const videoOverlay = component.querySelector(VIDEO_EMBED_OVERLAY_SELECTOR)
    videoAction.addEventListener('click', component.loadVideo)
    videoOverlay.addEventListener('click', component.loadVideo)
  }

  return component
}
