import './CommentDialog.scss'

import { ActionButton } from '@react/Comments'

interface CommentDialogProps {
  disabled?: boolean
  submitText?: string
  cancelText?: string
  onSubmit?: () => void
  onCancel?: () => void
}
export function CommentDialog({ disabled = false, submitText, cancelText, onSubmit, onCancel }: CommentDialogProps) {
  return (
    <div className="cmp-comment__dialog" data-testid="comment-dialog">
      <ActionButton variant="secondary" disabled={false} label={cancelText} onClick={onCancel} submit={false} />
      <ActionButton variant="primary" disabled={disabled} label={submitText} onClick={onSubmit} submit={true} />
    </div>
  )
}
