export enum IframeMessageType {
  IframeEventTracking = 'IframeEventTracking',
}

export function isInIframe() {
  return Boolean(window.frameElement)
}

export function trackIframeEvent(eventData) {
  window.parent.postMessage({ type: IframeMessageType.IframeEventTracking, eventData }, window.origin)
}

// test tsc timing
