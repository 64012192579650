import './CommentReply.scss'

import { ActionsVariants, CommentActions, CommentContext, CommentType, useComment } from '@react/Comments'
import { formatDate } from '@site/js/utils/formatDate'
import { useContext } from 'react'

interface CommentReplyProps {
  reply: CommentType
  afterSubmit?: () => void
}
export function CommentReply({ reply, afterSubmit }: CommentReplyProps) {
  const { translations } = useContext(CommentContext)
  const { activeAction, setActiveAction } = useComment({
    comment: reply,
  })

  return (
    <li className="cmp-comment-reply" data-testid="reply-item">
      <span className="cmp-button__icon cmp-button__icon--reply"></span>
      <div className="cmp-comment-reply__details">
        <span className="cmp-comment-reply__header">
          <h5 className="cmp-comment-reply__author">{reply.authorName}</h5>
          <span className="cmp-comment-reply__date">{formatDate(reply.date, translations.temporal)}</span>
        </span>
        {activeAction !== ActionsVariants.EDIT && <p className="cmp-comment__content">{reply.content}</p>}
        {reply.editable && (
          <div className="cmp-comment__controls">
            <CommentActions comment={reply} replyDisabled likeDisabled sendActiveAction={setActiveAction} afterSubmitting={afterSubmit} />
          </div>
        )}
      </div>
    </li>
  )
}
