import { isTouchOnly } from '../../site/js/utils/utils'

const Share = () => {
  const toolbarShare = document.querySelector('.cmp-interaction-toolbar__share')
  const init = () => {
    if (!toolbarShare) {
      return
    }
    const slideoutVisibleClass = 'cmp-interaction-toolbar__share-slideout--visible'
    let slideoutVisible = false

    if (isTouchOnly) {
      toolbarShare.addEventListener('click', () => toolbarSlideout())
    } else {
      const events = ['mouseenter', 'mouseleave']
      events.forEach(event => toolbarShare.addEventListener(event, () => toolbarSlideout()))
    }

    const toolbarSlideout = () => {
      if (!slideoutVisible) {
        toolbarShare.classList.add(slideoutVisibleClass)
        slideoutVisible = true
      } else {
        toolbarShare.classList.remove(slideoutVisibleClass)
        slideoutVisible = false
      }
    }

    if (toolbarShare.dataset.yammerUrl) {
      // initialize yammer sharing
      const data = toolbarShare.dataset
      const options = {
        customButton: true,
        classSelector: 'cmp-interaction-toolbar__share-yammer',
        defaultMessage: data.pageTitle,
        pageUrl: data.pageUrl,
      }

      if (window.yam) {
        window.yam.platform.yammerShare(options)
      }
    }
  }
  return {
    init,
  }
}
export { Share }
