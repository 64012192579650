import { createState } from '../../site/js/utils/state'

/**
 * default state
 */
const defaultState = {
  initialUrlParams: {},
  selectedFilters: {},
  mapData: [],
  listData: [],
  listTotal: 0,
  listTotalPages: 0,
  filtersData: [],
  loading: true,
}

/**
 * creates a new observeble
 * @param {object} config - state
 */
export const State = config => createState(Object.assign({}, defaultState, config))
