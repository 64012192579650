import './ActionButton.scss'

import { cls } from '@react/utils/classname'

interface ActionButtonProps {
  variant?: string
  submit?: boolean
  disabled?: boolean
  label: string
  onClick: () => void
  classNames?: string
}
export function ActionButton({ variant = 'primary', submit = false, disabled = false, label, onClick, classNames = '' }: ActionButtonProps) {
  return (
    <div
      className={cls({
        button: true,
        [classNames]: true,
        'cmp-button--primary-positive': variant === 'primary',
        'cmp-button--secondary-positive': variant === 'secondary',
      })}
    >
      <button
        className={cls({
          'cmp-button': true,
          'cmp-button--disabled': disabled,
        })}
        disabled={disabled}
        onClick={onClick}
        type={submit ? 'submit' : 'button'}
      >
        <span className="cmp-button__text">{label}</span>
      </button>
    </div>
  )
}
