import { Email } from './email'
import { Like } from './like'
import { Share } from './share'
import { Yammer } from './yammer'

window.addEventListener('DOMContentLoaded', () => {
  const interactionToolbar = document.querySelector('.cmp-interaction-toolbar')
  if (!interactionToolbar) {
    return
  }

  Like().init()
  Share().init()
  Email().init()
  Yammer().init()
})
