import './Countdown.scss'

import { useCountdown, useFormattedLabel, useFormattedNumber } from '@react/Countdown/useCoundown'
import { useRef } from 'react'

interface CountdownProps {
  model: string
  labels: string
}

export function Countdown({ model, labels }: CountdownProps) {
  const parsedModel = JSON.parse(model)
  const ref = useRef(null)
  const { days, hours, minutes, seconds } = useCountdown(parsedModel.countdowndate, ref)

  const { dayLabel, hourLabel, minuteLabel, secondLabel } = useFormattedLabel({ labels, days, hours, minutes, seconds })

  return (
    <div className="countdown-timer__wrapper" ref={ref}>
      <h2 className="countdown-timer__title">{parsedModel.countdowntitle}</h2>
      <section className="countdown-timer">
        <div className="countdown-timer__block">
          <div className="countdown-timer__digit">{useFormattedNumber(days)}</div>
          <p className="countdown-timer__text">{dayLabel}</p>
        </div>
        <div className="countdown-timer__block">
          <div className="countdown-timer__digit">{useFormattedNumber(hours)}</div>
          <p className="countdown-timer__text">{hourLabel}</p>
        </div>
        <div className="countdown-timer__block">
          <div className="countdown-timer__digit">{useFormattedNumber(minutes)}</div>
          <p className="countdown-timer__text">{minuteLabel}</p>
        </div>
        <div className="countdown-timer__block">
          <div className="countdown-timer__digit">{useFormattedNumber(seconds)}</div>
          <p className="countdown-timer__text">{secondLabel}</p>
        </div>
      </section>
    </div>
  )
}
