import { intersectionHandler, ResponsiveImage as optimizeImage } from '@components/image/responsiveimage/responsiveimage'
import { FetchTeaserImage } from '@react/TheWall'
import { useEffect, useRef } from 'react'

type ResponsiveImgProps = {
  config: FetchTeaserImage
  additionalClasses?: string
}

const options = {
  rootMargin: '20%',
}

export default function ResponsiveImg({ config, additionalClasses = '' }: ResponsiveImgProps) {
  const imageRef = useRef(null)

  useEffect(() => {
    const image = optimizeImage(imageRef.current)
    image.init()
    const options = {
      rootMargin: '20%',
    }
    if ('IntersectionObserver' in window) {
      const lazyImageObserver = new IntersectionObserver(intersectionHandler, options)
      lazyImageObserver.observe(image)
    }
  }, [])

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    imageRef.current.querySelectorAll('source').forEach(e => e.remove())
    const image = optimizeImage(imageRef.current)
    image.init()
    image.replaceImage()

    if ('IntersectionObserver' in window) {
      const lazyImageObserver = new IntersectionObserver(intersectionHandler, options)
      lazyImageObserver.observe(image)
    }
  }, [config])

  return (
    <div className={`cmp-image cmp-image--responsive ${additionalClasses}`}>
      {config && config?.pattern && config?.placeholder && (
        <div ref={imageRef} className="cmp-responsive-image cmp-responsive-image--lazy" data-image-src={JSON.stringify(config)}>
          <picture>
            <img src={config.placeholder} />
          </picture>
        </div>
      )}
    </div>
  )
}
