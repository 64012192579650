import { isMobile } from '@site/js/utils/breakpoint'
import { pxToRem } from '@site/js/utils/px-to-rem'

export const NavigationLine = ({ className, navigation }) => {
  const navItems: Node[] = navigation.querySelectorAll(`.${className.itemsLevel0}`)
  const navLine = navigation.querySelector(`.${className.navLine}`)
  const activeItem = navigation.querySelector(`.${className.itemsLevel0}.${className.activeItem}`)
  const headerNavigation = document.querySelector('.cmp-header__navigation')

  const init = () => {
    if (isMobile) {
      return
    }

    setNavLineStyles(activeItem)

    navItems?.forEach(navItem => {
      navItem.addEventListener('mouseenter', (e: Event) => setNavLineStyles(e.target as HTMLElement))
      navItem.addEventListener('mouseleave', resetNavLineStyles)
    })
  }

  const setNavLineStyles = (target: HTMLElement) => {
    if (target) {
      navLine.style.left = pxToRem(target.getBoundingClientRect().left - (headerNavigation?.getBoundingClientRect().left || 0))
      navLine.style.width = pxToRem(target.clientWidth)
    } else {
      navLine.style = null
    }
  }

  const resetNavLineStyles = () => {
    if (
      (!activeItem && !navigation.classList.contains(className.expandedNavigation)) ||
      navigation.classList.contains(className.expandedNavigation)
    ) {
      return
    }

    const navLineInitLeft = activeItem?.getBoundingClientRect().left - (headerNavigation?.getBoundingClientRect().left || 0) || null
    const navLineInitWidth = activeItem?.clientWidth || null

    navLine.style.left = pxToRem(navLineInitLeft)
    navLine.style.width = pxToRem(navLineInitWidth)
  }

  return { init, resetNavLineStyles }
}
