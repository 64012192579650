import './TheWallTabListItem.scss'

import { TheWallContext } from '@react/TheWall/TheWallContext'
import { cls } from '@react/utils/classname'
import { useContext } from 'react'

type TheWallTabListItemProps = {
  topic: string
}

export default function TheWallTabListItem({ topic }: TheWallTabListItemProps) {
  const { setCurrentTopic, currentTopic } = useContext(TheWallContext)

  const handleClick = () => {
    setCurrentTopic(topic)
  }

  return (
    <li
      data-dm="hpwall.button"
      className={cls({
        'cmp-the-wall-tab-list-item--active': currentTopic === topic,
        'cmp-the-wall-tab-list-item': true,
      })}
      onClick={handleClick}
    >
      {topic}
    </li>
  )
}
