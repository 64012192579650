export const tableRowClickEvent = (e: Event, rowClassName: string) => {
  const target = e.target as HTMLElement
  const targetLinks = target.querySelectorAll('a')
  if (targetLinks && targetLinks.length === 1) {
    window.open(targetLinks[0].href, '_blank', 'noreferrer, noopener')
    return
  }
  const closestParentLinks = target.closest(`.${rowClassName}`).querySelectorAll('a')
  if (closestParentLinks.length === 1) {
    window.open(closestParentLinks[0].href, '_blank', 'noreferrer, noopener')
  }
}
