import './AgendaSlider.scss'

import { cls } from '@react/utils/classname'
import { useEffect, useState } from 'react'

type AgendaSliderProps = {
  speakersContainerId: string
}

const hiddenSliderItemClass = 'cmp-agenda-slider__item--is-hidden'

export default function AgendaSlider({ speakersContainerId }: AgendaSliderProps) {
  const [currentSlide, setCurrentSlide] = useState(1)
  const [parentElement, setParentElement] = useState(null)
  const [sliderElements, setSliderElements] = useState([])

  const numberOfSlides = sliderElements.length
  const paginationItemWidth = numberOfSlides > 0 ? 100 / numberOfSlides : 0

  useEffect(() => {
    setParentElement(document.getElementById(speakersContainerId))
  }, [speakersContainerId])

  useEffect(() => {
    setSliderElements(parentElement ? Array.from(parentElement.children) : [])

    if (parentElement) {
      const offset = -(currentSlide - 1) * 100
      parentElement.style.transform = `translateX(${offset}%)`
      sliderElements.forEach((sliderElement, index) => {
        index === currentSlide - 1 ? sliderElement.classList.remove(hiddenSliderItemClass) : sliderElement.classList.add(hiddenSliderItemClass)
      })
    }
  }, [parentElement, currentSlide])

  const handleSlideNext = () => {
    if (currentSlide === numberOfSlides) return
    setCurrentSlide(currentSlide + 1)
  }

  const handleSlidePrev = () => {
    if (currentSlide === 1) return
    setCurrentSlide(currentSlide - 1)
  }

  const handleNavigateToSlide = (slideNumber: number) => {
    if (currentSlide === slideNumber) return
    setCurrentSlide(slideNumber)
  }

  return (
    <div className="cmp-agenda-slider">
      <div className="cmp-agenda-slider__pagination-placeholder">
        <ul className="cmp-agenda-slider__pagination">
          {sliderElements.map((element, index) => (
            <li key={element.dataset.speakerName} style={{ width: `${paginationItemWidth}%` }}>
              <button
                className={cls({
                  'cmp-agenda-slider__pagination-page--is-active': currentSlide >= index + 1,
                  'cmp-agenda-slider__pagination-page': true,
                })}
                type="button"
                aria-label={`Go to slide ${index + 1}`}
                aria-current={currentSlide === index + 1}
                onClick={() => handleNavigateToSlide(index + 1)}
              ></button>
            </li>
          ))}
        </ul>
      </div>
      <div className="cmp-agenda-slider__arrows">
        <button className="cmp-agenda-slider__arrow cmp-agenda-slider__arrow--is-previous" type="button" onClick={handleSlidePrev}></button>
        <button className="cmp-agenda-slider__arrow cmp-agenda-slider__arrow--is-next" type="button" onClick={handleSlideNext}></button>
      </div>
    </div>
  )
}
