const listWrappers = document.querySelectorAll('.list-wrapper')
const initialLoadingSpinnerClass = '.cmp-filterbar__initial-loading-spinner'

const observer = new MutationObserver(entries => {
  entries.forEach(entry => {
    if ((entry.target as HTMLElement).querySelector(initialLoadingSpinnerClass)) {
      listWrappers[0].querySelector<HTMLElement>(initialLoadingSpinnerClass).style.visibility = 'visible'
    }
  })
})

const handleFilterbarInitialLoadingSpinner = () => {
  listWrappers?.forEach(listWrapper => observer.observe(listWrapper, { childList: true, subtree: true }))
}

export default handleFilterbarInitialLoadingSpinner
