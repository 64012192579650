// https://stackoverflow.com/questions/40603913/search-recursively-for-value-in-object-by-property-name
export function getDataByKey(data, keyName) {
  for (const key in data) {
    const entry = data[key]
    if (key === keyName) {
      return entry
    }
    if (typeof entry === 'object') {
      const found = getDataByKey(entry, keyName)
      if (found) {
        return found
      }
    }
  }
}
