export const header = document.querySelector('.cmp-header')
export const overlay = document.querySelector('.cmp-header__overlay')
export const navigationWrapper = document.querySelector('.cmp-header__navigation')
export const toggleMenuButton = document.querySelector('.cmp-header__toggle-button')
export const closeDropdownButtons = document.querySelectorAll('.cmp-header__dropdown .cmp-header__close-button')
export const toggleDropdownButtons = document.querySelectorAll('.cmp-header__dropdown .cmp-header__top-item-title')
export const search = document.querySelector('.cmp-header__meta-search')
export const searchDropdowns = document.querySelectorAll('.cmp-header__meta-search')
export const searchItems = document.querySelectorAll('.saas-search-redirect__link')
export const sticky = document.querySelector('.cmp-button--sticky')
export const navigation = document.querySelector('.cmp-navigation')
export const buttonBack = document.querySelector('.cmp-navigation__back-button')

export const visibleOverlayClassName = 'cmp-header__overlay--visible'
export const stickyHeaderClassName = 'cmp-header--sticky'
export const dropdownClassName = 'cmp-header__dropdown'
export const noScrollClassName = 'no-scroll'
export const openMenuClassName = 'cmp-header--menu-open'
export const openDropdownClassName = 'cmp-header__dropdown--open'
export const fixedDropdownClassName = 'cmp-header__dropdown--fixed'
export const logoutButtonClassName = 'cmp-header__logout'
export const dropdownContentSelector = '.cmp-header__top-item-content'

export const menuScrolled = 'cmp-header--menu-scrolled'
export const activeItemSelector = '.cmp-navigation__item--active'
export const ariaExpanded = 'aria-expanded'
export const navigationItemsSelector = '.cmp-navigation__item'
export const navigationTitleClassName = 'cmp-navigation__title'
export const ANCHOR_TAG = 'A'
