import { isInIframe } from '@site/js/utils/iframe'
import { pxToRem } from '@site/js/utils/px-to-rem'

import { afterSmoothScroll, getFieldLabel, getFieldNode } from './form-common'

const imageWrapperSelector = '.infobox-wrapper'
const isCorrectClass = 'is-correct'
const isInvalidClass = 'is-invalid'
const ariaInvalidAttribute = 'aria-invalid'

export const getBackendValidationErrors = (form, responseData = null) => {
  const validationErrors = new Map()

  const fieldErrors = responseData
    ? responseData.fieldErrors.map(fieldError => {
        const fieldName = String(fieldError).split(':')[0]
        return { fieldName, errorMessage: fieldError.substring(fieldName.length + 2) }
      })
    : []

  if (fieldErrors.length === 0) {
    return null
  }

  const invalidFieldsSelector = fieldErrors.map(fieldError => '[name="' + fieldError.fieldName + '"]').join(', ')
  const invalidFields = form.querySelectorAll(invalidFieldsSelector)

  for (const item of invalidFields) {
    if (item.tagName === 'FIELDSET') {
      continue
    }

    const fieldNode = getFieldNode(item)
    const contextualMessage = getFieldBackendContextualErrorMessage(item, fieldErrors)
    const fieldLabel = getFieldLabel(fieldNode)
    validationErrors.set(item, {
      inputEl: item,
      fieldNode: fieldNode,
      fieldLabel,
      contextualMessage,
      summaryMessage: getFieldSummaryErrorMessage(fieldLabel, contextualMessage),
      summaryHref: item.id ? '#' + item.id : null,
    })
  }

  return validationErrors
}

const getFieldBackendContextualErrorMessage = (inputEl, fieldErrors) => {
  return fieldErrors.find(fieldError => fieldError.fieldName === inputEl.name).errorMessage
}

export const getFrontendValidationErrors = form => {
  const validationErrors = new Map()
  const invalidFields = form.querySelectorAll(':invalid')

  for (const item of invalidFields) {
    if (item.type === 'radio' || item.type === 'checkbox') {
      continue
    }
    const fieldNode = getFieldNode(item)
    const contextualMessage = getFieldFrontendContextualErrorMessage(fieldNode)
    const fieldLabel = getFieldLabel(fieldNode)
    validationErrors.set(item, {
      inputEl: item,
      fieldNode: fieldNode,
      fieldLabel,
      isEmpty: item.validity.valueMissing,
      contextualMessage,
      summaryMessage: getFieldSummaryErrorMessage(fieldLabel, contextualMessage),
      summaryHref: item.id ? `#${item.id}` : null,
    })
  }
  return validationErrors
}

const getFieldFrontendContextualErrorMessage = fieldNode => {
  let fieldErrorMessage
  const formField = fieldNode.querySelector('.form-field')
  const maxLength = formField?.maxLength
  const fieldValue = formField?.value
  const constraintMessage =
    fieldValue && maxLength > 0 && fieldValue.length > maxLength
      ? fieldNode.getAttribute('data-maxlength-message')
      : fieldNode.getAttribute('data-cmp-constraint-message')

  const requiredField = fieldNode.getAttribute('data-cmp-required-message')
  const requiredDropdown = fieldNode.querySelector('.cmp-form-options__field--drop-down')?.getAttribute('data-cmp-required-message')

  if (constraintMessage) {
    fieldErrorMessage = constraintMessage
  } else if (requiredField || requiredDropdown) {
    fieldErrorMessage = requiredField || requiredDropdown
  }

  return fieldErrorMessage || ''
}

const getFieldSummaryErrorMessage = (fieldLabel, contextualMessage) => {
  return `${fieldLabel}: ${contextualMessage}`
}

const scrollToInfobox = () => {
  const infoboxEl = document.querySelector(imageWrapperSelector)
  const topOffset = isInIframe() ? document.querySelector('body').clientHeight + 10 : document.querySelector('.cmp-navigation').clientHeight + 10
  infoboxEl.style.scrollMarginTop = pxToRem(topOffset)

  if (isInIframe()) {
    infoboxEl.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  } else {
    infoboxEl.scrollIntoView({
      behavior: 'smooth',
    })
  }

  afterSmoothScroll(() => {
    const infoboxFocusableEl = document.querySelector('.infobox-focus')
    infoboxFocusableEl.focus()
  })
}

const isNotFileType = elementType => Boolean(elementType !== 'file')

export const printFormValidationSuccess = form => {
  form.classList.add('cmp-form--success')
  const infoboxEl = document.querySelector(imageWrapperSelector)
  infoboxEl.dataset.isFormValid = true
}

/**
 * If fields in the form are invalid, add the invalid class and show message
 * @param {element} form form element that contains invalid fields
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export const printFormValidationErrors = formValidationErrors => {
  const infoboxEl = document.querySelector(imageWrapperSelector)
  infoboxEl.dataset.isFormValid = false

  if (formValidationErrors === null) {
    scrollToInfobox()
    return
  }

  const invalidList = []
  formValidationErrors.forEach(error => {
    resetElementValidation(error.inputEl)
    if (error.isEmpty) {
      if (isNotFileType(error.inputEl.type)) {
        error.fieldNode.classList.add('is-empty')
        error.fieldNode.classList.remove(isCorrectClass)
      }
      error.inputEl.setAttribute(ariaInvalidAttribute, 'true')
    } else {
      if (isNotFileType(error.inputEl.type)) {
        error.fieldNode.classList.add(isInvalidClass)
        error.fieldNode.classList.remove(isCorrectClass)
      }
      error.inputEl.setAttribute(ariaInvalidAttribute, 'true')
    }

    if (!error.fieldNode.querySelector('.cmp-form__error-message')) {
      if (!error.fieldNode.classList.contains('cmp-form-captcha')) {
        printContextualErrorMessage(error.fieldNode, error.contextualMessage)
        invalidList.push({ message: error.summaryMessage, href: error.summaryHref })
      } else {
        invalidList.push({ message: error.summaryMessage, href: error.summaryHref })
      }
    }
  })

  infoboxEl.dataset.invalidList = JSON.stringify(invalidList)
  scrollToInfobox()
}

export const printContextualErrorMessage = (fieldNode, contextualMessage) => {
  const errorText = document.createElement('span')
  errorText.classList.add('cmp-form__error-message')

  errorText.innerHTML = contextualMessage

  fieldNode.append(errorText)
}

/**
 * Remove invalid class from the invalid input in the form
 * @param {element} el input field that was invalid
 */
export const resetElementValidation = el => {
  const fieldNode = getFieldNode(el)
  if (el.value && el.value.trim().length === 0) {
    el.value = ''
  }
  const isInvalid = el.validity.valueMissing || el.validity.typeMismatch || el.validity.patternMismatch
  fieldNode.classList.remove('is-empty')
  fieldNode.classList.remove(isInvalidClass)
  if (isInvalid) {
    fieldNode.classList.remove(isCorrectClass)
  }
  fieldNode.querySelector('input.form-field, select.form-field, teaxtarea.form-field')?.setAttribute(ariaInvalidAttribute, 'false')
  fieldNode.querySelector('.cmp-form__error-message')?.remove()
  if ((!fieldNode.classList.contains('is-empty') || !fieldNode.classList.contains(isInvalidClass)) && !isInvalid && isNotFileType(el.type)) {
    fieldNode.classList.add(isCorrectClass)
  }
}

/**
 * Remove invalid class from all radio in the invalid fieldset in the form
 * @param {element} el input field that was checked
 */
export const resetGroupValidation = el => {
  const fieldNode = getFieldNode(el)
  if (el.type === 'radio') {
    fieldNode.classList.remove(isInvalidClass)
    fieldNode.querySelectorAll('label').forEach(item => {
      item.classList.remove('is-empty')
      item.classList.remove(isInvalidClass)
    })
  }
}
