import './filters.css'

import { arrayOf, closest, exists } from '../../../site/js/utils/utils'
import { Filter } from './filter.js'
import { Search } from './search.js'

/**
 * handles filter event if its a search query
 * @param {event} e
 * @param {object} state
 */
const handlerSearchFilter = (e, state) => {
  const selectFilters = { ...state.selectedFilters }
  selectFilters.search = e.target.value
  e.target.blur()

  if (selectFilters.search === '') {
    delete selectFilters.search
  }

  state.selectedFilters = selectFilters
}

/**
 * Select filter event handler
 * @param {event} e
 * @param {object} state
 */
const onFilterChange = (e, state) => {
  // if filter is 'search' - set search
  if (e.target.name === 'search') {
    handlerSearchFilter(e, state)
    return
  }

  // otherwise set the selected filter
  const selectedOptions = Array.from(e.target.options).filter(option => option.selected)
  const values = selectedOptions.map(item => item.value)
  const selected = { ...state.selectedFilters }
  selected[e.target.name] = values

  // if filter is 'country' - reset 'city' filter
  if (e.target.name === 'country') {
    delete selected.city
  }

  // if filter is set to the default value - delete it
  if (values[0] === '') {
    delete selected[e.target.name]
  }

  if (selected.page) {
    delete selected.page
  }

  state.selectedFilters = selected
  dispatchEvent(new CustomEvent('mapapp-filters-changed'))
}

/**
 * resets filters
 * @param {object} state
 */
const resetFilters = state => () => {
  state.selectedFilters = {}
  dispatchEvent(new CustomEvent('mapapp-filters-reset'))
  return state
}

/**
 * Template for the filters
 * @param {object} state
 * @param {bool} search - config
 */
const template = ({ state = {}, config }) => `
  <div class="c-mapapp__filters-container">

    ${config.search === 'false' ? '' : Search(state, config)}

    <div class="c-mapapp__filters-filters">
      ${state.filtersData.map(Filter(config)).join('')}
    </div>

    ${
      config.resetButton === 'false'
        ? ''
        : `
      <div class="c-mapapp__reset">
        <button class="c-mapapp__reset-button">
          <span class="icon-reset u-color--primary"></span>
          ${config.translations.resetButton || 'Reset criteria'}
        </button>
      </div>
    `
    }

  </div>
`

/**
 * Creates filters
 * @param {object} config
 */
export const Filters = ({
  el,
  state,
  config,
  selectors = {
    resetButton: '.c-mapapp__reset-button',
  },
}) => {
  const update = () => {
    el.innerHTML = template({ state, config })
  }

  state.watch('filtersData', update)

  el.addEventListener('change', e => onFilterChange(e, state))

  el.addEventListener('click', e => arrayOf(e.target).map(closest(selectors.resetButton)).filter(exists).map(resetFilters(state)))
}
