export type CommentType = {
  date: string
  pagePath?: string
  content: string
  authorName: string
  id: string
  editable: boolean
  numOfLikes: number
  numOfReplies: number
  username?: string
  likedByUser: boolean
  visible?: boolean
}

export type CommentsTranslationType = {
  loadMore: string
  comment: string
  comments: string
  thankYou: string
  commentCannotBeAdded: string
  sortbyoldest: string
  sortbynewest: string
  sortbymostliked: string
  addComment: string
  editComment: string
  editCommentConfirm: string
  deleteComment: string
  deleteCommentConfirm: string
  post: string
  reply: string
  replies: string
  addReply: string
  like: string
  likes: string
  cancel: string
  temporal: {
    minute: string
    minutes: string
    hour: string
    justNow: string
    hours: string
    day: string
    days: string
    timeAgo: string
  }
}

export type SortCommentsType = {
  sort: string
  sortOrder: string
}

export type useCommentsType = {
  numOfPages: number
  comments: [] | CommentType[]
  loadMore: (sortType: SortCommentsType) => void
  loadComments: (params?: Record<string, unknown>) => void
  translations: CommentsTranslationType
  numOfComments: number
  handleChangeCommentText: (value: string) => void
  commentFieldValue: string
  addComment: () => void
  sortComments: (sortType: SortCommentsType) => void
  isLoadMoreVisible: boolean
}

export type SortCommentsOptionType = {
  id: string
  name: string
  sort: string
  sortOrder: string
}

export type CommentContextType = {
  loadComments: (params?) => void
  translations: CommentsTranslationType
  deleteOwnCommentUrl: string
  likeCommentUrl: string
  createReplyUrl: string
  updateOwnCommentUrl: string
  repliesUrl: string
  commentsUrl: string
  createCommentUrl: string
  sortType: SortCommentsType
}

export enum ActionsVariants {
  EDIT = 'edit',
  SHOW_DELETE_DIALOG = 'show_delete_dialog',
  REPLY = 'reply',
  DELETE_COMMENT = 'delete_comment',
}
