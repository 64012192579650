type DataProps = {
  key: string
  property: string
  value: string
}

const renderDefaultTags = (tags: string[]) => {
  const tagsTemplate = tags?.map((tag: string) => `<li class="cmp-taglist__item">${tag}</li>`).join(' ')
  return `<ul class="cmp-taglist">${tagsTemplate}</ul>`
}

const renderRemovableTags = (removableTags: DataProps[]) => {
  return `<ul class="cmp-taglist cmp-taglist--is-removable">${removableTags
    .map(
      (removableTag: DataProps) => `
        <li class="cmp-taglist__item">
            <button class="cmp-taglist__item-button" data-property="${removableTag.property}" data-key="${removableTag.key}" data-value="${removableTag.value}">
                <span class="cmp-taglist__item-text">${removableTag.value}</span>
                <span class="cmp-taglist__item-icon icon-close"></span>
            </button>
        </li>
        `,
    )
    .join(' ')}</ul>`
}
export const tagListTemplate = (data: DataProps[] | string[], type?: string) => {
  return type === 'removable' ? renderRemovableTags(data as DataProps[]) : renderDefaultTags(data as string[])
}
