import { cls } from '@react/utils/classname'
import { isMobile } from '@site/js/utils/breakpoint'
import { parseJson } from '@site/js/utils/utils'

/**
 * Careers list template
 * @param {object} state
 * @param {object} config
 */
export const template = (state, config) => {
  const shortlistData = parseJson(localStorage.getItem('shortlist')) || {}

  return `
  <div class="c-mapapp__list--careers">
    <p class="c-mapapp__list-results">
      ${
        config.translations.screenDisplayResults
          ? config.translations.screenDisplayResults.replace('{0}', state.listTotal)
          : `here are ${state.listTotal} jobs matching your criteria.`
      }
    </p>
    <table class="c-mapapp__list-table">
      <thead  class="${cls({
        'u-visually-hidden': isMobile,
      })}">
        <tr>
          <th scope="col">${config.translations.titleColumn || 'Job title'}</th>
          <th scope="col">${config.translations.locationColumn || 'Location'}</th>
          <th scope="col">${config.translations.functionColumn || 'Job function'}</th>
          <th scope="col">${config.translations.hierarchyColumn || 'Type of role'}</th>
        </tr>
      </thead>
      <tbody>
      ${state.listData
        .map(
          item => `
        <tr>
          <td>
            <a href="${item.path}" class="c-mapapp__list-link">${item.title}</a>

            <button value="${shortlistData[item.id] ? 'remove' : 'add'}"  data-item='${JSON.stringify(item).replace(/'/g, '&apos;')}' data-id="${
            item.id
          }" class="js-toggle-in-shortlist c-shortlist__toggle">
              <span class="c-shortlist__toggle-add">
                <span class="icon-add icon--md u-color--primary"></span>
                <span>${config.translations.shortlistButtonAdd || 'Add to shortlist'}</span>
              </span>
              <span class="c-shortlist__toggle-remove">
                <span class="icon-remove icon--md u-color--primary"></span>
                <span>${config.translations.shortlistButtonRemove || 'Remove from shortlist'}</span>
              </span>
            </button>

          </td>
          <td>${item.location}</td>
          <td>${item.function}</td>
          <td>${item.hierarchy}</td>
        </tr>
      `,
        )
        .join('')}
      </tbody>
    </table>
  </div>
`
}
