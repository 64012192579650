import { isMobile } from '@site/js/utils/breakpoint'
import debounce from '@site/js/utils/debounce'
import { trackEvent } from '@site/js/utils/tracking'

const classes = {
  slideUpImage: 'cmp-slide-up-teaser__item--slide-up',
  slideDownImage: 'cmp-slide-up-teaser__item--slide-down',
  slideUpItem: '.cmp-slide-up-teaser__item',
  slideUpItemInfoLink: '.cmp-slide-up-teaser__item-info-link',
  slideUpItemInfoTitle: '.cmp-slide-up-teaser__item-info-title',
  slideUpItemInfoLinkText: '.cmp-slide-up-teaser__item-info-link-text',
}

const setActiveSlideUp = () => {
  const slideUpItems = document.querySelectorAll(classes.slideUpItem)

  if (isMobile && !slideUpItems.length) {
    return
  }

  const handleClick = teaser => {
    teaser.classList.remove(classes.slideDownImage)
    teaser.classList.add(classes.slideUpImage)
  }

  const handleScroll = () => {
    slideUpItems.forEach(teaser => {
      teaser.classList.add(classes.slideDownImage)
      teaser.classList.remove(classes.slideUpImage)
    })
  }

  const handleTracking = (title, text, href) => {
    trackEvent({
      event: 'interaction',
      eventSource: 'aem',
      action: 'click',
      componentName: 'slideUpTeaser',
      interactionType: 'link',
      title,
      text,
      href,
    })
  }

  if (isMobile) {
    slideUpItems.forEach(teaser => {
      teaser.addEventListener('click', () => handleClick(teaser))
    })
  }

  window.addEventListener('scroll', debounce(handleScroll, 200))

  slideUpItems.forEach(teaser => {
    const title = teaser.querySelector(classes.slideUpItemInfoTitle).innerHTML
    const text = teaser.querySelector(classes.slideUpItemInfoLinkText).innerHTML
    const href = teaser.querySelector(classes.slideUpItemInfoLink).getAttribute('href')

    teaser.querySelector(classes.slideUpItemInfoLink).addEventListener('click', () => handleTracking(title, text, href))
  })
}

setActiveSlideUp()
