import { setLocationFilters, zoomTo } from './behaviours'

/**
 * creates an array of pushpins
 * @param {object} configuration
 */
export const createPushpins =
  ({ infobox, state, map }) =>
  () =>
    state.mapData.map(item => {
      const location = new window.Microsoft.Maps.Location(item.latitude, item.longitude)

      const pushpin = new window.Microsoft.Maps.Pushpin(location, {
        text: item.count || '1',
        item,
        color: 'hsl(350, 100%, 45%)',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50"><circle cx="25" cy="25" r="8" stroke="hsla(350, 100%, 45%, 0.5)" stroke-width="8" fill="hsl(350, 100%, 45%)" /></svg>',
        anchor: new window.Microsoft.Maps.Point(25, 25),
        textOffset: new window.Microsoft.Maps.Point(0, 17), // Subtract 8 to compensate for height of text.
      })

      // set data
      pushpin.data = item

      // bind event
      window.Microsoft.Maps.Events.addHandler(pushpin, 'click', _e => {
        zoomTo({ map, pushpins: [pushpin] })
        setLocationFilters({ state, pushpins: [pushpin] })
        infobox.open(pushpin.data.infobox, pushpin.getLocation())
      })

      return pushpin
    })
