const CLASSES = {
  VISIBILITY: 'mobile-gallery__modal--visible',
  CONTROLS_VISIBILITY: 'swiper-controls--visible',
  SHADOW: 'media-gallery-mobile__box-shadow-btn',
  ITEMS: '.js-media-gallery__image',
}

function MediaGallery(element) {
  const galleryID = element.dataset.galleryId
  const modal = document.querySelector(`.mobile-gallery__modal[data-modal-id="${galleryID}"]`)
  const slides = element.querySelectorAll('.media-gallery__desktop .swiper-slide')
  const controls = element.querySelector('.swiper-controls')

  function items(isGallery) {
    if (isGallery) {
      return element.querySelectorAll(CLASSES.ITEMS)
    }
    return modal.querySelectorAll(CLASSES.ITEMS)
  }

  function galleryButton() {
    return element.querySelector('.js-gallery-button')
  }

  function shadowButton() {
    return element.querySelector('.media-gallery-mobile__box-shadow')
  }

  function closeButton() {
    return modal.querySelector('.mobile-gallery-header__close-btn')
  }

  function emitEvent(item) {
    window.dispatchEvent(
      new CustomEvent('cmp-media-gallery-image-click', {
        detail: {
          id: galleryID,
          imageIndex: item.dataset.imageIndex,
          imagePerPage: item.dataset.imagePerPage,
          pageIndex: item.dataset.pageIndex,
        },
      }),
    )
  }

  function attachEvents(isGallery = false) {
    items(isGallery).forEach(function (item) {
      item.addEventListener('click', function () {
        emitEvent(item)
      })
    })
  }

  function attachCloseEvent() {
    closeButton().addEventListener('click', function () {
      modal.classList.remove(CLASSES.VISIBILITY)
    })
  }

  function attachModalVisibility() {
    if (galleryButton()) {
      shadowButton().classList.add(CLASSES.SHADOW)
      galleryButton().addEventListener('click', function () {
        modal.classList.add(CLASSES.VISIBILITY)
      })
    }
  }

  function init() {
    if (slides.length > 3) {
      controls.classList.add(CLASSES.CONTROLS_VISIBILITY)
    }

    attachModalVisibility()
    attachEvents(true)
    attachEvents()
    attachCloseEvent()
  }

  init()
}

window.addEventListener('load', _event => {
  const mediaGalleries = document.querySelectorAll('.cmp-media-gallery')

  if (mediaGalleries.length) {
    mediaGalleries.forEach(function (mediaGallery) {
      MediaGallery(mediaGallery)
    })
  }
})
