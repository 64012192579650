import './TheWallTeasersList.scss'

import { TheWallContext } from '@react/TheWall/TheWallContext'
import TheWallTeasersItem from '@react/TheWall/TheWallTeasersList/TheWallTeasersItem'
import { useContext, useEffect, useRef } from 'react'

export type TheWallTeasersListProps = {
  setElement: (parent: HTMLElement, el: HTMLElement) => void
  updateStep: (curStep: number) => void
}

const obsOptions = {
  root: null,
  threshold: 0.5,
}

export default function TheWallTeasersList({ setElement, updateStep }: TheWallTeasersListProps) {
  const { currentTopic, contextData } = useContext(TheWallContext)
  const teaserListRef = useRef(null)

  const filteredTopic = contextData.items.find(item => item.topic === currentTopic)

  useEffect(() => {
    const obsCallback = function (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setElement(teaserListRef.current, entry.target)
          const innerStep = Number(entry.target.getAttribute('data-step')) + 1
          updateStep(innerStep)
        }
      })
    }

    const observer = new IntersectionObserver(obsCallback, obsOptions)

    teaserListRef.current.childNodes.forEach(childNode => observer.observe(childNode))

    return () => {
      observer.disconnect()
    }
  }, [currentTopic])

  if (filteredTopic) {
    return (
      <ul ref={teaserListRef} className="cmp-the-wall__teasers-list">
        {filteredTopic.teasers.map((teaser, index) => (
          <TheWallTeasersItem index={index} key={teaser.title} teaser={teaser} />
        ))}
      </ul>
    )
  }

  return null
}
