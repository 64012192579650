import { getRequest, postRequest } from '../../site/js/utils/request'
import { pushInteractionToolbarDataAnalytics } from './interactiontoolbarUtils'

const cmpInteractionToolbarActiveClass = 'cmp-interaction-toolbar__like--active'

const Like = () => {
  const like = document.querySelector('.cmp-interaction-toolbar__like')
  const numOfLikesLabel = document.querySelector('.cmp-interaction-toolbar__like-number')
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const init = () => {
    let pageLiked = false
    let numOfLikes = 0

    if (like) {
      getRequest(like.dataset.pageLikesUrl)
        .then(response => response.json())
        .then(data => {
          numOfLikes = data.numOfLikes
          pageLiked = data.likedByUser
          if (pageLiked) {
            like.classList.add(cmpInteractionToolbarActiveClass)
          }
          setLikeEventListener()
          if (numOfLikesLabel) {
            numOfLikesLabel.innerHTML = numOfLikes
          }
        })
        .catch(error => console.error(error))

      const setLikeEventListener = () => {
        like.addEventListener('click', () => {
          pushInteractionToolbarDataAnalytics({
            componentName: 'share-like',
            title: 'interaction-toolbar',
          })
          const body = new FormData()
          body.append('liked', JSON.stringify(!pageLiked))
          postRequest(like.dataset.likePageUrl, body)
            .then(response => response.json())
            .then(data => {
              pageLiked = data.likedByUser
              if (numOfLikesLabel) {
                numOfLikesLabel.innerHTML = data.numOfLikes
              }
              pageLiked ? like.classList.add(cmpInteractionToolbarActiveClass) : like.classList.remove(cmpInteractionToolbarActiveClass)
            })
            .catch(error => console.error(error))
        })
      }
    }
  }
  return {
    init,
  }
}
export { Like }
