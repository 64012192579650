import './ReplyAction.scss'

import { cls } from '@react/utils/classname'

type ReplyActionProps = {
  onClick: () => void
  label: string
  classNames: string
}
export function ReplyAction({ onClick, label, classNames }: ReplyActionProps) {
  return (
    <div className={cls({ 'cmp-reply-action': true, [classNames]: true })}>
      <span className="cmp-button__icon cmp-button__icon--reply"></span>
      <span className="cmp-reply-action__text" onClick={onClick} data-testid="reply-action">
        {label}
      </span>
    </div>
  )
}
