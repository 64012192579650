import './pagination.css'

/**
 * checks for null and undefined
 * @param {any} x
 */
const exists = x => x != null

/**
 * lifts value to array (not null or undefined)
 * @param {any} x
 */
const arrayOf = x => [x].filter(exists)

/**
 * creates an array of numbers within a range
 * e.g range(5,10) -> [5,6,7,8,9,10]
 * @param {number} start
 * @param {number} end
 */
const range = (start, end) => {
  const foo = []
  for (let i = start; i <= end; ) {
    foo.push(i++)
  }
  return foo
}

/**
 * creates a pagination
 * @param {object} configuration
 */
export const Pagination =
  ({
    total = 100, // total pages
    page = 1, // current page
    step = 2, // pages on each side of middle
    classNames = {
      container: 'c-pagination',
      list: 'c-pagination__list',
      prev: 'c-pagination__prev',
      next: 'c-pagination__next',
      item: 'c-pagination__item',
      link: 'c-pagination__link',
      truncate: 'c-pagination__truncate',
      disabled: 'is-disabled',
      active: 'is-active',
    },
    onChange = function () {},
  }) =>
  // eslint-disable-next-line sonarjs/cognitive-complexity
  (el = null) => {
    el.classNames = classNames

    /**
     * gets current page
     */
    el.getPage = () => page

    /**
     * dispatches a change event on the pagination element
     */
    const dispatch = () => {
      const event = new CustomEvent('change')
      el.dispatchEvent(event)
    }

    /**
     * change handler
     */
    const change = () => {
      onChange(page)
      dispatch()
      render()
    }

    /**
     * on click page event handler
     * @param {number} pageNr
     */
    const onClickPage = pageNr => {
      page = parseInt(pageNr)
      change()
    }

    /**
     * on click previous button event handler
     */
    const onClickPrev = () => {
      page--
      if (page < 1) {
        page = 1
      }
      change()
    }

    /**
     * on click next page event handler
     */
    const onClickNext = () => {
      page++
      if (page > total) {
        page = total
      }
      change()
    }

    /**
     * click event handler
     * @param {event} e
     */
    const handleClickOrKeydown = e => {
      if (e.type === 'click' || e.key === 'Enter') {
        arrayOf(e.target.closest('li')).map(item =>
          item.classList.contains(classNames.prev)
            ? onClickPrev()
            : item.classList.contains(classNames.next)
            ? onClickNext()
            : item.classList.contains(classNames.item)
            ? onClickPage(item.innerText)
            : (() => false)(),
        )
      }
    }

    /**
     * updates the pagination
     * @param {number} currentPage
     * @param {number} totalPages
     */
    el.update = (currentPage, totalPages) => {
      page = Number(currentPage)
      total = Number(totalPages)
      render()
    }

    /**
     * Templates
     */
    // previous button
    const prevBtn = () =>
      `<li class="${classNames.prev} ${page <= 1 ? classNames.disabled : ''}"><span aria-role="link" tabindex="0" class="${
        classNames.link
      }"> </span></li>`

    // pages at the beginning
    const beginning = rangeStart =>
      rangeStart <= 3
        ? range(1, rangeStart - 1)
            .map(
              i =>
                `<li class="${classNames.item} ${i === page ? classNames.active : ''}"><span aria-role="link" tabindex="0" class="${
                  classNames.link
                }"> ${i} </span></li>`,
            )
            .join('')
        : `
          <li class="${classNames.item}"><span aria-role="link" tabindex="0" class="${classNames.link}">1</span></li>
          <li class="${classNames.truncate}">&hellip;</li>
        `

    // pages in the middle
    const middle = (rangeStart, rangeEnd) =>
      range(rangeStart, rangeEnd)
        .map(
          i =>
            `<li class="${classNames.item} ${i === page ? classNames.active : ''}"><span aria-role="link" tabindex="0" class="${
              classNames.link
            }"> ${i} </span></li>`,
        )
        .join('')

    // pages at the end
    const end = rangeEnd =>
      rangeEnd >= total - 2
        ? range(rangeEnd + 1, total)
            .map(
              i =>
                `<li class="${classNames.item} ${i === page ? classNames.active : ''}"><span aria-role="link" tabindex="0" class="${
                  classNames.link
                }"> ${i} </span></li>`,
            )
            .join('')
        : `
          <li class="${classNames.truncate}">&hellip;</li>
          <li class="${classNames.item}"><span aria-role="link" tabindex="0" class="${classNames.link}">${total}</span></li>
        `

    // next button
    const nextBtn = () =>
      `<li class="${classNames.next} ${page >= total ? classNames.disabled : ''}"><span aria-role="link" tabindex="0" class="${
        classNames.link
      }">  </span></li>`

    // pagination layout
    const template = (rangeStart, rangeEnd) => `
      <nav role="navigation" aria-label="Pagination Navigation" class="${classNames.container}">
        <ul class="${classNames.list}">
          ${prevBtn()}
          ${beginning(rangeStart)}
          ${middle(rangeStart, rangeEnd)}
          ${end(rangeEnd)}
          ${nextBtn()}
        </ul>
      </nav>
    `

    /**
     * Calculates the ranges of pagination items
     * @returns {object} rangeStart, rangeEnd
     */
    const calcRanges = () => {
      let rangeStart = page - step
      let rangeEnd = page + step

      if (rangeEnd > total) {
        rangeEnd = total
        rangeStart = total - step * 2
        rangeStart = rangeStart < 1 ? 1 : rangeStart
      }

      if (rangeStart <= 1) {
        rangeStart = 1
        rangeEnd = Math.min(step * 2 + 1, total)
      }

      return { rangeStart, rangeEnd }
    }

    /**
     * renders the pagination
     */
    const render = () => {
      const { rangeStart, rangeEnd } = calcRanges()

      // render
      el.innerHTML = total && page ? template(rangeStart, rangeEnd) : ''
    }

    // render initial
    render()

    // bind events
    el.addEventListener('click', handleClickOrKeydown, false)
    el.addEventListener('keydown', handleClickOrKeydown, false)
    return el
  }
