import { useContext } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { ContactHubContext } from '../ContactHubContext'
import ContactHubDrilldownItem from '../ContactHubDrilldownItem'
import { DrilldownItemType } from '../ContactHubDrilldownItem'

type ContactHubDrilldownType = {
  drilldowndata: {
    item: DrilldownItemType
  }
}

export default function ContactHubDrilldown({ drilldowndata }: ContactHubDrilldownType) {
  const { fadeDirection } = useContext(ContactHubContext)

  return (
    <div className="cmp-contacthub__drilldown">
      <TransitionGroup>
        {Object.keys(drilldowndata)
          .filter(key => key !== 'title' && key !== 'parent')
          .map(key => {
            return (
              <CSSTransition timeout={200} key={key} classNames={fadeDirection || ''}>
                <ContactHubDrilldownItem key={key} item={drilldowndata[key]} />
              </CSSTransition>
            )
          })}
      </TransitionGroup>
    </div>
  )
}
