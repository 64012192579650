import './SortComments.scss'

import { CommentContext, SortCommentsOptionType, SortCommentsType } from '@react/Comments'
import { cls } from '@react/utils/classname'
import { useCallback, useContext, useEffect, useState } from 'react'
interface SortCommentsProps {
  sortComments: (params: SortCommentsType) => void
}
export function SortComments({ sortComments }: SortCommentsProps) {
  const { translations, sortType } = useContext(CommentContext)

  const list: SortCommentsOptionType[] = [
    {
      id: 'sortByOldest',
      name: translations.sortbyoldest,
      sort: 'date',
      sortOrder: 'asc',
    },
    {
      id: 'sortByNewest',
      name: translations.sortbynewest,
      sort: 'date',
      sortOrder: 'desc',
    },
    {
      id: 'sortByMostLiked',
      name: translations.sortbymostliked,
      sort: 'cStats.likesNum',
      sortOrder: '',
    },
  ]
  const [isOpen, setIsOpen] = useState(false)
  const [activeSortItem, setActiveSortItem] = useState(list[0])

  useEffect(() => {
    const activeSortType = list.find(item => item.sort === sortType.sort && item.sortOrder === sortType.sortOrder) || list[0]

    setActiveSortItem(activeSortType)
  }, [sortType])
  const handleOpen = useCallback(() => {
    setIsOpen(prevSortIsOpen => !prevSortIsOpen)
  }, [])

  const handleSort = useCallback((sortItem: SortCommentsOptionType) => {
    const { sort, sortOrder } = sortItem
    sortComments({ sort, sortOrder })
    setActiveSortItem(sortItem)
  }, [])

  return (
    <div className="cmp-comments__sorting" onClick={handleOpen} data-testid="sort-comments">
      <div className="cmp-comments__sorting__label">
        <span
          className={cls({
            'cmp-button__icon': true,
            'cmp-button__icon--keyboard_arrow_up': isOpen,
            'cmp-button__icon--keyboard_arrow_down': !isOpen,
          })}
        ></span>
        <span className="cmp-comments__sorting__name">{activeSortItem.name}</span>
      </div>
      {isOpen && (
        <ul className="cmp-comments__sorting__options">
          {list.map((item, index) => (
            <li
              key={item.id + index}
              className={cls({
                'cmp-comments__sorting__option': true,
                'cmp-comments__sorting__option--current': item.name === activeSortItem.name,
              })}
              onClick={() => handleSort(item)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
