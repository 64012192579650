import './LikeAction.scss'

import { cls } from '@react/utils/classname'

type LikeActionProps = {
  onClick: () => void
  label: string
  classNames: string
  isActive: boolean
}
export function LikeAction({ onClick, label, classNames, isActive }: LikeActionProps) {
  return (
    <div className={cls({ 'cmp-like-action': true, [classNames]: true, 'cmp-like-action--active': isActive })}>
      <span className="cmp-like-action__text">{label}</span>
      <span className="cmp-button__icon cmp-button__icon--thumbup" onClick={onClick} data-testid="like-action"></span>
    </div>
  )
}
