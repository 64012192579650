import './TheWallTabList.scss'

import { useContext } from 'react'

import { TheWallContext } from '../TheWallContext'
import TheWallTabListItem from './TheWallTabListItem'

export default function TheWallTabList() {
  const { contextData } = useContext(TheWallContext)

  return (
    <ul className="cmp-the-wall-tab-list">
      {contextData.items.map(item => (
        <TheWallTabListItem key={item.topic} topic={item.topic} />
      ))}
    </ul>
  )
}
