import './shortlist.css'

import { cls } from '@react/utils/classname'
import { isMobile } from '@site/js/utils/breakpoint'
import { arrayOf, clone, compose, parseJson, selectAll } from '@site/js/utils/utils'

import { Pagination } from '../../pagination/pagination'

/**
 * adds an item to an object
 * @param {object} obj
 */
const add = obj => data => {
  data[obj.id] = obj
  return data
}

/**
 * removes an item from object
 * @param {id} id
 * @param {object} data
 */
const remove = id => data => {
  delete data[id]
  return data
}

// item template
const listItemTemplate = translations => item =>
  `
  <tr>
    <td>
      <a class="c-mapapp__list-link" href="${item.path}">${item.title}</a>
      <button value="remove" data-id="${item.id}" class="js-toggle-in-shortlist c-shortlist__toggle">
        <span class="c-shortlist__toggle-remove">
          <span class="icon-remove icon--md u-color--primary"></span>
          <span>${translations.shortlistButtonRemove || 'Remove from shortlist'}</span>
        </span>
      </button>
    </td>
    <td>${item.location}</td>
    <td>${item.function}</td>
    <td>${item.hierarchy}</td>
  </tr>
`

// list template
const listTemplate = (data, translations) => `
  <table class="c-mapapp__list-table">
      <thead  class="${cls({
        'u-visually-hidden': isMobile,
      })}">      <tr>
        <th scope="col">${translations.titleColumn || 'Job title'}</th>
        <th scope="col">${translations.locationColumn || 'Location'}</th>
        <th scope="col">${translations.functionColumn || 'Job function'}</th>
        <th scope="col">${translations.hierarchyColumn || 'Type of role'}</th>
      </tr>
    </thead>
    <tbody>
    ${data.map(listItemTemplate(translations)).join('')}
    </tbody>
  </table>
`

/**
 * template
 * @param {array} data
 */
const template = translations => `
  <h3>${translations.title ? translations.title : 'Your shortlist'}</h3>
  <p>
  ${translations.description ? translations.description : 'Add the jobs you want to consider to your shortlist and review later.'}
  <br>
  ${translations.note ? translations.note : 'Please note that your shortlist is saved locally and will be available only on the same device.'}
  </p>
`

/**
 * Shortlist
 * @param {htmlElement} el
 */
export const ShortList = ({
  el,
  selectors = {
    toggleButton: '.js-toggle-in-shortlist',
  },
  storageKey = 'shortlist',
}) => {
  const translations = clone(el.dataset.translations ? parseJson(el.dataset.translations) : {})

  const limit = 4
  let curPage = 1

  el.innerHTML = template(translations)

  // add containers
  const listContainer = document.createElement('div')
  const paginationContainer = document.createElement('div')
  el.appendChild(listContainer)
  el.appendChild(paginationContainer)

  // pagination
  const pagination = Pagination({
    onChange: page => {
      curPage = page
      update()
    },
  })(paginationContainer)

  /**
   * updates the shortlist with pagination
   */
  const update = () => {
    const data = Array.from(Object.values(get()))
    const pages = Math.ceil(data.length / limit)

    if (curPage > pages && curPage !== 1) {
      // ensure it renders the previous page when last item on a page is deleted
      curPage = pages
    }

    const start = (curPage - 1) * limit
    const end = (curPage - 1) * limit + limit
    const paginatedData = data.slice(start, end)

    pagination.update(curPage, pages)
    listContainer.innerHTML = paginatedData.length ? listTemplate(clone(paginatedData), translations) : ''
  }

  /**
   * gets data from localstorage
   */
  const get = () => parseJson(localStorage.getItem(storageKey)) || {}

  /**
   * stores data in localstorage
   * @param {object} obj
   */
  const store = obj => localStorage.setItem(storageKey, JSON.stringify(obj))

  /**
   * adds an item to the shortlist
   * @param {object} item
   */
  const addToList = item => compose(update, store, add(item), get)()

  /**
   * removes an item from the shortlist
   * @param {event} e
   */
  const removeFromShortlist = button => {
    compose(store, remove(button.dataset.id), get)()
    update()
    selectAll(selectors.toggleButton + `[data-id="${button.dataset.id}"]`).map(toggleBtn => {
      toggleBtn.value = 'add'
    })
  }

  /**
   * adds an item to shortlist
   * @param {htmlElement} button
   */
  const addToShortlist = button => {
    addToList({ ...parseJson(button.dataset.item) })
    button.value = 'remove' // switch to remove
  }

  /**
   * handles click event
   * @param {event} e
   */
  const toggleItem = e =>
    arrayOf(e.target.closest(selectors.toggleButton)).map(button => {
      button.value === 'add' ? addToShortlist(button) : removeFromShortlist(button)
    })

  // initial render
  update()

  // attach events
  addEventListener('click', toggleItem)
}

// start shortlist
selectAll('short-list').map(el => ShortList({ el }))
