export interface ImageProperties {
  pattern: string
  originalWidth: number
  originalHeight: number
  caption: string
  alt: string
  placeholder: string
}

export const Image = (config: ImageProperties): string => {
  if (!config || !config.pattern || !config.placeholder) return ''

  return `<div class="cmp-responsive-image cmp-responsive-image--lazy" data-image-src='${JSON.stringify(config)}'>
        <picture>
            <img src='${config.placeholder}'>
        </picture>
    </div>`
}

export const imageTemplate = (image: ImageProperties): string => {
  return `<div data-component="ImageWrapper"
  data-props:classes="cmp-responsive-image cmp-responsive-image--lazy"
  data-props:model-json="${JSON.stringify(image)}"
  data-props:image-src="${image.placeholder}">
    ${Image(image)}
  </div>`
}
