import { createContext } from 'react'

import { FetchData } from './TheWall'

type ContextDataType = {
  items: FetchData['items']
}

type TheWallContextState = {
  contextData: ContextDataType
  setContextData: (newData: FetchData) => void
  currentTopic: string
  setCurrentTopic: (topic: string) => void
}

const defaultContext: TheWallContextState = {
  contextData: {
    items: [],
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setContextData: (newData): void => {},
  currentTopic: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setCurrentTopic: (topic): void => {},
}

export const TheWallContext = createContext<TheWallContextState>(defaultContext)
