import './search.css'

import { clone } from '../../../site/js/utils/utils.js'

/**
 * Search template
 * @param {object} state
 * @param {object} config
 */
export const Search = (state, config) => `
  <div class="c-mapapp__search">
    <div class="c-mapapp__search-field">
      <input data-dm="${config.dmAttributes.search.searchInput}" placeholder="${
  config.translations.searchPlaceholder || 'Search jobs'
}" class="c-mapapp__search-input" name="search" type="text" value="${clone(state.selectedFilters.search) || ''}" >
      <span class="icon-search icon--md"></span>
    </div>
    <button data-dm="${config.dmAttributes.search.searchButton}" class="c-mapapp__search-button" type="submit">${
  config.translations.searchButton || 'Search'
}</button>
  </div>
`
