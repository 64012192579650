/**
 *  On to navigation component - teaser dropdown
 */

import { trackEvent } from '@site/js/utils/tracking'

export const TeaserDropDown = () => {
  const teaserTitles = document.querySelectorAll('.cmp-navigation-teaser__title')
  const linkList = document.querySelectorAll('.cmp-navigation-teaser__link')

  const addOnClickEventListener = () => {
    teaserTitles?.forEach(title => {
      title.addEventListener('click', () => {
        title.classList.toggle('title--is-open')
        title.nextElementSibling.classList.toggle('content--is-open')
      })
    })
  }

  linkList?.forEach(link => {
    handleTracking(link)
  })

  const init = () => {
    addOnClickEventListener()
  }

  return {
    init,
  }
}

const handleTracking = link => {
  link?.addEventListener('click', event => {
    const linkText = event.target.innerHTML
    const href = event.target.href
    const title = event.target.dataset.title

    trackEvent({
      action: 'click',
      componentName: 'on-page-navigation',
      interactionType: 'link',
      title,
      linkText,
      href,
    })
  })
}
