/**
 * KEPT FOR BACKWARD COMPATIBILITY
 */
import './index'

import { TsComponentRegistry } from '@components/ts-component-registry'
import { initReactComponents, initReactInAuthorMode } from '@react/react-component-registry'

const cache = {}

function importAll(requireContext: __WebpackModuleApi.RequireContext) {
  // eslint-disable-next-line space-before-function-paren
  requireContext.keys().forEach(key => {
    cache[key] = requireContext(key)
    return cache
  })
}

// Imports all .ts files from ./components directory and its subdirectories except for,
// - videodetails and filterable-list folders as they are already bundled separately
// - any __tests__ folder. Please make sure to place inside this folder *.test.ts, *.spec.ts and *.mock.ts
importAll(require.context('../components', true, /^((?!\/?__tests__|videodetails|filterable-list\/).)*\.ts$/))

/**
 * kicks off js execution of components
 */
document.addEventListener('DOMContentLoaded', () => {
  TsComponentRegistry.initComponents()
})

initReactComponents()
initReactInAuthorMode()
