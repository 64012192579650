import './ContactHub.scss'

import { useMemo, useState } from 'react'

import { ContactHubContext } from './ContactHubContext'
import ContactHubDrilldown from './ContactHubDrilldown'
import { getDataByKey } from './helper'

type ContactHubType = {
  drilldowndata: string
  title: string
  stepbacktitle: string
}

function buildDrilldownMenu(data, parent) {
  return data.reduce((accumulator, element) => {
    return {
      ...accumulator,
      [element.title]: {
        title: element.title,
        parent,
        ...(element.children ? buildDrilldownMenu(element.children, element.title) : element),
      },
    }
  }, {})
}

const ROOT_TITLE = 'root'

export default function ContactHub({ title, stepbacktitle, drilldowndata }: ContactHubType) {
  const data = useMemo(() => {
    try {
      const jsonData = JSON.parse(drilldowndata)
      return buildDrilldownMenu(jsonData, ROOT_TITLE)
    } catch (error) {
      console.error(error)
    }
  }, [drilldowndata])
  const [currentData, setCurrentData] = useState(data)
  const [selectedItem, setSelectedItem] = useState('')
  const [fadeDirection, setFadeDirection] = useState('')

  function handleOnClick() {
    setFadeDirection('slide-fade-previous')
    if (currentData.parent === ROOT_TITLE) {
      setCurrentData(data)
      setSelectedItem('')
    } else {
      const retrievedData = getDataByKey(data, currentData.parent)
      setCurrentData(retrievedData)
      setSelectedItem(currentData.parent)
    }
  }

  return (
    <ContactHubContext.Provider
      value={{
        originalData: data,
        currentData: data,
        setCurrentData,
        selectedItem,
        setSelectedItem,
        fadeDirection,
        setFadeDirection,
      }}
    >
      <div className="cmp-contacthub">
        <h3 className="cmp-contacthub__title">{selectedItem || title}</h3>
        {selectedItem && selectedItem !== ROOT_TITLE ? (
          <div className="cmp-contacthub__back" role="button" tabIndex={0} onClick={handleOnClick}>
            <span className="cmp-contacthub__back--icon icon-keyboard_arrow_left" />
            <span className="cmp-contacthub__back--title">{stepbacktitle}</span>
          </div>
        ) : null}

        <ContactHubDrilldown drilldowndata={currentData} />
      </div>
    </ContactHubContext.Provider>
  )
}
