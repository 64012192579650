/**
 * creates a basic observeble with getters and setters
 * @param {object} defaultState
 */
export const createState = defaultState => {
  const obj = {
    state: { ...defaultState },
    watchers: {},
    watch: function (key, fn) {
      // if the key is the function, watch all keys, otherwise watch the key
      typeof key === 'function' ? (this.watchers._all = [...this.watchers._all, fn]) : (this.watchers[key] = [...this.watchers[key], fn])
    },
  }

  const proxy = obj => key => {
    obj.watchers[key] = obj.watchers[key] || []
    obj.watchers._all = obj.watchers._all || []

    Object.defineProperty(obj, key, {
      configurable: true,
      enumerable: true,
      get: () => obj.state[key],
      set: val => {
        obj.state[key] = val
        obj.watchers[key].forEach(fn => fn(key, obj))
        obj.watchers._all.forEach(fn => fn(key, obj))
      },
    })
  }

  Object.keys(obj.state).forEach(proxy(obj))

  return obj
}
