import { storageAvailable } from '../../site/js/utils/storageCheck'
/* eslint-disable max-lines */
/**
 * Country Selector Component
 */
const CountrySelector = () => {
  const countriesSelect = document.querySelector('.cmp-countryselector .cmp-countryselector--countries')
  const countriesOptions = document.querySelectorAll('.cmp-countryselector .c-custom-select-panel .c-custom-select-option')
  const countrySelectorLabel = document.querySelector('.cmp-countryselector .c-custom-select-opener span')
  const languagesSelect = document.querySelector('.cmp-countryselector .cmp-countryselector--languages')
  const languageLink = document.querySelector('.cmp-countryselector .cmp-countryselector--link')
  const languageSelectorDisable = document.querySelector('.js-country-selector-languages .customSelect')
  const languageOptions = document.querySelectorAll('.js-country-selector-languages .c-custom-select-panel .c-custom-select-option')
  const languageSelectorLabel = document.querySelector('.js-country-selector-languages .c-custom-select-opener span')
  const languageIsSelectedClass = 'is-selected'
  const countryIsSelectedClass = languageIsSelectedClass
  const hasFocusClass = 'has-focus'

  const hiddenSelectWrapperClassName = 'cmp-countryselector__group--hidden'
  const hiddenLanguageOptionClassName = 'cmp-countryselector__option--hidden'

  const resetLanguageSelect = (languagesSelectWrapper, customLanguageOptions) => {
    customLanguageOptions.forEach(customLanguageOption => {
      if (customLanguageOption.dataset.value !== '') {
        customLanguageOption.classList.add(hiddenLanguageOptionClassName)
      }
    })
    languagesSelectWrapper.querySelector('.c-custom-select-opener span').textContent =
      languagesSelectWrapper.querySelector('.c-custom-select-option').textContent
  }

  const filterLanguageOptions = (countryLabel, customLanguageOptions) => {
    customLanguageOptions.forEach(customLanguageOption => {
      customLanguageOption.classList.add(hiddenLanguageOptionClassName)
    })

    languagesSelect.querySelectorAll('option').forEach(languageOption => {
      const isLanguageOfSelectedCountry = languageOption.dataset.country === countryLabel
      if (isLanguageOfSelectedCountry) {
        const optionValue = languageOption.value
        customLanguageOptions.forEach(customLanguageOption => {
          if (customLanguageOption.dataset.value === optionValue) {
            customLanguageOption.classList.remove(hiddenLanguageOptionClassName)
          }
        })
      }
    })

    regionLanguages()
  }

  const showLanguageSelect = event => {
    const countryLabel = event.target.value
    const isCountryValueEmpty = countryLabel === ''
    const languagesSelectWrapper = languagesSelect.closest('.cmp-countryselector__group')
    const customLanguageOptions = languagesSelectWrapper.querySelectorAll('.c-custom-select-option')

    languageLink.href = '#'

    if (isCountryValueEmpty) {
      languagesSelectWrapper.classList.add(hiddenSelectWrapperClassName)
    } else {
      resetLanguageSelect(languagesSelectWrapper, customLanguageOptions)
      filterLanguageOptions(countryLabel, customLanguageOptions)
      languagesSelectWrapper.classList.remove(hiddenSelectWrapperClassName)
    }
  }

  const updateLanguageLink = event => {
    const selectedLanguage = event.target.value

    if (selectedLanguage === '') {
      languageLink.href = '#'
    } else {
      sessionStorage.setItem('selectedLanguageValue', selectedLanguage)
      const link = selectedLanguage.split('/')
      link.shift()
      languageLink.href = link.join('/')
      if (!isWholeQueryStringIncluded(link)) {
        // dynamic news list case ('DNL' prefix is provided by Dispatcher rewrite rule)
        // add wcmmmode param string from URL
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.has('wcmmode')) {
          languageLink.href += `?wcmmode=${queryParams.get('wcmmode')}`
        }
      } else {
        // add query param string from URL
        const queryString = window.location.search
        if (queryString) {
          languageLink.href += queryString
        }
      }
    }
  }

  const isWholeQueryStringIncluded = linkSegments => {
    if (!linkSegments || linkSegments.length === 0) {
      return true
    }

    // check if the link contains DNL in its last segment - dynamic news list case ('DNL' prefix is provided by Dispatcher rewrite rule)
    const lastLinkSegment = linkSegments[linkSegments.length - 1]
    if (!lastLinkSegment) {
      return true
    }

    if (lastLinkSegment.startsWith('DNL')) {
      return false
    }

    // check if there's 'DNL' present in the selector of the last link segment
    const dnlRegex = /\.DNL([^/]+)\./g
    const dnlRegexMatched = lastLinkSegment.match(dnlRegex)

    return dnlRegexMatched && dnlRegexMatched.length > 0 ? false : true
  }

  const isRelevantLanguage = (language, relevantLanguages) => {
    let isRelevant = false
    relevantLanguages.forEach(relevantLanguage => {
      if (relevantLanguage.dataset.value === language) {
        isRelevant = true
      }
    })
    return isRelevant
  }

  /* Check how many languages we have on change Region */
  const regionLanguages = () => {
    const relevantLanguages = Array.from(languageOptions).filter(x => {
      return !x.classList.contains(hiddenLanguageOptionClassName)
    })

    if (relevantLanguages.length === 0) {
      relevantLanguages.push(languageOptions[0])
    }

    if (relevantLanguages.length <= 1) {
      languageSelectorDisable && languageSelectorDisable.classList.add('language-selector--disabled')
    } else {
      languageSelectorDisable && languageSelectorDisable.classList.remove('language-selector--disabled')
    }

    if (storageAvailable('sessionStorage')) {
      const sessionLanguage = sessionStorage.getItem('selectedLanguageValue')

      if (!sessionLanguage || !isRelevantLanguage(sessionLanguage, relevantLanguages)) {
        sessionStorage.setItem('selectedLanguageValue', relevantLanguages[0].dataset.value)
        languagesSelect.value = relevantLanguages[0].dataset.value
      } else {
        languagesSelect.value = sessionLanguage
      }

      relevantLanguages.forEach(languageOption => {
        languageOption.classList.remove(languageIsSelectedClass)
        if (languageOption.dataset.value === languagesSelect.value) {
          languageOption.classList.add(languageIsSelectedClass)
        }
      })
    } else {
      languagesSelect.value = relevantLanguages[0].dataset.value
    }

    languagesSelect.dispatchEvent(new window.CustomEvent('change'))

    updateLanguageSelectorOptions(relevantLanguages)
    languageSelectorLabel.textContent =
      relevantLanguages.find(element => element.classList.contains(languageIsSelectedClass))?.textContent || relevantLanguages[0].textContent
  }

  const updateLanguageSelectorOptions = relevantLanguages => {
    languagesSelect.querySelectorAll('option').forEach(languageOption => {
      const languageValue = relevantLanguages[0].dataset.value
      const isSelectedLanguage = languageOption.value === languageValue
      if (isSelectedLanguage) {
        languageOption.setAttribute('selected', 'selected')
      } else {
        languageOption.removeAttribute('selected')
      }
    })
  }

  const updateSelectedCountryAttributes = () => {
    countriesSelect.querySelectorAll('option').forEach(countryOption => {
      const isCountryOfSelectedLanguage = countryOption.value === countriesSelect.value
      if (isCountryOfSelectedLanguage) {
        countryOption.setAttribute('selected', 'selected')
      } else {
        countryOption.removeAttribute('selected')
      }
    })
  }

  const updateCountrySelectorOptions = _event => {
    updateSelectedCountryAttributes()

    const selectedCountry = countriesSelect.querySelector('option[selected]').textContent

    if (storageAvailable('sessionStorage')) {
      sessionStorage.setItem('selectedCountryValue', selectedCountry)
    }
  }

  /**
   * init
   */
  const init = () => {
    if (countriesSelect && languagesSelect && languageLink) {
      /* Retirve country from sessionStorage */
      if (storageAvailable('sessionStorage')) {
        const sessionCountry = sessionStorage.getItem('selectedCountryValue')

        if (sessionCountry) {
          countriesSelect.value = sessionCountry.trim()

          updateSelectedCountryAttributes()

          countriesOptions.forEach(countryOption => {
            countryOption.classList.remove(countryIsSelectedClass, hasFocusClass)
            if (countryOption.textContent === countriesSelect.value) {
              countryOption.classList.add(countryIsSelectedClass, hasFocusClass)
            }
          })

          countrySelectorLabel.textContent = countriesSelect.value
        }
      }

      countriesSelect.addEventListener('change', event => {
        updateCountrySelectorOptions(event)
        regionLanguages()
        showLanguageSelect(event)
      })
      languagesSelect.addEventListener('change', event => updateLanguageLink(event))

      const countryLabel = countriesSelect.value

      filterLanguageOptions(countryLabel, languageOptions)
    }
  }

  return {
    init,
  }
}

export { CountrySelector }
/* eslint-enable max-lines */
