/**
 * Footnotes Component
 */

const Footnotes = () => {
  const footnotes = document.querySelector('#footnotes')
  /**
   * initialize
   */
  const init = () => {
    if (!footnotes) {
      return
    }
    const sups = Array.from(document.querySelectorAll('sup'))
    // Check if the superscript contains a number
    sups.map(sup => {
      if (!isNaN(Number(sup.innerText))) {
        sup.innerHTML = `<a class="cmp-footnote-sup" href="#footnotes">${Number(sup.innerText)}</a>`
      }
    })
  }

  return {
    init,
  }
}

export { Footnotes }
