import { createContext } from 'react'

const defaultContext = {
  originalData: {},
  currentData: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setCurrentData: (newData): void => {},
  selectedItem: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setSelectedItem: (newElement): void => {},
  fadeDirection: '',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setFadeDirection: (direction): void => {},
}

export const ContactHubContext = createContext(defaultContext)
