import { Pagination } from '../../pagination/pagination.js'

/**
 * creates a pagination component
 * @param {object} configuration
 */
export const Paginate = ({ el, state }) => {
  /**
   * on change event handler
   * @param {number} pageNr
   */
  const onPaginationChange = pageNr => {
    state.selectedFilters = { ...state.selectedFilters, page: pageNr }
  }

  /**
   * create pagination
   */
  const pagination = Pagination({
    page: 1,
    total: 1,
    onChange: onPaginationChange,
  })(el)

  /**
   * updates the pagination
   */
  const update = () => {
    const currentPage = state.selectedFilters.page ? (Number(state.selectedFilters.page) ? Number(state.selectedFilters.page) : 1) : 1

    pagination.update(currentPage, state.listTotalPages)
  }

  state.watch('listData', update)
  update()
}
