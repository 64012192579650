import { pushInteractionToolbarDataAnalytics } from './interactiontoolbarUtils'

const Email = () => {
  const email = document.querySelector('.cmp-interaction-toolbar__share-email')
  const init = () => {
    if (email) {
      email.addEventListener('click', () =>
        pushInteractionToolbarDataAnalytics({
          componentName: 'share-email',
          title: 'interaction-toolbar',
        }),
      )
    }
  }

  return {
    init,
  }
}

export { Email }
