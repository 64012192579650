import { pxToRem } from '../../../site/js/utils/px-to-rem'

const PARALLAX_VIDEO_IMAGE_MASK = '.cmp-videocontainer__video--parallax-scroll_mask'
const PARALLAX_VIDEO_IMAGE_MASK_ACTIVE = `${PARALLAX_VIDEO_IMAGE_MASK}.parallax--active`

let ticking = false

const initializeImageMask = () => {
  const maskAbleVideos = Array.from(document.querySelectorAll(PARALLAX_VIDEO_IMAGE_MASK))

  maskAbleVideos.forEach(video => {
    video.videoChild = video.querySelector('.cmp-videocontainer__dynamicmediaplayer')
  })

  ParallaxMaskVideoObserver(maskAbleVideos)
  handleScroll()

  document.addEventListener('scroll', () => handleScroll())
  window.addEventListener('resize', () => handleScroll())
}

const applyMask = video => {
  const { top } = video.getBoundingClientRect()
  video.videoChild.style.transform = `translate3d(0, ${pxToRem(-1 * top)}, 0)`
}

const handleScroll = () => {
  if (!ticking) {
    requestAnimationFrame(update)
    ticking = true
  }
}

const update = () => {
  ticking = false

  const videos = Array.from(document.querySelectorAll(PARALLAX_VIDEO_IMAGE_MASK_ACTIVE))
  videos.forEach(video => applyMask(video))
}

const handleIntersection = (entries, _observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('parallax--active')
      handleScroll()
    } else {
      entry.target.classList.remove('parallax--active')
    }
  })
}

const ParallaxMaskVideoObserver = videos => {
  if (!videos || videos.length <= 0) {
    return
  }

  const options = {
    rootMargin: '10%',
  }

  if ('IntersectionObserver' in window) {
    const parallaxMaskVideoObserver = new IntersectionObserver(handleIntersection, options)

    videos.forEach(video => parallaxMaskVideoObserver.observe(video))
  }
}

document.addEventListener('DOMContentLoaded', () => initializeImageMask())
