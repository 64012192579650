import { isMobile } from '../../site/js/utils/breakpoint'
import { TsComponentRegistry } from '../ts-component-registry'

class NewsTeaserComponent extends TsComponentRegistry.Component<HTMLDivElement> {
  scrollAnimation: boolean
  newsTeasers: NodeListOf<HTMLElement>
  constructor(element: HTMLDivElement) {
    super(element)
    this.newsTeasers = this.element.querySelectorAll('.news-teaser')
    this.scrollAnimation = this.newsTeasers[0].dataset.animationActive === 'true'
    this.init()
  }

  private init(): void {
    this.scrollAnimation && !isMobile && this.animationHandler()
    if (!isMobile) this.setTeasersTooltip()
  }

  private animationHandler(): void {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('active')
            const video = entry.target.querySelector('video')
            if (video && video.paused) video.play()
          }
        })
      },
      {
        threshold: [0.2],
      },
    )
    this.newsTeasers.forEach(teaser => observer.observe(teaser))
  }

  private isTextOverflown(element): boolean {
    return element.scrollHeight > element.clientHeight
  }

  private setTeasersTooltip(): void {
    this.newsTeasers.forEach(teaser => {
      const title = teaser.querySelector('.news-teaser__link__text__title')
      if (this.isTextOverflown(title)) title.classList.add('news-teaser__link__text__title--has-tooltip')
    })
  }
}

const selector = '.news-teasers'
TsComponentRegistry.registerComponent(selector, NewsTeaserComponent)
