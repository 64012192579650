import { getRequest } from '../../site/js/utils/request'

const StockTicker = (STOCK_TICKER, _key) => {
  if (!STOCK_TICKER) {
    return
  }

  const jsonURL = STOCK_TICKER.getAttribute('data-url')
  const getTranslations = JSON.parse(STOCK_TICKER.getAttribute('data-translations'))
  const timestamp = STOCK_TICKER.querySelector('.stock-ticker__timestamp')
  const baseUrl = window.location.origin
  const apiUrl = baseUrl + jsonURL
  const stockTickerPercentage = STOCK_TICKER.querySelector('.stock-ticker__percentage')
  const percentageIcon = STOCK_TICKER.querySelector('.cmp-button__icon')

  function updateTranslations() {
    return getTranslations.exchangeTimeLabel.split(' ').slice(0, 1)[0]
  }

  function getResponse() {
    getRequest(apiUrl)
      .then(response => response.json())
      .then(data => {
        timestamp.innerHTML = updateTranslations() + ' ' + data.data.formattedExchangeTime
      })
  }

  function getPercentageValue() {
    const typeOfPercentage = parseFloat(stockTickerPercentage.innerText)

    if (percentageIcon) {
      if (typeOfPercentage < 0) {
        percentageIcon.classList.add('icon--stock_decrease')
      } else if (typeOfPercentage > 0) {
        percentageIcon.classList.add('icon--stock_increase')
      } else {
        percentageIcon.classList.add('icon--stock_neutral')
      }
    }
  }

  getResponse()
  getPercentageValue()
}

window.addEventListener('load', () => {
  const StockTickers = document.querySelectorAll('.cmp-stock-ticker')
  StockTickers.forEach((el, key) => StockTicker(el, key))
})
