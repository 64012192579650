document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.download-asset-rendition').forEach(button => {
    button.addEventListener('click', e => {
      e.preventDefault()
      window.downloadFile(button.getAttribute('href'), button.dataset.downloadName)
    })
  })

  document.addEventListener('keyup', function (e) {
    if (e.target.classList.contains('tag-filters-search')) {
      const field = e.target
      const value = field.value
      const tagsWrapper = field.parentElement.querySelector('.tag-filters-wrapper')
      let noResultsArea = tagsWrapper.querySelector('.no-results-message')
      let hasResults = false
      tagsWrapper.querySelectorAll('.checkbox').forEach(field => {
        if (value && !field.innerText.toLowerCase().includes(value.toLowerCase())) {
          field.parentNode.style.display = 'none'
        } else {
          field.parentNode.style.display = ''
          hasResults = true
          if (field.querySelector('input[type=checkbox]').dataset?.childTagFilter !== undefined) {
            const tagsWrapper = field.closest('.tag-filter-group-wrapper')
            const parentTagWrapper = tagsWrapper.querySelector('.parent-tag-filter-wrapper')
            const childTagsWrapper = tagsWrapper.querySelector('.child-tag-filters-wrapper')
            parentTagWrapper.style.display = ''
            parentTagWrapper.classList.add('active')
            childTagsWrapper.classList.add('active')
          }
        }
      })
      if (!hasResults && !noResultsArea) {
        const noResultsMessage = document.createElement('div')
        noResultsMessage.classList.add('field')
        noResultsMessage.classList.add('no-results-message')
        noResultsMessage.innerHTML = tagsWrapper.dataset.noResultsText
        tagsWrapper.appendChild(noResultsMessage)
      } else if (hasResults && noResultsArea) {
        tagsWrapper.removeChild(noResultsArea)
      }
    }
  })

  window.downloadFile = function (url, file) {
    // eslint-disable-next-line no-undef
    AssetShare.CustomDownload.showDimmer()
    fetch(url)
      .then(response => response.blob())
      .then(blobData => {
        // create file link in browser's memory
        const href = URL.createObjectURL(blobData)
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', file)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
      })
      .catch(error => console.error(error))
      // eslint-disable-next-line no-undef
      .finally(() => AssetShare.CustomDownload.hideDimmer())
  }
})
