import './Carousel.scss'

import Icon from '@react/Atoms/Icon/Icon'
import { TheWallTeasersListProps } from '@react/TheWall/TheWallTeasersList'
import React, { useState } from 'react'

import { cls } from '../utils/classname'

type CarouselObject = {
  carouselList: HTMLElement
  carouselItem: HTMLElement
}

type CarouselProps = {
  componentList?: (data: TheWallTeasersListProps) => React.ReactNode
  numberOfSlide: number
}

export default function Carousel({ componentList, numberOfSlide }: CarouselProps) {
  const [activeSlide, setActiveSlide] = useState(1)
  const [elements, setElements] = useState<CarouselObject>({ carouselList: null, carouselItem: null })

  const progressbar = (activeSlide / numberOfSlide) * 100

  const updateStep = (curStep: number) => {
    setActiveSlide(curStep)
  }

  const setElement = (carouselList, carouselItem) => {
    setElements({ carouselList, carouselItem })
  }

  const handleMoveSlider = direction => {
    if (elements.carouselItem === null) {
      return
    }

    const slideWidth = elements.carouselItem.clientWidth
    const offset = slideWidth * (direction === 'next' ? 1 : -1)
    elements.carouselList.scrollLeft += offset
  }

  const handleSlideNext = () => {
    if (activeSlide === numberOfSlide) return
    setActiveSlide(activeSlide + 1)
    handleMoveSlider('next')
  }

  const handleSlidePrev = () => {
    if (activeSlide === 1) return
    setActiveSlide(activeSlide - 1)
    handleMoveSlider('prev')
  }

  return (
    <>
      {componentList({ setElement, updateStep })}
      <div className="cmp-react-carousel">
        <div className="cmp-react-carousel__progress">
          <div style={{ width: `${progressbar}%` }} className="cmp-react-carousel__progress-status"></div>
        </div>
        <div className="cmp-react-carousel__controls">
          <div
            className={cls({
              'cmp-react-carousel__controls-disabled': activeSlide === 1,
              'cmp-react-carousel__control-prev': true,
            })}
            onClick={handleSlidePrev}
          >
            <Icon iconName="keyboard_arrow_left" />
          </div>
          <div
            className={cls({
              'cmp-react-carousel__controls-disabled': activeSlide === numberOfSlide,
              'cmp-react-carousel__control-next': true,
            })}
            onClick={handleSlideNext}
          >
            <Icon iconName="keyboard_arrow_right" />
          </div>
        </div>
      </div>
    </>
  )
}
