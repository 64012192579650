import { AA_SESSION_ID, AA_UID, GA_SESSION_ID, GA_UID } from '@site/js/utils/cookies'

const analyticsPrefix = 'SC_'
let analyticsParamsString = ''

// set specific class on teaser container as currently anchor elements cannot be distinguished within.
// Eg. no specific class set on a chat live agent anchor element
export const CHAT_LIVE_AGENT_CLASS_SELECTOR = '.cmp-teasercontainer--with-chat-live-agent'

document.addEventListener(
  'sessionDataReady',
  function (
    e: Event & {
      detail: {
        message: {
          aa_uid: string
          aa_session_id: string
          ga_uid: string
          ga_session_id: string
        }
      }
    },
  ) {
    try {
      const { aa_uid, aa_session_id, ga_uid, ga_session_id } = e.detail.message

      const googleAnalytics = `${analyticsPrefix}${GA_UID}=${ga_uid}` || ''
      const dynamicSessionId = `${analyticsPrefix}${GA_SESSION_ID}=${ga_session_id}` || ''
      const adobeAnalytics = `${analyticsPrefix}${AA_UID}=${aa_uid}` || ''
      const sessionId = `${analyticsPrefix}${AA_SESSION_ID}=${aa_session_id}` || ''

      analyticsParamsString = [googleAnalytics, dynamicSessionId, adobeAnalytics, sessionId].filter(e => Boolean(e)).join('&')
    } catch (e) {
      console.error('[chat-live-agent]: An error has occurred on sessionDataReady event', e)
    }
  },
)

export const initChatLiveAgentElements = () => {
  // eues: PROD | eues2: STAGE
  const SALESFORCE_SERVICE_CHAT_LINK_REGEX = new RegExp(/eues2?.ccspcloud.com/)
  const chatLiveAgentElements = document.querySelectorAll(`.cmp-button--chat,${CHAT_LIVE_AGENT_CLASS_SELECTOR} a`)

  if (!chatLiveAgentElements) {
    return
  }

  for (const chatLiveAgentElement of chatLiveAgentElements) {
    const isNotValidChatLiveButton = Boolean(
      chatLiveAgentElement instanceof HTMLButtonElement &&
        (!chatLiveAgentElement.dataset.link || !chatLiveAgentElement.dataset.link.match(SALESFORCE_SERVICE_CHAT_LINK_REGEX)),
    )
    const isNotValidChatLiveAgentAnchor = Boolean(
      chatLiveAgentElement instanceof HTMLAnchorElement &&
        (!chatLiveAgentElement.href || !chatLiveAgentElement.href.match(SALESFORCE_SERVICE_CHAT_LINK_REGEX)),
    )

    if (isNotValidChatLiveButton || isNotValidChatLiveAgentAnchor) {
      continue
    }

    chatLiveAgentElement.addEventListener('click', (e: Event) => {
      e.preventDefault()
      const dataUrl = (chatLiveAgentElement as HTMLButtonElement).dataset.link || (chatLiveAgentElement as HTMLAnchorElement).href
      const dataUrlWithAnalyticsParams = dataUrl
        .split('&')
        .filter(param => !param.startsWith(analyticsPrefix))
        .map(param => (param.startsWith('dataFileUrl') ? (param += `&${analyticsParamsString}`) : param))
        .join('&')

      const hpgPageParam = `&HPGpage=${encodeURIComponent(window.location.href)}`
      window.open(`${dataUrlWithAnalyticsParams}${hpgPageParam}`, '_blank', 'noreferrer, noopener, width=480, height=650, top=0, left=0')
    })
  }
}
