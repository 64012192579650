/**
 *  Stage Component - scroll indicator
 */
import { initParallax } from '@components/parallax-scroll/parallax-scroll'

export const ScrollIndicator = () => {
  const scrollIndicator = document.querySelector('.scroll-indicator')
  const parallaxContainer = document.querySelector('.parallax-scroll-container')
  const firstSection = document.querySelector('main')?.querySelectorAll('.container')[1]

  const onClick = () => {
    scrollIndicator?.addEventListener('click', () => {
      // handle margin-top of the parallax
      if (parallaxContainer) {
        initParallax(parallaxContainer, true)
      }

      firstSection.scrollIntoView({
        behavior: 'smooth',
      })
    })
  }

  const init = () => {
    onClick()
  }

  return {
    init,
  }
}
