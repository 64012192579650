import { pxToRem } from '../../site/js/utils/px-to-rem'

function parallax() {
  const observer = new IntersectionObserver(
    function (entries) {
      const watermark = document.querySelector('.cmp-pagestage-text-only__watermark-title')
      const parent = document.querySelector('.cmp-pagestage-text-only').getBoundingClientRect()
      if (entries[0].isIntersecting === true) {
        const yPos = parent.height - window.pageYOffset / 4
        watermark.style.top = pxToRem(yPos)
      }
    },
    { threshold: [0] },
  )

  observer.observe(document.querySelector('.cmp-text:first-of-type'))
}

window.addEventListener('scroll', function () {
  if (document.querySelector('.cmp-pagestage-text-only__watermark-title')) {
    parallax()
  }
})
