/**
 *  Languagenavigation Component
 */
const Languagenavigation = () => {
  const languagenavigationSelect = document.querySelector('.cmp-languagenavigation .cmp-form-options select')
  const languageLink = document.querySelector('.cmp-languagenavigation .cmp-languagenavigation--link')

  const updateLanguageLink = event => {
    const selectedLanguage = event.target.value

    if (selectedLanguage === '') {
      languageLink.href = '#'
    } else {
      languageLink.href = selectedLanguage
    }

    const link = selectedLanguage.split('/')
    link.shift()
    if (!isWholeQueryStringIncluded(link)) {
      // dynamic news list case ('DNL' prefix is provided by Dispatcher rewrite rule)
      // add wcmmmode param string from URL
      const queryParams = new URLSearchParams(window.location.search)
      if (queryParams.has('wcmmode')) {
        languageLink.href += `?wcmmode=${queryParams.get('wcmmode')}`
      }
    } else {
      // add query param string from URL
      const queryString = window.location.search
      if (queryString) {
        languageLink.href += queryString
      }
    }
  }

  const isWholeQueryStringIncluded = linkSegments => {
    if (!linkSegments || linkSegments.length === 0) {
      return true
    }

    // check if the link contains DNL in its last segment - dynamic news list case ('DNL' prefix is provided by Dispatcher rewrite rule)
    const lastLinkSegment = linkSegments[linkSegments.length - 1]
    if (!lastLinkSegment) {
      return true
    }

    if (lastLinkSegment.startsWith('DNL')) {
      return false
    }

    // check if there's 'DNL' present in the selector of the last link segment
    const dnlRegex = /\.DNL([^/]+)\./g
    const dnlRegexMatched = lastLinkSegment.match(dnlRegex)

    return dnlRegexMatched && dnlRegexMatched.length > 0 ? false : true
  }

  /**
   * init
   */
  const init = () => {
    if (languagenavigationSelect && languageLink) {
      languagenavigationSelect.addEventListener('change', event => updateLanguageLink(event))
    }
  }

  return {
    init,
  }
}

export { Languagenavigation }
