/**
 * Map styles
 * - based on the current implementation on ABB
 */
const mapStyles = {
  // version: '1.0',
  settings: {
    landColor: '#d8d8d8',
  },
  elements: {
    mapElement: {
      labelColor: '#232323',
      labelOutlineColor: '#eaeaea',
    },
    political: {
      borderStrokeColor: '#7c7c7c',
      borderOutlineColor: '#00000000',
    },
    point: {
      iconColor: '#232323',
      fillColor: '#f4f4f4',
      strokeColor: '#7c7c7c',
      visible: false,
    },
    transportation: {
      strokeColor: '#a2a2a2',
      fillColor: '#bdbdbd',
    },
    structure: {
      fillColor: '#d2d2d2',
      strokeColor: '#eee',
      visible: false,
    },
    water: {
      fillColor: '#fff',
    },
    area: {
      visible: false,
    },
    railway: {
      visible: false,
    },
    transit: {
      visible: false,
    },
  },
}

/**
 * Creates a bing map
 * @param {HTMLElement} el
 * @param {object} center
 * @param {number} zoomLevel
 */
export const createMap = ({ el, center, zoomLevel }) => {
  const newMap = new window.Microsoft.Maps.Map(el, {
    center,
    zoom: zoomLevel,
    mapTypeId: window.Microsoft.Maps.MapTypeId.grayscale,
    showMapTypeSelector: false,
    customMapStyle: mapStyles,
    showLocateMeButton: false,
    showScalebar: false,
    disableScrollWheelZoom: true,
  })

  // Remove default Map element style
  newMap._rootElement[0].firstChild.removeAttribute('style')

  return newMap
}
