import { pxToRem } from '../../../../site/js/utils/px-to-rem'

const PARALLAX_ZOOM_SELECTOR = '.parallax.parallax--image_zoom'
const PARALLAX_ZOOM_SELECTOR_ACTIVE = `${PARALLAX_ZOOM_SELECTOR}.parallax--active`

let ticking = false

const initializeZoom = () => {
  const zoomAbleImages = Array.from(document.querySelectorAll(PARALLAX_ZOOM_SELECTOR))

  ParallaxZoomImageObserver(zoomAbleImages)
  handleScroll()

  document.addEventListener('scroll', () => handleScroll())
}

const constrain = (value, min, max) => {
  if (value < min) {
    return min
  }
  if (value > max) {
    return max
  }
  return value
}

const applyZoom = image => {
  const { width, height, top } = image.getBoundingClientRect()
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight
  const scrollRatio = constrain((viewportHeight - top) / viewportHeight, 0, 1.3)
  const zoomRatio = 1 + 0.2 * scrollRatio
  image.style.backgroundSize = `${pxToRem(width * zoomRatio)} ${pxToRem(height * zoomRatio)}`
}

const handleScroll = () => {
  if (!ticking) {
    requestAnimationFrame(update)
    ticking = true
  }
}

const update = () => {
  ticking = false

  const images = Array.from(document.querySelectorAll(PARALLAX_ZOOM_SELECTOR_ACTIVE))
  images.forEach(image => applyZoom(image))
}

const handleIntersection = (entries, _observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('parallax--active')
    } else {
      entry.target.classList.remove('parallax--active')
    }
  })
}

const ParallaxZoomImageObserver = images => {
  if (!images || images.length <= 0) {
    return
  }

  const options = {
    rootMargin: '15%',
  }

  if ('IntersectionObserver' in window) {
    const parallaxZoomImageObserver = new IntersectionObserver(handleIntersection, options)
    images.forEach(image => parallaxZoomImageObserver.observe(image))
  }
}

document.addEventListener('DOMContentLoaded', () => initializeZoom())
