import { initSort } from '@site/js/utils/table-sort'
import { tableRowClickEvent } from '@site/js/utils/tableRowClickEvent'

const WRAPPED_TABLE_CLASS = 'cmp-text__table--wrapped'
const WRAPPER_CLASS = 'cmp-text__table-wrapper'
const WRAPPER_CLASS_REL = 'cmp-text__table-wrapper__relative'
const WRAPPER_CLASS_OVERFLOW = 'cmp-text__table-wrapper--overflow'
const WRAPPER_SELECTOR = `.${WRAPPER_CLASS}`

const ROW_SELECTABLE = 'cmp-text__table--row-selectable'
const CELL_SELECTABLE = 'cmp-text__table--cell-selectable'
const CELL_CLASS = 'cmp-text__table--cell'
const LINK_CLASS = 'cmp-text__table--link'

/**
 * check if an element is overflowing
 */
function checkOverflow(el) {
  const curOverflow = el.style.overflow

  el.style.overflow = 'hidden'

  const isOverflowing = el.clientWidth < el.scrollWidth && el.scrollWidth - el.clientWidth >= 2

  el.style.overflow = curOverflow

  return isOverflowing
}

/**
 * check if wrapper overflows and apply styling
 */
function processOverflow(wrapper) {
  const isOverflowing = checkOverflow(wrapper)

  if (isOverflowing) {
    wrapper.classList.add(WRAPPER_CLASS_OVERFLOW)
  } else {
    wrapper.classList.remove(WRAPPER_CLASS_OVERFLOW)
  }
}

/**
 * wrap tables
 */
function wrapTable(table) {
  const wrapper = document.createElement('div')
  wrapper.classList.add(WRAPPER_CLASS)
  wrapper.setAttribute('tabIndex', '0')

  table.insertAdjacentElement('afterend', wrapper)

  wrapper.appendChild(table)

  table.classList.add(WRAPPED_TABLE_CLASS)

  wrapper.addEventListener('scroll', function () {
    if (wrapper.scrollWidth - wrapper.clientWidth - wrapper.scrollLeft === 0) {
      wrapper.classList.remove(WRAPPER_CLASS_OVERFLOW)
    } else {
      wrapper.classList.add(WRAPPER_CLASS_OVERFLOW)
    }
  })
}

/**
 * check wrappers to see if they need overflow styles
 */
const updateOverflows = function () {
  document.querySelectorAll(WRAPPER_SELECTOR).forEach(wrapper => {
    processOverflow(wrapper)
  })
}

/**
 * Wrap all text component tables and run initial overflow check
 */
document.querySelectorAll(`.cmp-text table:not(.${WRAPPED_TABLE_CLASS})`).forEach(table => {
  wrapTable(table)
  const tableWrapper = table.closest(WRAPPER_SELECTOR)
  const relativeTableWrapper = document.createElement('div')
  relativeTableWrapper.classList.add(WRAPPER_CLASS_REL)

  tableWrapper.insertAdjacentElement('afterend', relativeTableWrapper)

  relativeTableWrapper.appendChild(tableWrapper)
})
updateOverflows()

/**
 * container size change handler
 */
window.addEventListener('resize', updateOverflows)

/**
 * adjust table styles
 */
document.querySelectorAll('.cmp-text table tr').forEach(tableRow => {
  const rowLinks = tableRow.querySelectorAll('a')

  if (rowLinks.length === 1 || (rowLinks.length > 1 && [...rowLinks].every(link => link.href === rowLinks[0].href))) {
    tableRow.classList.add(ROW_SELECTABLE)
  }
  if (rowLinks.length > 1) {
    tableRow.classList.add(CELL_SELECTABLE)
  }
  tableRow.querySelectorAll('td').forEach(tableCell => {
    tableCell.classList.add(CELL_CLASS)
    const cellLinks = tableCell.querySelectorAll('a').length
    if (cellLinks > 1) {
      tableCell.closest('tr').classList.remove(ROW_SELECTABLE)
    }
    if (cellLinks === 1) {
      tableCell.classList.add(LINK_CLASS)
    }
  })
})

/**
 * Add on click event for the table rows using event delegation because of dynamic elements
 */

document.addEventListener('click', function (e) {
  if (e.target.classList.contains(CELL_CLASS)) {
    tableRowClickEvent(e, ROW_SELECTABLE)
  }
})

/**
 * Set up table sorting
 */
initSort()
