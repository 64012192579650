import { trackEvent } from '@site/js/utils/tracking'

const initLogoLinkTracking = () => {
  const logoLinks = document.querySelectorAll('.cmp-header__logo-link, .cmp-navigation__link-logo')
  if (logoLinks) {
  logoLinks.forEach(logoLink => {
    logoLink.addEventListener('click', () => {
      const img = logoLink.querySelector('img')
      const alt = img ? img.alt : ''
      const href = logoLink.href || ''

      trackEvent({
        event: 'interaction',
        eventSource: 'aem',
        action: 'click',
        componentName: 'header',
        interactionType: 'logo',
        title: alt,
        href: href,
      })
    })
  })
}
}

export default initLogoLinkTracking
