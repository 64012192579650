import { cls } from '@react/utils/classname'
import { isMobile } from '@site/js/utils/breakpoint'

import img from '../../../../resources/hitachienergy_partner_logo.svg'

/**
 * Partners - list template
 * @param {object} state
 */
export const template = (state, config) => `
  <br><br>
  <table class="c-mapapp__list-table">
      <thead  class="${cls({
        'u-visually-hidden': isMobile,
      })}">
      <tr>
        <th scope="col">${config.translations.addressLabel}</th>
        <th scope="col">${config.translations.productsLabel}</th>
        <th scope="col">${config.translations.channelTypeLabel}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
    ${state.listData
      .map(
        item => `
      <tr>
        <td>
          <a data-dm="partners-search.link" href="${item.url}" class="u-color--primary">${item.name}</a> <br>

          ${
            item.address
              ? `
            ${item.address.street}<br>
            ${item.address.zipCode}<br>
            ${item.address.city}<br>
            ${item.address.country}
          `
              : ''
          }

        </td>
        <td>
          ${((item.responsibility || {}).products || []).map(product => product)}
        </td>
        <td>
          ${((item.responsibility || {}).channels || []).map(channel => channel)}
        </td>
        <td>
          ${item.displayLogo ? `<img width="160" property="authorized" src="${img}" alt="Authorized value provider"> ` : ''}
        </td>
      </tr>
    `,
      )
      .join('')}
    </tbody>
  </table>
`
