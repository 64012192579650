/**
 * Lightbox
 */
const Lightbox = () => {
  const lightboxSelector = '.lightbox'
  // Selectors list of elements that should be displayed in a lightbox on click
  const lightboxElementsSelectors = [':not(.cmp-teaser__image) > .cmp-image', '.cmp-embed']

  const overlayClassName = 'lightbox__overlay'
  const visibleOverlayClassName = 'lightbox__overlay--visible'
  const contentClassName = 'lightbox__content'

  /**
   * Incerts content into Lightbox overlay and displays the Lightbox
   * @param {HTMLElement} contentHTML content to incert into the Lightbox
   */
  const showOverlay = contentHTML => {
    const overlay = document.querySelector(`.${overlayClassName}`)
    const content = overlay.querySelector(`.${contentClassName}`)
    content.innerHTML = contentHTML
    overlay.classList.add(visibleOverlayClassName)

    if (contentHTML.indexOf('iframe') > -1) {
      reloadIframe(overlay)
    }
  }

  /**
   * Reloads the iframe when is clicked
   * @param {HTMLElement} overlay Lightbox content
   */
  const reloadIframe = overlay => {
    const rand = Math.floor(Math.random() * 1000000 + 1)
    const iframe = overlay.querySelector('iframe')
    const iframeSrc = iframe.src
    iframe.src = iframeSrc + '?uid=' + rand
  }

  /**
   * Hides Lightbox overlay and removes its content
   */
  const hideOverlay = () => {
    const overlay = document.querySelector(`.${overlayClassName}`)
    const content = overlay.querySelector(`.${contentClassName}`)
    overlay.classList.remove(visibleOverlayClassName)
    content.innerHTML = ''
  }

  /**
   *  Adds Lightbox overlay click event listener
   */
  const initOverlay = () => {
    const overlay = document.querySelector(`.${overlayClassName}`)
    overlay.addEventListener('click', event => {
      const isContentClicked = event.target.closest(`.${contentClassName}`)
      if (!isContentClicked) {
        hideOverlay()
      }
    })
  }

  /**
   * Adds Lightbox overlay HTML into the page
   */
  const createOverlay = () => {
    const isOverlayCreated = document.querySelector(`.${overlayClassName}`)
    if (!isOverlayCreated) {
      const overlayElement = document.createElement('div')
      overlayElement.classList.add(overlayClassName)
      const contentElement = document.createElement('div')
      contentElement.classList.add(contentClassName)
      overlayElement.appendChild(contentElement)
      if (document.querySelector('main')) {
        document.querySelector('main').appendChild(overlayElement)

        initOverlay()
      }
    }
  }

  /**
   * Opens lightbox if a clicked element has a parent matches one of the selectors
   * from a list of appropriate to be displayed in the Lightbox
   * @param {event} event
   */
  const openLightbox = event => {
    lightboxElementsSelectors.forEach(selector => {
      const lightboxElement = event.target.closest(selector)
      if (lightboxElement) {
        const imageTitle = lightboxElement.parentElement.querySelector('.cmp-image__title')
        const innerHTML = imageTitle ? lightboxElement.parentElement.innerHTML : lightboxElement.innerHTML
        showOverlay(innerHTML)
      }
    })
  }

  /**
   * initialize
   */
  const init = () => {
    createOverlay()

    document.querySelectorAll(lightboxSelector).forEach(lightbox => lightbox.addEventListener('click', event => openLightbox(event)))
  }

  return {
    init,
  }
}

export { Lightbox }
