import { copyToClipboard } from '../../site/js/utils/copy-to-clipboad'
import { getRequest } from '../../site/js/utils/request'

const linksLibrary = (modalConfig, ABBPopupLinks) => {
  /* Set up Modal container */
  const modalHTML = `
    <div id="abbLibraryModal" class="modal-wrapper modal-wrapper--hidden">
      <div class="abb-credential-modal">
        <span id="closeBtn" class="abb-credential-modal__close-btn icon-close icon--md"></span>
        <h3 class="abb-credential-modal__title">${modalConfig.title}</h3>
        <p class="abb-credential-modal__text">
          ${modalConfig.text}
        </p>
        <div class="abb-credential-modal__url">
          <p class="abb-credential-modal__link">
          </p>
          <button class="abb-credential-modal__copy-btn">
            <span class="icon-copy icon--md"></span>
            <span class="copy-text">${modalConfig.copyButtonLabel}</span>
          </button>
        </div>
      </div>
    </div>
  `
  document.body.insertAdjacentHTML('beforeend', modalHTML)

  const modal = document.getElementById('abbLibraryModal')
  const copyBtn = modal.querySelector('.abb-credential-modal__copy-btn')
  const closeBtn = modal.querySelector('.abb-credential-modal__close-btn')
  const copyText = modal.querySelector('.copy-text')
  const modalLink = modal.querySelector('.abb-credential-modal__link')

  const setModalContent = linkHref => {
    /* Update Modal Link */
    const modalLink = modal.querySelector('.abb-credential-modal__link')
    modalLink.textContent = linkHref

    modal.classList.remove('modal-wrapper--hidden')
  }

  const attachCloseButton = (btn, event = 'click') => {
    btn.addEventListener(event, function () {
      modal.classList.add('modal-wrapper--hidden')
      copyText.innerHTML = modalConfig.copyButtonLabel
      resetCopyAnimation(copyBtn)
    })
  }

  const attachCopyButton = (btn, event = 'click') => {
    btn.addEventListener(event, function () {
      const buttonIcon = btn.querySelector('.icon--md')
      copyToClipboard(modalLink.textContent)

      modalLink.classList.add('abb-credential-modal__link--copied')
      buttonIcon.classList.remove('icon-copy')
      buttonIcon.classList.add('icon-check')
      copyText.innerHTML = modalConfig.copiedButtonLabel
    })
  }

  const resetCopyAnimation = btn => {
    const buttonIcon = btn.querySelector('.icon--md')

    modalLink.classList.remove('abb-credential-modal__link--copied')
    buttonIcon.classList.remove('icon-check')
    buttonIcon.classList.add('icon-copy')
  }

  const configureLinks = () => {
    ABBPopupLinks.forEach(link => {
      link.addEventListener('click', function (e) {
        e.preventDefault()
        setModalContent(link.getAttribute('href'))
      })
    })
  }

  attachCloseButton(closeBtn)
  attachCopyButton(copyBtn)

  configureLinks()
}

window.addEventListener('load', () => {
  /* Check that config data is present */
  const contentSourceLink = document.body.getAttribute('data-abb-links-infomessage-config-url')
  if (!contentSourceLink) {
    return
  }

  /* Check that page contains library page or abandon */
  const ABBPopupLinks = document.querySelectorAll('.abb-library-link')
  if (!ABBPopupLinks.length) {
    return
  }

  getRequest(contentSourceLink)
    .then(response => response.json())
    .then(data => linksLibrary(data.config, ABBPopupLinks))
    .catch(() => {})
})
