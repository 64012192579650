import './CommentReplies.scss'

import { CommentContext, CommentReply, CommentType, useComment } from '@react/Comments'
import { cls } from '@react/utils/classname'
import { useContext } from 'react'

interface CommentRepliesProps {
  comment: CommentType
}
export function CommentReplies({ comment }: CommentRepliesProps) {
  const { translations } = useContext(CommentContext)
  const { numOfReplies } = comment
  const { toggleReply, replies, isRepliesVisible, getReplies } = useComment({
    comment,
  })
  return (
    <ul className="cmp-comment-replies" data-testid="replies">
      <div className="cmp-comment-replies__toggle" onClick={toggleReply} data-testid="replies-toggle">
        <span className="cmp-comment-replies__toggle-text" data-testid="replies-label">
          {numOfReplies} {numOfReplies > 1 || numOfReplies === 0 ? translations.replies : translations.reply}
        </span>
        <span
          className={cls({
            'cmp-button__icon': true,
            'cmp-button__icon--keyboard_arrow_up': isRepliesVisible,
            'cmp-button__icon--keyboard_arrow_down': !isRepliesVisible,
          })}
        ></span>
      </div>
      {isRepliesVisible && replies?.map(reply => reply && <CommentReply key={reply.id} reply={reply} afterSubmit={getReplies} />)}
    </ul>
  )
}
