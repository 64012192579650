import Abbreviation from '@react/Abbreviation'
import { registerReactComponent } from '@react/react-component-registry'

function SortableTable(element, type) {
  const sortType = type
  const table = element
  const headers = Array.from(table.querySelectorAll('th.c-filterable-list__table-th--is-sortable'))
  const tableRows =
    sortType === 'fragmentslist'
      ? Array.from(table.querySelectorAll('tbody tr'))
      : Array.from(table.querySelectorAll('tr:not(:first-of-type)'))
      const sortableColumnIndices = headers.map(header => Array.from(header.parentNode.children).indexOf(header))
  const sortData = (data, column, order) => {
    return data.sort((a, b) => {
      return (
        new Intl.Collator('en', { usage: 'sort', sensitivity: 'base', ignorePunctuation: true, numeric: true }).compare(
          a[column]?.toLowerCase() || '',
          b[column]?.toLowerCase() || '',
        ) * order
      )
    })
  }

  const renderNewRows = data => {
    data.forEach((row, index) => {
      const rowHTML = row.pop()
      tableRows[index].innerHTML = rowHTML
    })
  }

  const setActiveControl = (column, order) => {
    const activeColumnClasses = {
      ACTIVE: 'sortable-header--active',
      ASC: 'sortable-header--asc',
      DESC: 'sortable-header--desc',
    }

    /* convert numeric order to text */
    order = order > 0 ? 'ASC' : 'DESC'

    headers.forEach((header, index) => {
      Object.entries(activeColumnClasses).forEach(activeColumnClass => header.classList.remove(activeColumnClass[1]))
      /* update header controls */
      if (index === column) {
        header.classList.add(Object.values(activeColumnClasses)[0])
        header.classList.add(activeColumnClasses[order])
      }
    })
  }

  const sortTableRows = (columnIndex, order = 1) => {
    /* Update controls UI */
    setActiveControl(columnIndex, order)
    const actualColumnIndex = sortableColumnIndices[columnIndex]

    /* Gather dsata from table */
    const data = []

    tableRows.forEach(row => {
      const rowData = [...Array.from(row.querySelectorAll('td, th')).map(cell => cell.textContent), row.innerHTML]
      data.push(rowData)
    })

    const sortedData = sortData(data, actualColumnIndex, order)
    renderNewRows(sortedData)

    if (sortType === 'fragmentslist') {
      registerReactComponent('Abbreviation', Abbreviation)
    }
  }

  const sortControls = columnLabel => {
    return `
        <div class="sortable-header__wrapper">
            ${columnLabel}
            <div class="sortable-header__controls">
                <svg class="sort-icon sort-icon__up" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 14 7" style="enable-background: new 0 0 14 7;" xml:space="preserve">
                    <polygon class="sort-icon__icon" points="0,7 7,0 14,7 "/>
                </svg>
                <svg class="sort-icon sort-icon__down" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 14 7" style="enable-background: new 0 0 14 7;" xml:space="preserve">
                    <polygon class="sort-icon__icon" points="0,0 7,7 14,0 "/>
                </svg>
            </div>
        </div>
    `
  }

  element.enableSort = () => {
    /* Check that headers exist */
    if (!headers || headers.length <= 0) {
      return
    }

    /* add header controls and actions */
    headers.forEach((header, index) => {
      header.classList.add('sortable-header')

      header.innerHTML = sortControls(header.textContent)

      const controlUp = header.querySelector('.sort-icon__up')
      const controlDown = header.querySelector('.sort-icon__down')

      controlUp.addEventListener('click', () => sortTableRows(index))
      controlDown.addEventListener('click', () => sortTableRows(index, -1))
    })
  }

  return element
}

export function initSort(type) {
  const tables =
    type === 'fragmentslist'
      ? Array.from(document.querySelectorAll('.c-filterable-list__table'))
      : Array.from(document.querySelectorAll('.cmp-text table'))
  tables.forEach(function (table) {
    new SortableTable(table, type).enableSort()
  })
}
