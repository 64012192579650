import './CreateComment.scss'

import { ActionButton, CommentContext } from '@react/Comments'
import { useCallback, useContext } from 'react'

interface CreateCommentProps {
  onChange: (value: string) => void
  value: string
  onAddComment: () => void
}
export function CreateComment({ onChange, value, onAddComment }: CreateCommentProps) {
  const { translations } = useContext(CommentContext)

  const handleChangeText = useCallback(({ target }) => {
    onChange(target.value)
  }, [])

  return (
    <div className="cmp-comments__post" data-testid="create-comment">
      <textarea className="cmp-comments__post-textarea" placeholder={translations.addComment} value={value} onChange={handleChangeText}></textarea>
      <ActionButton
        classNames="cmp-comments__post-button"
        variant="primary"
        disabled={false}
        label={translations.post}
        onClick={onAddComment}
        submit={false}
      />
    </div>
  )
}
