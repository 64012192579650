import { CommentContextType } from '@react/Comments/CommentsTypes'
import { createContext } from 'react'

const defaultContext: CommentContextType = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadComments: (): void => {},
  translations: null,
  deleteOwnCommentUrl: '',
  likeCommentUrl: '',
  createReplyUrl: '',
  updateOwnCommentUrl: '',
  repliesUrl: '',
  commentsUrl: '',
  createCommentUrl: '',
  sortType: {
    sort: 'date',
    sortOrder: 'desc',
  },
}
export const CommentContext = createContext(defaultContext)
