import { pxToRem } from '../../../../site/js/utils/px-to-rem'

const PARALLAX_VERTICAL_DOF_SELECTOR = '.parallax.parallax--vertical_scrolling'
const PARALLAX_VERTICAL_DOF_SELECTOR_ACTIVE = `${PARALLAX_VERTICAL_DOF_SELECTOR}.parallax--active`

let ticking = false

const initializeDOF = () => {
  const dofAbleImages = Array.from(document.querySelectorAll(PARALLAX_VERTICAL_DOF_SELECTOR))

  dofAbleImages.forEach(image => {
    const imageSrc = image.style.backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0]

    image.bgImageObj = new Image()
    image.bgImageObj.src = imageSrc
  })

  ParallaxDOFImageObserver(dofAbleImages)
  handleScroll()

  document.addEventListener('scroll', () => handleScroll())
  window.addEventListener('resize', () => handleScroll())
}

const applyDOF = image => {
  const { width, height, top } = image.getBoundingClientRect()
  const zoomRatio = 1.2

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight

  let backgroundHeight = image.bgImageObj.height
  let backgroundWidth = image.bgImageObj.width

  const setHeight = height * zoomRatio
  const widthAspect = setHeight / backgroundHeight
  backgroundHeight = setHeight
  backgroundWidth *= widthAspect

  if (backgroundWidth < width) {
    const heightAspect = width / backgroundWidth
    backgroundWidth = width
    backgroundHeight *= heightAspect
  }

  const scrollRatio = (viewportHeight - top) / viewportHeight
  const topPosition = height * -0.2 + height * 0.2 * scrollRatio
  image.style.backgroundSize = `${pxToRem(backgroundWidth)} ${pxToRem(backgroundHeight)}`
  image.style.backgroundPosition = `center ${pxToRem(topPosition)}`
}

const handleScroll = () => {
  if (!ticking) {
    requestAnimationFrame(update)
    ticking = true
  }
}

const update = () => {
  ticking = false

  const images = Array.from(document.querySelectorAll(PARALLAX_VERTICAL_DOF_SELECTOR_ACTIVE))
  images.forEach(image => applyDOF(image))
}

const handleIntersection = (entries, _observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('parallax--active')
      handleScroll()
    } else {
      entry.target.classList.remove('parallax--active')
    }
  })
}

const ParallaxDOFImageObserver = images => {
  if (!images || images.length <= 0) {
    return
  }

  const options = {
    rootMargin: '5%',
  }

  if ('IntersectionObserver' in window) {
    const parallaxDofImageObserver = new IntersectionObserver(handleIntersection, options)

    images.forEach(image => parallaxDofImageObserver.observe(image))
  }
}

document.addEventListener('DOMContentLoaded', () => initializeDOF())
