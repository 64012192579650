/* eslint-disable */

const INITIALISED_CLASSNAME = 'cmp-bignumbers__number--initialised'
const NO_ANIMATION_CLASSNAME = 'cmp-bignumbers__number--no-animation'
const COMPLETED_CLASSNAME = 'cmp-bignumbers__number--completed'
const COUNT_SELECTOR = '.cmp-bignumbers__count'
const FRAME_RATE = 60 /* Frames Per Second, typical for RequestAnimationFrame */
export const BIG_NUMBERS_SELECTOR = `.cmp-bignumbers__number`

interface BigNumberConfig {
  startNumber: number
  endNumber: number
  duration: number
  stepNumber?: number
}

interface numberType extends Element {
  modelInstance?: BigNumber
}

export class BigNumber {
  private _element: HTMLElement
  private config: BigNumberConfig
  private frames: number = 0
  private frameAdvance: number = 0
  private count: HTMLSpanElement
  private countValue: number = 0

  constructor(element: HTMLElement) {
    this._element = element as HTMLElement

    this.count = this._element.querySelector(COUNT_SELECTOR)

    this.config = Object.assign({}, JSON.parse(this._element.dataset.config))

    if (isNaN(this.config.endNumber) || isNaN(this.config.startNumber) || isNaN(this.config.duration)) return

    if (this.prefersReducedMotion()) {
      this._element.classList.add(NO_ANIMATION_CLASSNAME)
      return this
    }

    this.initialise()

    return this
  }

  prefersReducedMotion(): boolean {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

    if (mediaQuery.matches) return true

    return false
  }

  initialise() {
    if (this.config.startNumber != this.config.endNumber) {
      this.countValue = this.config.startNumber
      this.displayCount()
    }

    this.frames = Math.floor(FRAME_RATE * this.config.duration)
    this.frameAdvance = (this.config.endNumber - this.config.startNumber) / this.frames

    // @ts-ignore
    this._element.modelInstance = this

    this._element.classList.add(INITIALISED_CLASSNAME)
  }

  displayCount() {
    this.count.textContent = `${Math.round(this.countValue)}`
  }

  getElement(): HTMLElement {
    return this._element
  }

  countUp() {
    if (this.countValue >= this.config.endNumber) return
    if (Math.round(this.countValue + this.frameAdvance) >= this.config.endNumber) {
      this.countValue = this.config.endNumber
      this.displayCount()
      this._element.classList.add(COMPLETED_CLASSNAME)
      return
    }

    this.countValue += this.frameAdvance
    this.displayCount()
    return window.requestAnimationFrame(() => this.countUp())
  }

  startCount() {
    window.requestAnimationFrame(() => this.countUp())
  }

  resetCount() {
    if (this.countValue === this.config.endNumber) {
      this._element.classList.remove(COMPLETED_CLASSNAME, NO_ANIMATION_CLASSNAME)
      this.countValue = this.config.startNumber
      this.initialise()
    }
  }
}

export const BigNumbersObserver = (components: Array<Element>) => {
  if (!components || components.length <= 0) return

  if ('IntersectionObserver' in window) {
    const bigNumbersObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          const number: numberType = entry.target

          if (entry.isIntersecting) {
            if (!number.classList.contains(COMPLETED_CLASSNAME) && !number.classList.contains(NO_ANIMATION_CLASSNAME)) {
              number.modelInstance.startCount()
            }
          } else {
            number.modelInstance.resetCount()
          }
        })
      },
      { threshold: 0.9 },
    )

    components.forEach((component: Element) => bigNumbersObserver.observe(component))
  }
}

/* eslint-enable */
