import { CommentContext } from '@react/Comments/CommentsContext'
import { ActionsVariants, CommentType, SortCommentsType } from '@react/Comments/CommentsTypes'
import { getRequest, postRequest } from '@site/js/utils/request'
import { useContext, useState } from 'react'

interface useCommentProps {
  comment: CommentType
  afterSubmitting?: () => void
  sortType?: SortCommentsType
}

export const useComment = ({ comment, afterSubmitting, sortType }: useCommentProps) => {
  const [fieldValue, setFieldValue] = useState('')
  const [isRepliesVisible, setRepliesVisible] = useState(false)
  const [activeAction, setActiveAction] = useState<ActionsVariants | null>(null)
  const [replies, setReplies] = useState<[] | CommentType[]>([])
  const { id, likedByUser } = comment

  const { loadComments, updateOwnCommentUrl, createReplyUrl, repliesUrl, deleteOwnCommentUrl, likeCommentUrl } = useContext(CommentContext)
  const resetActions = () => {
    setActiveAction(null)
    setFieldValue('')
  }

  const getReplies = async () => {
    try {
      const response = await getRequest(repliesUrl + '?' + new URLSearchParams({ commentId: id, sortOrder: 'asc' }).toString())
      const { content } = await response.json()

      if (content) {
        setReplies(content)
      }
    } catch (error) {
      console.error(error)
    }
  }
  const editComment = async () => {
    const body = new FormData()
    body.append('commentId', id)
    body.append('commentContent', fieldValue)
    try {
      await postRequest(updateOwnCommentUrl, body)
      resetActions()
      await loadComments(sortType)
      if (afterSubmitting) {
        afterSubmitting()
      }
    } catch (error) {
      console.error(error)
    }
  }

  const deleteComment = async () => {
    const body = new FormData()
    body.append('commentId', id)
    try {
      await postRequest(deleteOwnCommentUrl, body)
      if (afterSubmitting) {
        afterSubmitting()
      }
      resetActions()
      setTimeout(() => {
        // this timeout is needed to see the animation
        setActiveAction(null)
        loadComments(sortType)
      }, 100)
    } catch (error) {
      console.error(error)
    }
  }
  const replyComment = async () => {
    const body = new FormData()
    body.append('commentId', id)
    body.append('commentContent', fieldValue)
    try {
      await postRequest(createReplyUrl, body)
      await loadComments(sortType)
      await getReplies()
      resetActions()
    } catch (error) {
      console.error(error)
    }
  }
  const likeComment = async () => {
    const body = new FormData()
    body.append('liked', (!likedByUser).toString())
    body.append('commentId', id)
    try {
      await postRequest(likeCommentUrl, body)
      await loadComments(sortType)
    } catch (error) {
      console.error(error)
    }
  }

  const onSubmit = async () => {
    if (activeAction === ActionsVariants.SHOW_DELETE_DIALOG) {
      setActiveAction(ActionsVariants.DELETE_COMMENT)
      await deleteComment()
    } else if (activeAction === ActionsVariants.EDIT) {
      await editComment()
    } else if (activeAction === ActionsVariants.REPLY) {
      await replyComment()
    }
  }

  const toggleReply = async () => {
    if (!isRepliesVisible) {
      await getReplies()
    }
    setRepliesVisible(prevState => !prevState)
  }

  return {
    editComment,
    deleteComment,
    replyComment,
    fieldValue,
    setFieldValue,
    toggleReply,
    isRepliesVisible,
    activeAction,
    setActiveAction,
    likeComment,
    onSubmit,
    replies,
    getReplies,
  }
}
