import { pxToRem } from '../../site/js/utils/px-to-rem'

const AgendaWatermarkTitle = () => {
  const agendaWrapper = document.querySelector('.specialevent-container > .cmp-container')
  const watermarkParent = document.querySelector('.cmp-specialevent-container__triangle-watermark')
  const watermarkTitle = document.querySelector('.cmp-specialevent-container__triangle-watermark--title')
  const days = document.querySelectorAll('.day')

  const init = () => {
    if (!watermarkTitle || !agendaWrapper || days.length === 0) {
      return
    }
    window.addEventListener('load', function () {
      if (days) {
        watermarkParent.classList.remove('cmp-specialevent-container__triangle-watermark--hidden')
      }
      watermarkParent.style.height = `${pxToRem(watermarkTitle.getBoundingClientRect().height)}`
      updateWatermarkTopPosition()
      window.addEventListener('resize', function () {
        updateWatermarkTopPosition()
      })
    })
  }

  const updateWatermarkTopPosition = () => (watermarkParent.style.top = window.innerHeight * (1 / 100) + 'vh')

  return {
    init,
  }
}
export { AgendaWatermarkTitle }
