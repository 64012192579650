import { trackEvent } from '@site/js/utils/tracking'

const contentElement = document.querySelector('.cmp-banner-cta__content-inner')
const buttonElement = contentElement?.querySelector('.cmp-banner-cta__button')
const titleElement = contentElement?.querySelector('.cmp-banner-cta__title')
const buttonAnchorElement = buttonElement?.querySelector('.cmp-button')
const buttonTextElement = buttonElement?.querySelector('.cmp-button__text')

buttonElement?.addEventListener('click', _e => {
  trackEvent({
    event: 'interaction',
    eventSource: 'aem',
    action: 'click',
    componentName: 'pagestage-banner-cta',
    interactionType: 'button',
    text: buttonTextElement?.textContent,
    title: titleElement?.textContent.trim(),
    href: buttonAnchorElement?.href,
  })
})
