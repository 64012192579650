import {
  dropdownContentSelector,
  fixedDropdownClassName,
  header,
  navigation,
  openDropdownClassName,
  overlay,
  search,
  visibleOverlayClassName,
} from '@components/header/headerConstants'
import { isMobileHeader } from '@site/js/utils/breakpoint'

/**
 *  Search in sticky header
 */

export const StickyHeaderSearch = () => {
  const clickOnStickyHeaderSearch = () => {
    const stickyContent = document.querySelectorAll('.cmp-header__top-item--sticky-content')[1]
    // the check for the button below is because the buttons structure in the sticky header on web and intranet is different
    // web: contact us + search button; intranet: only search button
    const searchButton = document.querySelector('.cmp-header--web')
      ? stickyContent?.querySelectorAll('.cmp-button')[1]
      : stickyContent?.querySelector('.cmp-button')
    searchButton?.addEventListener('click', () => {
      const isDropdownOpen = search?.classList.contains(openDropdownClassName)
      if (!isDropdownOpen) {
        search?.setAttribute('aria-expanded', 'true')
        search.querySelector(dropdownContentSelector)?.classList.add(openDropdownClassName, fixedDropdownClassName)
        overlay?.classList.add(visibleOverlayClassName)
        header?.querySelector('input')?.focus()
        navigation?.classList.remove('cmp-navigation--expanded')
        const itemsLevel0 = navigation?.querySelectorAll('.cmp-navigation__item--level-0')
        itemsLevel0?.forEach(item => {
          item.classList.remove('cmp-navigation__item--expanded')
          if (!isMobileHeader) {
            item.classList.remove('cmp-navigation__item--selected')
          }
        })
      }
      initializeSearchButtonClick()
    })
  }

  const initializeSearchButtonClick = () => {
    const searchWrapper = document.querySelectorAll('.cmp-saas__search-input-wrapper')[1]
    searchWrapper?.addEventListener('click', e => {
      if (e.target.classList.contains('cmp-saas__suggestion-element')) {
        // There are 2 searches (in header and sticky)  being rendered on the page. Both come from SASS (search as a service).
        // Upon click on the suggestion items, the code in searchInput.ts will trigger a click on the first rendered “search” button.
        // The code below is to make sure all input fields are in sync with the same value, in case this first button is triggered first
        document.querySelectorAll('.cmp-saas__search-input')[0].value = e.target.textContent
        document.querySelectorAll('.cmp-saas__button')[1].click()
      }
    })
  }

  const init = () => {
    clickOnStickyHeaderSearch()
  }

  return {
    init,
  }
}
