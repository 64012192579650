export const AA_SESSION_ID_COOKIE_NAME = 'aa_sessionID'
export const AA_SESSION_ID = 'aa_session_id'
export const AA_UID = 'aa_uid'
export const GA_SESSION_ID = 'ga_session_id'
export const GA_UID = 'ga_uid'

export const parseCookies = (): Record<string, string> => {
  const output = {}
  document.cookie.split(/\s*;\s*/).forEach(function (pair) {
    const splitPair = pair.split(/\s*=\s*/)
    const name = decodeURIComponent(splitPair[0])
    const value = decodeURIComponent(splitPair.splice(1).join('='))
    if (name) {
      output[name] = value
    }
  })
  return output
}

const getAnalyticsIdFromCookies = (regex: RegExp): string => {
  const match = document.cookie.match(regex)
  return match?.[1]?.split(';')[0]
}

const getAnalyticsId = (regex: RegExp, key: string): Record<string, string> => {
  const analyticsId = getAnalyticsIdFromCookies(regex)
  return { [key]: analyticsId }
}

export const getGoogleAnalyticsId = (): Record<string, string> => getAnalyticsId(/(GA\d+\.\d+\.\d{8,}\.\d{8,})/, GA_UID)
export const getAdobeAnalyticsId = (): Record<string, string> => getAnalyticsId(/%7CMCMID%7C(.*?)%7C/, AA_UID)
export const getDynamicSessionId = (): Record<string, string> => getAnalyticsId(/_ga_.*?=(.*?);?$/, GA_SESSION_ID)

export const getCookieValue = (cookieKey: string): string => {
  const parsedCookies = parseCookies()
  return parsedCookies?.[cookieKey]
}

export function getAnalyticsDataForDataLayer(): Record<string, string> {
  return { ...getGoogleAnalyticsId(), ...getAdobeAnalyticsId(), [AA_SESSION_ID]: getCookieValue(AA_SESSION_ID_COOKIE_NAME), ...getDynamicSessionId() }
}
