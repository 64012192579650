import './TheWallTeasersItem.scss'

import Icon from '@react/Atoms/Icon'
import ResponsiveImg from '@react/ResponsiveImage'
import { FetchTeasers } from '@react/TheWall'

type TheWallTeasersItemProps = {
  teaser: FetchTeasers
  index: number
}

export default function TheWallTeasersItem({ teaser, index }: TheWallTeasersItemProps) {
  return (
    <li data-step={index} className="cmp-the-wall-teasers-item">
      <a href={teaser.link}>
        <ResponsiveImg config={teaser.image} />
        <h3 className="cmp-the-wall-teasers-item__header">
          <span className="cmp-the-wall-teasers-item__text">{teaser.title}</span>
          <Icon iconName="keyboard_arrow_right" />
        </h3>
      </a>
    </li>
  )
}
