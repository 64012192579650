import { arrayOf } from '../../../site/js/utils/utils'
import { setLocationFilters, zoomTo } from './behaviours'

/**
 * Creates a cluster layer
 * @param {object} map
 * @param {object} state
 * @param {Array} pushpins
 */
export const createClusterLayer = ({ map, state, pushpins, clusterGridSize, clusterMaxZoom }) => {
  /**
   * event handler - on cluster click
   * - sets the location filters - based on the cluster location
   * - zooms to location
   * @param {*} e
   */
  const onClusterClick = e =>
    arrayOf(e.target.containedPushpins).map(pushpins => {
      zoomTo({ map, pushpins, maxZoom: clusterMaxZoom })
      setLocationFilters({ state, pushpins })
    })

  /**
   * Customizes the clusters
   * @param {*} cluster
   */
  const customizeClusteredPin = cluster => {
    // sums the count from the pin data
    // defaults to 1 if no data.count property exist on the pin
    const clusterCountReducer = (count, pin) => count + (pin.data.count ? parseInt(pin.data.count) : 1)

    // Define variables for minimum cluster radius, and how wide the outline area of the circle should be.
    const minRadius = 18
    const outlineWidth = 7

    // Get the number of pushpins in the cluster
    const clusterSize = cluster.containedPushpins.reduce(clusterCountReducer, 0)

    // Calculate the radius of the cluster based on the number of pushpins in the cluster.
    const radius = (Math.log(clusterSize) / Math.log(10)) * 5 + minRadius

    // Default cluster color - bigger then 99 pins
    let fillColor = 'hsl(350, 100%, 30%)'
    let strokeColor = 'hsla(350, 100%, 30%, .5)'
    if (clusterSize < 10) {
      // color if less than 10 pushpins in it.
      fillColor = 'hsl(350, 100%, 40%)'
      strokeColor = 'hsla(350, 100%, 40%, .5)'
    } else if (clusterSize < 100) {
      // color if there are 10 to 99 pushpins in it.
      fillColor = 'hsl(350, 100%, 35%)'
      strokeColor = 'hsla(350, 100%, 35%, .5)'
    }

    // Create an SVG string of two circles, one on top of the other, with the specified radius and color.
    const svg = [
      '<svg xmlns="http://www.w3.org/2000/svg" width="',
      radius * 2,
      '" height="',
      radius * 2,
      '">',
      '<circle cx="',
      radius,
      '" cy="',
      radius,
      '" r="',
      radius,
      '" fill="',
      strokeColor,
      '"/>',
      '<circle cx="',
      radius,
      '" cy="',
      radius,
      '" r="',
      radius - outlineWidth,
      '" fill="',
      fillColor,
      '"/>',
      '</svg>',
    ]

    // Customize the clustered pushpin using the generated SVG and anchor on its center.
    cluster.setOptions({
      text: clusterSize.toString(),
      animate: true,
      icon: svg.join(''),
      anchor: new window.Microsoft.Maps.Point(radius, radius),
      textOffset: new window.Microsoft.Maps.Point(0, radius - 8), // Subtract 8 to compensate for height of text.
    })

    window.Microsoft.Maps.Events.addHandler(cluster, 'click', onClusterClick)
  }

  // Waits for the clustering module to load
  return new Promise(resolve => {
    window.Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', () => {
      // Create a ClusterLayer and add it to the map.
      const clusterLayer = new window.Microsoft.Maps.ClusterLayer(pushpins, {
        clusteredPinCallback: customizeClusteredPin,
        gridSize: clusterGridSize,
      })

      resolve(clusterLayer)
    })
  })
}
