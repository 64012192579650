/**
 * Retrieves the name of the component which this element is part of
 * and returns data object with prefilled name dm attributes.
 */

export const DmAttributes = mapEl => {
  const componentDecorationDivEl = mapEl.closest('div[data-dm*=".component"]')
  const componentName = componentDecorationDivEl ? componentDecorationDivEl.dataset.dm.split('.component')[0] + '.' : ''

  return {
    dmAttributes: {
      search: {
        searchInput: `${componentName}search.input`,
        searchButton: `${componentName}search.button`,
      },
      select: `${componentName}`,
    },
  }
}
