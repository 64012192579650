import { request } from '../../../site/js/utils/request/index'

/**
 * responsible for retriving map data
 * @param {object} configuration
 */
export const MapService = ({ state, url }) => {
  /**
   * initalizes filters and renders the list
   * @param {object} data
   */
  const resolved = data => {
    state.mapData = data.items
  }

  /**
   * handles rejected request
   * @param {string} err
   */
  const rejected = err => {
    console.error('rejected', err)
    state.mapData = []
  }

  /**
   * request data from url
   */
  const get = url => {
    url ? request(url).fork(rejected, resolved) : rejected('error')
  }

  get(url)
}
