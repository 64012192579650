const getLocationHash = () => {
  return location.hash
}

const setActiveTab = tab => {
  const headerHeight = document.querySelector('.header')?.clientHeight || 0
  setTimeout(() => {
    const elementPosition = tab.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - headerHeight
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }, 0)

  const activeTabWithMediaGallery = document.querySelector('.cmp-tabs__tabpanel--active .mediagallery:not(.custom-event-fired)')

  if (activeTabWithMediaGallery) {
    const changeTabEvent = new CustomEvent('changetab')
    window.dispatchEvent(changeTabEvent)
    activeTabWithMediaGallery.classList.add('custom-event-fired')
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const tabs = Array.from(document.querySelectorAll('.cmp-tabs__tab'))
  const locationHash = getLocationHash()

  if (tabs.length) {
    tabs.forEach(el => {
      const elementId = `#${el.id}`
      el.addEventListener('click', () => {
        setActiveTab(el)
      })

      if (locationHash && elementId === locationHash) {
        setActiveTab(el)
      }
    })
  }
})
