import { pushInteractionToolbarDataAnalytics } from './interactiontoolbarUtils'

const Yammer = () => {
  const yammer = document.querySelector('.cmp-interaction-toolbar__share-yammer')

  const init = () => {
    if (yammer) {
      yammer.addEventListener('click', () =>
        pushInteractionToolbarDataAnalytics({
          componentName: 'share-yammer',
          title: 'interaction-toolbar',
        }),
      )
    }
  }

  return {
    init,
  }
}

export { Yammer }
