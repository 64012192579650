// eslint-disable-next-line max-params
const triggerScroll = (scrollableItems, startItem, topOffsetModifier, scrollDistanceModifier, bottomOffsetModifier) => {
  const windowHeight = window.innerHeight * (1 + bottomOffsetModifier)
  const maxScrollDistance = 0 - window.innerHeight * scrollDistanceModifier
  const finalPositionOffset = window.innerHeight * topOffsetModifier
  const scrollWindow = windowHeight - finalPositionOffset

  for (let i = startItem - 1; i < scrollableItems.length; i++) {
    const section = scrollableItems[i]
    const topOffset = section.getBoundingClientRect().top
    if (topOffset <= finalPositionOffset) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      section.style['margin-top'] = maxScrollDistance + 'px'
      continue
    }
    if (topOffset >= windowHeight) {
      section.style['margin-top'] = '0px'
      continue
    }
    const spaceScrolled = scrollWindow - topOffset + finalPositionOffset
    const newMargin = (maxScrollDistance * spaceScrolled) / scrollWindow
    section.style['margin-top'] = newMargin + 'px'
  }
}
const getModifierValue = (element, name, defaultValue) => {
  let value = element.dataset[name]
  return typeof value === 'undefined' ? defaultValue : value - 0
}
export const initParallax = (parentContainer, isScrollIndicatorClicked = false) => {
  const scrollableItems = parentContainer.querySelectorAll('.parallax-scroll-item')
  if (scrollableItems.length === 0) {
    return
  }
  const startItem = getModifierValue(parentContainer, 'startItem', 1)
  if (scrollableItems.length < startItem) {
    return
  }
  const topOffsetModifier = getModifierValue(parentContainer, 'topOffsetModifier', -0.1)
  const scrollDistanceModifier = getModifierValue(parentContainer, 'scrollDistanceModifier', 0.4)
  const bottomOffsetModifier = getModifierValue(parentContainer, 'bottomOffsetModifier', -0.4)
  document.addEventListener(
    'scroll',
    () => {
      scrollableItems[0].style['margin-top'] = '0px'
      if (!isScrollIndicatorClicked) {
        triggerScroll(scrollableItems, startItem, topOffsetModifier, scrollDistanceModifier, bottomOffsetModifier)
      }
    },
    { passive: true },
  )

  if (isScrollIndicatorClicked) {
    setTimeout(() => {
      isScrollIndicatorClicked = false
    }, 1000)
  }

  // Locally, testing with this line only, the parallax is not working
  // If the parallax is broken, try to uncomment the line below, otherwise we can delete it
  // triggerScroll(scrollableItems, startItem, topOffsetModifier, scrollDistanceModifier, bottomOffsetModifier, isScrollIndicatorClicked)
}
document.addEventListener('DOMContentLoaded', () => {
  const containers = document.querySelectorAll('.parallax-scroll-container')
  if (containers.length === 0) {
    return
  }
  for (let i = 0; i < containers.length; i++) {
    initParallax(containers[0])
  }
})
