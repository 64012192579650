import { trackEvent } from '@site/js/utils/tracking'

const footerLink = document.querySelector('.cmp-footercmp__rich-text--copyrightsLink a')

footerLink?.addEventListener('click', e => {
  const element = e.target as HTMLAnchorElement
  trackEvent({
    event: 'interaction',
    eventSource: 'aem',
    action: 'click',
    componentName: 'footer',
    interactionType: 'link',
    title: element.innerText,
    href: element.href,
  })
})
