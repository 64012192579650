const carouselSelector = '.cmp-carousel'
const carouselIndicators = '.cmp-carousel__indicator'
const activeIndicator = '.cmp-carousel__indicator--active'
const pastIndicator = 'cmp-carousel__indicator--past'

const carousels = document.querySelectorAll(carouselSelector)

if (carousels) {
  // eslint-disable-next-line sonarjs/cognitive-complexity
  carousels.forEach(carousel => {
    const indicators = carousel.querySelectorAll(carouselIndicators)
    const nextArrow = carousel.querySelector('.cmp-carousel__action--next')
    const previousArrow = carousel.querySelector('.cmp-carousel__action--previous')

    indicators.forEach((indicator, _id) => {
      indicator.style.width = String(100 / indicators.length) + '%'
    })

    if (nextArrow) {
      nextArrow.addEventListener('click', () => {
        const activeItemId = parseInt(carousel.querySelector(activeIndicator).dataset.indicatorid)
        indicators.forEach(indicator => {
          indicator.classList.remove(pastIndicator)
          if (activeItemId + 1 <= indicators.length && parseInt(indicator.dataset.indicatorid) <= activeItemId) {
            indicator.classList.add(pastIndicator)
          }
        })
      })
    }

    if (previousArrow) {
      previousArrow.addEventListener('click', () => {
        const activeItemId = parseInt(carousel.querySelector(activeIndicator).dataset.indicatorid)
        indicators.forEach(indicator => {
          indicator.classList.remove(pastIndicator)
          if (parseInt(indicator.dataset.indicatorid) < activeItemId || activeItemId === 1) {
            indicator.classList.add(pastIndicator)
          }
        })
      })
    }
  })
}
