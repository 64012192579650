import { trackEvent } from '@site/js/utils/tracking'

window.addEventListener('DOMContentLoaded', () => {
  const fullwidthhorizontalteaser: Element[] = Array.from(document.querySelectorAll('.cmp-full-width-horizontal-teaser'))

  if (!fullwidthhorizontalteaser || fullwidthhorizontalteaser.length <= 0) {
    return
  }

  fullwidthhorizontalteaser.forEach(function (teaser: Element) {
    const ctaButton = teaser.querySelector('.cmp-full-width-horizontal-teaser__button > a')
    const innerText = teaser.querySelector('.cmp-full-width-horizontal-teaser__button-text').innerHTML
    const title = teaser.querySelector('.cmp-full-width-horizontal-teaser__title').innerHTML
    const href = `${window.location.origin}${ctaButton?.getAttribute('href')}`

    ctaButton?.addEventListener('click', function () {
      trackEvent({
        event: 'interaction',
        eventSource: 'aem',
        action: 'click',
        componentName: 'fullWidthHorizontalTeaser',
        interactionType: 'button',
        title,
        innerText,
        href,
      })
    })
  })
})
