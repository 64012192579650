/**
 * Profile Component
 */
const Profile = () => {
  const header = document.querySelector('.cmp-header')
  // User profile items
  const logOutButton = document.querySelectorAll('.cmp-header__logout')
  const logInButton = document.querySelectorAll('.cmp-header__login')
  const logOuUserName = document.querySelectorAll('.cmp-header__logout .cmp-header__top-item-username')
  let userName = ''
  let profileData = {}

  /**
   * Getting user status from ClientContext
   */
  const checkIfLogIn = () => {
    if (profileData) {
      userName = profileData.name === undefined ? '' : profileData.name
      return profileData.authorizableId_xss !== undefined && profileData.authorizableId_xss !== 'anonymous'
    }
    return false
  }

  /**
   * Toggle visibility on buttons depending on 'visibility' parameter
   */
  const removeVisibility = (btn, visibility) => {
    if (visibility) {
      btn.classList.add('cmp-header__top-item--hidden')
    } else {
      btn.classList.remove('cmp-header__top-item--hidden')
    }
  }

  /**
   * It writes new name profile
   */
  const changeLogoutUserNameValue = userNameValue => {
    logOuUserName.forEach(item => {
      item.innerHTML = userNameValue
    })
  }

  // check if author mode
  const isAuthorMode = () => {
    const headerTopContent = header.querySelector('.cmp-header__top-content')
    return (
      headerTopContent !== undefined &&
      headerTopContent.hasAttribute('data-is-author-mode') &&
      headerTopContent.getAttribute('data-is-author-mode') === 'true'
    )
  }

  // Update profile buttons: for each state(login/logout) there are 2. One on desktop and one for mobile view
  const getUserName = () => {
    if (checkIfLogIn()) {
      // if user is login => logoutButton visible
      logInButton.forEach(item => {
        removeVisibility(item, true)
      })
      logOutButton.forEach(item => {
        removeVisibility(item, false)
      })
    } else {
      // if user is logout => loginButton visible
      logInButton.forEach(item => {
        removeVisibility(item, false)
      })
      logOutButton.forEach(item => {
        removeVisibility(item, true)
      })
    }

    // updating nameProfile when is preview mode
    changeLogoutUserNameValue(userName)
  }

  /**
   * initialize
   */
  const init = async () => {
    if (!header) {
      return
    }

    const ms = Date.now()
    const response = await fetch('/libs/granite/security/currentuser.json?currentms=' + ms)

    if (!response.ok) {
      console.warn('Not able to check if user is logged in. Skip displaying data and return empty object')
      return {}
    }

    profileData = await response.json()
    getUserName()
    if (isAuthorMode()) {
      logOuUserName.forEach(item => {
        removeVisibility(item, true)
      })
    }
  }

  return {
    init,
  }
}

export { Profile }
