export const getSearchParams = (url = window.location.href) => new URL(url).search

export const replaceUrlSearchParamValue = (param: string, replaceParam: string, url = window.location.href): string => {
  const urlObject = new URL(url)
  const modifiedUrl = new URLSearchParams(urlObject.search)

  if (modifiedUrl.has(param)) {
    modifiedUrl.set(param, replaceParam)
  }

  urlObject.search = decodeURIComponent(modifiedUrl.toString())
  return urlObject.href
}

export const getDocumentReferrerHostname = () => {
  return document.referrer ? new URL(document.referrer).origin : window.location.origin
}
