import { pxToRem } from '../../../site/js/utils/px-to-rem'

const PARALLAX_VIDEO_VERTICAL_DOF_SELECTOR = '.cmp-videocontainer__video--parallax-vertical_scrolling'
const PARALLAX_VIDEO_VERTICAL_DOF_SELECTOR_ACTIVE = `${PARALLAX_VIDEO_VERTICAL_DOF_SELECTOR}.parallax--active`

let ticking = false

const initializeDOF = () => {
  const dofAbleVideos = Array.from(document.querySelectorAll(PARALLAX_VIDEO_VERTICAL_DOF_SELECTOR))

  dofAbleVideos.forEach(video => {
    video.videoChild = video.querySelector('.cmp-videocontainer__dynamicmediaplayer, .cmp-pagestage__dynamicmediaplayer')
  })

  ParallaxDOFvideoObserver(dofAbleVideos)
  handleScroll()

  document.addEventListener('scroll', () => handleScroll())
  window.addEventListener('resize', () => handleScroll())
}

const applyDOF = video => {
  const { height, top } = video.getBoundingClientRect()
  const zoomRatio = 1.2

  const viewportHeight = window.innerHeight || document.documentElement.clientHeight

  const scrollRatio = (viewportHeight - top) / viewportHeight
  const topPosition = height * -0.2 + height * 0.2 * scrollRatio
  video.videoChild.style.transform = `scale(${zoomRatio}) translate3d(0, ${pxToRem(topPosition)}, 0)`
}

const handleScroll = () => {
  if (!ticking) {
    requestAnimationFrame(update)
    ticking = true
  }
}

const update = () => {
  ticking = false

  const videos = Array.from(document.querySelectorAll(PARALLAX_VIDEO_VERTICAL_DOF_SELECTOR_ACTIVE))
  videos.forEach(video => applyDOF(video))
}

const handleIntersection = (entries, _observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('parallax--active')
      handleScroll()
    } else {
      entry.target.classList.remove('parallax--active')
    }
  })
}

const ParallaxDOFvideoObserver = videos => {
  if (!videos || videos.length <= 0) {
    return
  }

  const options = {
    rootMargin: '10%',
  }

  if ('IntersectionObserver' in window) {
    const parallaxDofvideoObserver = new IntersectionObserver(handleIntersection, options)

    videos.forEach(video => parallaxDofvideoObserver.observe(video))
  }
}

document.addEventListener('DOMContentLoaded', () => initializeDOF())
