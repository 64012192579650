import './TheWall.scss'

import Carousel from '@react/Carousel'
import { TheWallContext } from '@react/TheWall/TheWallContext'
import TheWallTabList from '@react/TheWall/TheWallTabList'
import TheWallTeasersList from '@react/TheWall/TheWallTeasersList'
import { useEffect, useState } from 'react'

export type FetchTeaserImage = {
  alt: string
  caption: string
  generateSources: boolean
  originalHeight: number
  originalWidth: number
  pattern: string
  placeholder: string
}

export type FetchTeasers = {
  title: string
  link: string
  externalLink: boolean
  image: FetchTeaserImage
}

type FetchTopic = {
  topic: string
  teasers: FetchTeasers[]
}

export type FetchData = {
  title: string
  items: FetchTopic[]
}

type TheWallProps = {
  data: string
}

export default function TheWall({ data }: TheWallProps) {
  const [contextData, setContextData] = useState(null)
  const [currentTopic, setCurrentTopic] = useState(null)

  useEffect(() => {
    try {
      const parsedData: FetchData = JSON.parse(data)
      setContextData(parsedData)
      setCurrentTopic(parsedData.items[0].topic)
    } catch (err) {
      setContextData(null)
      setCurrentTopic(null)
    }
  }, [data])

  if (contextData === null || currentTopic === null || contextData.items === null) {
    return null
  }

  return (
    <TheWallContext.Provider
      value={{
        contextData,
        setContextData,
        currentTopic,
        setCurrentTopic,
      }}
    >
      <div className="cmp-the-wall">
        <h2 className="cmp-the-wall__title">{contextData.title}</h2>
        <TheWallTabList />
        <Carousel
          componentList={({ setElement, updateStep }) => <TheWallTeasersList setElement={setElement} updateStep={updateStep} />}
          numberOfSlide={contextData.items[0].teasers.length}
        ></Carousel>
      </div>
    </TheWallContext.Provider>
  )
}
