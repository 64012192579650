import './infobox.scss'

/**
 * Template
 * @param {array} data
 */
const infoboxTemplate = data => `
  <h4 class="infobox__title">
    ${
      data.website
        ? `<a target="_blank" rel="noreferrer noopener" class="infobox__title-link" href="${data.website}">
      ${data.title}
    </a>`
        : data.title
    }
  </h4>
  <table>
    ${Object.keys(data)
      .map(key =>
        key === 'title' || key === 'website'
          ? ''
          : `
      <tr>
        <th>${key}</th>
        <td>${data[key]}</td>
      </tr>
    `,
      )
      .join('')}
  </table>
`

/**
 * Creates a new infobox
 * - the same infobox is used for all infoboxes
 * @param {object} configuration
 */
export const createInfobox = ({ center, map }) => {
  // create new infobox
  const infobox = new window.Microsoft.Maps.Infobox(center, {
    visible: false,
  })

  infobox.setMap(map)

  /**
   * closes/hides infobox
   */
  infobox.close = () => {
    infobox.setOptions({ visible: false })
  }

  /**
   * Opens the infobox
   * @param {object} info data
   */
  infobox.open = (info, location) => {
    if (!info || !location) {
      return
    }

    // open infobox options with the metadata of the pushpin.
    infobox.setOptions({
      location,
      // title: e.target.data.infobox.title,
      description: infoboxTemplate(info),
      visible: true,
      maxWidth: 636,
      maxHeight: 200,
    })

    // fix bing map infobox arrow glitch
    infobox.setOptions({ visible: false })
    infobox.setOptions({ visible: true })

    // get infobox into the view of the map
    const bounds = window.Microsoft.Maps.LocationRect.fromLocations(location)
    map.setView({ bounds })
    // adjust zoom
    if (map.getZoom() > 15) {
      map.setView({ zoom: 15 })
    }
    // add offset

    map.setView({ center: map.getCenter(), centerOffset: new window.Microsoft.Maps.Point(0, 40) })
  }

  // add click handler - hide infobox when clicked outside
  window.Microsoft.Maps.Events.addHandler(map, 'click', infobox.close)
  addEventListener('mapapp-filters-reset', infobox.close)
  addEventListener('mapapp-filters-changed', infobox.close)

  return infobox
}
