import { request } from '../../../site/js/utils/request/index'

/**
 * responsible for retriving list data
 * @param {object} configuration
 */
export const ListService = ({ state, url }) => {
  /**
   * gets selected filters state from server response
   * @param {array} data
   */
  const getSelectedFromResponse = filters => {
    const selected = {}
    filters.forEach(filter => {
      filter.options.forEach(option => {
        if (option.selected && option.key !== '') {
          selected[filter.property] = selected[filter.property] || []
          selected[filter.property].push(option.key)
        }
      })
    })
    return selected
  }

  /**
   * initalizes filters and renders the list
   * @param {object} data
   */
  const resolved = data => {
    // update selected filters with the filters state from the server response
    const selected = getSelectedFromResponse(data.filters)
    if (state.selectedFilters.search) {
      selected.search = state.selectedFilters.search
    }
    // set values without change event
    Object.keys(state.selectedFilters).forEach(key => {
      if (selected[key]) {
        state.selectedFilters[key] = selected[key]
      } else {
        delete state.selectedFilters[key]
      }
    })

    state.selectedFilters.page = data.currentPage
    state.listTotal = data.results
    state.listTotalPages = data.pages
    state.listData = data.items
    state.filtersData = data.filters
    state.loading = false
  }

  /**
   * handles rejected request
   * @param {string} err
   */
  const rejected = err => {
    console.error('rejected', err)
    state.listData = 'error'
    state.loading = false
  }

  /**
   * request data from url
   */
  const get = urlString => {
    urlString ? request(urlString).fork(rejected, resolved) : rejected('error')
  }

  /**
   * build url with selected filters and send request
   */
  const getData = () => {
    state.loading = true
    const urlString = url + '?' + new URLSearchParams(state.selectedFilters).toString()
    get(urlString)
  }

  state.watch('selectedFilters', getData)

  getData()
}
