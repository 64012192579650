import './DeleteAction.scss'

import { cls } from '@react/utils/classname'

type DeleteActionProps = {
  onClick: () => void
  label: string
  classNames: string
}
export function DeleteAction({ onClick, label, classNames }: DeleteActionProps) {
  return (
    <div className={cls({ 'cmp-delete-action': true, [classNames]: true })} onClick={onClick} data-testid="delete-action">
      <span className="cmp-button__icon cmp-button__icon--trash"></span>
      {label}
    </div>
  )
}
