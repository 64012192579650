import './Comments.scss'

import { Comment, CommentContext, CreateComment, SortComments, SortCommentsType, useComments } from '@react/Comments'
import { useEffect, useState } from 'react'
interface CommentsProps {
  likeCommentUrl: string
  createReplyUrl: string
  repliesUrl: string
  updateOwnCommentUrl: string
  deleteOwnCommentUrl: string
  translationsData: string
  commentsUrl: string
  createCommentUrl: string
  commentsSectionDisabled: string
}

export function Comments({
  translationsData,
  deleteOwnCommentUrl,
  likeCommentUrl,
  createReplyUrl,
  updateOwnCommentUrl,
  repliesUrl,
  commentsUrl,
  createCommentUrl,
  commentsSectionDisabled,
}: CommentsProps) {
  const [sortType, setSortType] = useState<SortCommentsType>({ sort: 'date', sortOrder: 'desc' })
  const urls = {
    deleteOwnCommentUrl,
    likeCommentUrl,
    createReplyUrl,
    updateOwnCommentUrl,
    repliesUrl,
    commentsUrl,
    createCommentUrl,
  }

  const {
    sortComments,
    isLoadMoreVisible,
    handleChangeCommentText,
    commentFieldValue,
    translations,
    loadComments,
    comments,
    numOfComments,
    loadMore,
    addComment,
  } = useComments({
    translation: translationsData,
    ...urls,
  })
  useEffect(() => {
    loadComments()
  }, [])

  const handleSortComments = (params: SortCommentsType) => {
    setSortType(params)
    sortComments(params)
  }

  const handleLoadMore = () => {
    loadMore(sortType)
  }

  return (
    <CommentContext.Provider value={{ ...urls, loadComments, translations, sortType }}>
      <div className="cmp-comments">
        <h5 className="cmp-comments__title">{translations.comments}</h5>
        <div className="cmp-comments__header">
          <SortComments sortComments={handleSortComments} />
          <div className="cmp-comments__header-counter">
            {numOfComments} {numOfComments > 1 || numOfComments === 0 ? translations.comments : translations.comment}
          </div>
        </div>
        {commentsSectionDisabled !== 'true' && (
          <CreateComment onChange={handleChangeCommentText} value={commentFieldValue} onAddComment={addComment} />
        )}
        {comments.map(comment => comment && <Comment key={comment.id} comment={comment} />)}
        {isLoadMoreVisible && (
          <button className="cmp-comments__load-more" onClick={handleLoadMore}>
            {translations.loadMore} <span className="icon-keyboard_arrow_down icon--sm"></span>
          </button>
        )}
      </div>
    </CommentContext.Provider>
  )
}
