import { cls } from '@react/utils/classname'

type EditActionProps = {
  onClick: () => void
  label: string
  classNames: string
}
export function EditAction({ onClick, label, classNames }: EditActionProps) {
  return (
    <div className={cls({ 'cmp-edit-action': true, [classNames]: true })} onClick={onClick} data-testid="edit-action">
      <span className="cmp-button__icon cmp-button__icon--edit"></span>
      {label}
    </div>
  )
}
