import { cls } from '@react/utils/classname'

type ActiveLink = {
  id: string
  isClicked: boolean
}

type AnchorNavigationElementProps = {
  isActive: boolean
  id: string
  label: string
  index: number
  setActiveLink: (activeLink: ActiveLink) => void
  expanded: boolean
}

export default function AnchorNavigationElement({ isActive, id, label, index, setActiveLink, expanded }: AnchorNavigationElementProps) {
  const expandedFirstItemStyles = expanded && index === 0

  return (
    <li
      id={`tab-${id}`}
      className={cls({
        'cmp-anchor-navigation__item': true,
        'cmp-anchor-navigation__item--active': isActive,
        'cmp-anchor-navigation__item--has-expanded-first-item': expandedFirstItemStyles,
      })}
      aria-selected={isActive}
      onClick={e => {
        e.preventDefault()
        setActiveLink({ id, isClicked: true })
      }}
    >
      <a className="cmp-anchor-navigation__link" href={`#${id}`} data-dm="anchornavigation.link">
        {label}
      </a>
    </li>
  )
}
