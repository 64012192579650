import './TeamsNotificationsInPage.scss'

import { getCookieValue } from '@site/js/utils/cookies'
import { useEffect, useState } from 'react'

import { getRequest } from '../../site/js/utils/request'
import TeamsNotifications, { TeamsNotificationsProps } from '../TeamsNotifications'

type TeamsNotificationsInPageProps = {
  endpointUrl: string
  forceDisplay?: string
}

export default function TeamsNotificationsInPage({ endpointUrl, forceDisplay }: TeamsNotificationsInPageProps) {
  const [teamsNotificationsData, setTeamsNotificationsData] = useState<TeamsNotificationsProps>({
    title: '',
    hintText: '',
    hintUrl: '',
    channels: [],
  })

  const isUserLoggedIn = getCookieValue('pg.user.loggedIn') === 'true'
  const isForceDisplayed = forceDisplay === 'true'

  useEffect(() => {
    getRequest(endpointUrl)
      .then(response => response.json())
      .then(data => {
        setTeamsNotificationsData({
          title: data.title,
          hintText: data.hintText,
          hintUrl: data.hintUrl,
          channels: data.channels,
        })
      })
      .catch(error => console.error(error))
  }, [endpointUrl])

  const { title, hintText, hintUrl, channels } = teamsNotificationsData
  const hideTeamsNotifications = channels.length <= 0 || (!isUserLoggedIn && !isForceDisplayed)

  return (
    !hideTeamsNotifications && (
      <div className="teams-notifications-in-page-component">
        <TeamsNotifications channels={channels} title={title} hintText={hintText} hintUrl={hintUrl} type="page" />
      </div>
    )
  )
}
