import './app.css'

import { getRefs } from '../../site/js/utils/utils'
import { Filters } from './filters/filters'
import { List } from './list/list.js'
import { Loading } from './loading/loading.js'
import { BingMap } from './map/index'
import { Paginate } from './pagination/pagination.js'

/**
 * app layout template
 * @param {object} state
 */
const layout = (state, mapHidden) => `
  <div class="c-mapapp__map ${mapHidden === 'true' ? 'c-mapapp__map-hide' : ''}"></div>
  <div class="c-mapapp__container">
    <div class="c-mapapp__loading-overlay"></div>
    <div class="c-mapapp__filters"></div>
    <div class="c-mapapp__list"></div>
    <div class="c-mapapp__pagination"></div>
  </div>
`

/**
 * Creates a map list app
 * @param {object} configuration
 */
export const App = ({
  el = null,
  selectors = {
    map: '.c-mapapp__map',
    filters: '.c-mapapp__filters',
    list: '.c-mapapp__list',
    pagination: '.c-mapapp__pagination',
    loading: '.c-mapapp__loading-overlay',
  },
  state,
  config,
}) => {
  // render layout
  el.insertAdjacentHTML('afterbegin', layout(state, config.hidemap))

  // get refs
  const refs = getRefs(selectors)(el)

  // components
  refs.map.map(el => BingMap({ el, state }))

  refs.filters.map(el => Filters({ el, state, config }))

  refs.list.map(el => List({ el, state, config }))

  refs.pagination.map(el => Paginate({ el, state }))

  refs.loading.map(el => Loading({ el, state }))
}
