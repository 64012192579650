import './loading.css'

/**
 * Loading state
 * @param {htmlElement} el
 * @param {object} state
 * @param {object} classNames
 */
export const Loading = ({
  el,
  state,
  classNames = {
    active: 'is-active',
  },
}) => {
  const toggle = () => (state.loading ? el.classList.add(classNames.active) : el.classList.remove(classNames.active))

  state.watch('loading', toggle)
}
