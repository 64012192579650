import { isMobile } from '@site/js/utils/breakpoint'
import { isSafari } from '@site/js/utils/utils'

import { TsComponentRegistry } from '../ts-component-registry'

class PeekabooVideoTeaserComponent extends TsComponentRegistry.Component<HTMLDivElement> {
  peekabooTeasers: NodeListOf<HTMLElement>
  constructor(element: HTMLDivElement) {
    super(element)
    this.peekabooTeasers = this.element.querySelectorAll('.cmp-peekaboo-video-teaser')
    this.init()
  }

  private init(): void {
    this.videoHandler()
  }

  private videoHandler(): void {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const videoWrapper: HTMLDivElement = entry.target.querySelector('.cmp-peekaboo-video-teaser__video-wrapper')
          const video: HTMLVideoElement = videoWrapper?.querySelector('video')
          const content: HTMLDivElement = entry.target.querySelector('.cmp-peekaboo-video-teaser__content')
          const listenersArray = [videoWrapper, content]
          if (video) {
            const { mobileVideoDisabled } = videoWrapper?.dataset || {}
            this.initEventListeners(listenersArray, video)
            this.setVideoAttributes({ videoWrapper, video })
            if (entry.isIntersecting) {
              if (isMobile && mobileVideoDisabled !== 'true') {
                video.play()
              }
            } else {
              video.pause()
            }
          }
        })
      },
      {
        threshold: [0.2],
      },
    )
    this.peekabooTeasers.forEach(teaser => observer.observe(teaser))
  }

  private initEventListeners(listenersArray: HTMLElement[], video: HTMLVideoElement) {
    listenersArray.forEach(element => {
      element.addEventListener('mouseover', () => {
        if (!isMobile && video.paused) {
          video.play()
        }
      })
    })
    listenersArray.forEach(element => {
      element.addEventListener('mouseleave', () => {
        if (!isMobile) {
          video.pause()
        }
      })
    })
  }

  private setVideoAttributes(videoObject = { videoWrapper: null, video: null }): void {
    const { videoWrapper, video } = videoObject
    const { videourl: src, posterimageurl: poster } = videoWrapper.dataset
    if (!video.src) {
      video.src = src
    }
    if (!video.poster) {
      video.poster = poster
    }
    if (isSafari) {
      video.style.backgroundImage = `url('${poster}')`
    }
    if (video.autoplay != null) {
      video.autoplay = null
    }
  }
}

const selector = '.cmp-peekaboo-video-teasers'
TsComponentRegistry.registerComponent(selector, PeekabooVideoTeaserComponent)
