// distinguish potentiel other params (e.g. 'wcmmode') from filter params
const filterParamPrefix = 'filter-'

/**
 * converts url params to an object
 * - gets the url params from browser url
 */
const urlToObject = () => {
  const obj = Object.fromEntries(new URLSearchParams(location.search))
  Object.keys(obj).forEach(key => {
    obj[key] = obj[key].split(',')
  })
  return obj
}

/**
 * converts an object to url string
 * @param {object} o
 */
const objToUrlString = o => new URLSearchParams(o).toString()

/**
 * sets the url params in the browser url
 * @param {string} s - url params as string
 */
const updateUrl = s => window.history.replaceState({}, '', `${location.pathname}?${s}${location.hash}`)

/**
 * filter/get other url params - not filter params
 * @param {Array} [key, value] entry
 */
const isNotFilterParam = ([key, _value]) => !key.includes(filterParamPrefix)

/**
 * prefix object keys
 * @param {Object} obj
 */
const prefixSelectedFilters = (obj, prefix) =>
  Object.assign(
    {},
    ...Object.keys(obj).map(key => {
      const item = {}
      item[prefix + key] = obj[key]
      return item
    }),
  )

/**
 * gets current url params that are not filter params
 */
const getCurParamsNotFilters = () => Object.fromEntries(Object.entries(urlToObject()).filter(isNotFilterParam))

/**
 * updates the url from object
 * @param {object} obj
 */
const update = selectedFilters =>
  updateUrl(
    objToUrlString(Object.assign({ 'filter-page': '1' }, getCurParamsNotFilters(), prefixSelectedFilters(selectedFilters, filterParamPrefix))),
  )

/**
 * filter - returns entries that has the filter prefix
 * @param {array} [key, value] entry
 */
const isFilterParam = ([key, _value]) => key.includes(filterParamPrefix)

/**
 * removes the filter prefix from key
 * @param {array} entry - [key, value]
 */
const stripPrefix = ([key, value]) => [key.replace(filterParamPrefix, ''), value]

/**
 * sets the current selected filters
 * @param {object} state
 */
const setCurrentSelectedFilters = state => {
  state.selectedFilters = Object.fromEntries(Object.entries(urlToObject()).filter(isFilterParam).map(stripPrefix))
}

/**
 * handles url changes
 * @param {*} state
 */
export const UrlHandler = state => {
  setCurrentSelectedFilters(state)

  // update url with selected filters when they change
  state.watch('selectedFilters', () => update(state.selectedFilters))
  state.watch('filtersData', () => update(state.selectedFilters))
}
