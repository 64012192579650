import { arrayOf } from '../../../site/js/utils/utils'

/**
 * filters the pushpins based on selected filters
 * @param {object} state
 * @param {array} pins
 */
export const filterPushpins = ({ state, pins }) =>
  pins.filter(pin => {
    let bool = true

    Object.keys(state.selectedFilters).forEach(key => {
      // skip these filters
      if (key === 'country' || key === 'city' || key === 'page' || key === 'search') {
        return
      }

      state.selectedFilters[key].forEach(option => {
        // bails if the filter doesn't exist in the response data for the map items
        if (!pin.data.filters[key]) {
          return
        }
        let match = false
        // checks if pushpin has the selected filter value
        pin.data.filters[key].forEach(value => {
          if (option === value) {
            match = true
          }
        })
        bool = match
      })
    })
    return bool
  })

/**
 * zooms to location of pins
 * @param {object} map
 * @param {array} pushpins
 * @param {number} maxZoom
 */
export const zoomTo = ({ map, pushpins, maxZoom = 15 }) =>
  // get locations
  arrayOf(pushpins.map(pushpin => pushpin.getLocation()))
    // Create a bounding box for the pushpins.
    .map(locations =>
      arrayOf(window.Microsoft.Maps.LocationRect.fromLocations(locations))
        // Zoom into the bounding box
        .map(bounds => {
          map.setView({ bounds })
          // ensure max zoom
          if (map.getZoom() > maxZoom) {
            map.setView({ zoom: maxZoom })
          }
        }),
    )

/**
 * zooms map to location of pushpins in the area of selected location filters
 * @param {array} pushpins
 */
export const zoomToLocation = ({ state, map, pins, center, zoomLevel }) => {
  const country = state.selectedFilters.country
  const city = state.selectedFilters.city
  let zoomToPins = []
  let maxZoom = 7

  if (country) {
    zoomToPins = pins.filter(pin => pin.data.filters.country === country[0])
  }

  if (city) {
    maxZoom = 10
    zoomToPins = pins.filter(pin => pin.data.filters.city === city[0])
  }

  if (zoomToPins.length) {
    zoomTo({ map, pushpins: zoomToPins, maxZoom })
    return
  }

  map.setView({ center, zoom: zoomLevel })
}

/**
 * Sets filters to the location filters - (country / city)
 * - based on the clicked map location
 * @param {object} state
 * @param {array} pushpins
 */
export const setLocationFilters = ({ state, pushpins }) => {
  const obj = {
    country: [],
    city: [],
  }

  const select = { ...state.selectedFilters }

  // get locations
  pushpins.forEach(element => {
    obj.country.push(element.data.filters.country)
    obj.city.push(element.data.filters.city)
  })

  // only distinct values
  obj.country = [...new Set(obj.country)]
  obj.city = [...new Set(obj.city)]

  // set country filter
  if (obj.country.length === 1) {
    select.country = obj.country
  } else {
    delete select.country
  }

  // set city filter
  if (obj.city.length === 1) {
    select.city = obj.city
  } else {
    delete select.city
  }

  const nothingChanged = select.country === state.selectedFilters.country && select.city === state.selectedFilters.city

  // bails if nothing has changed
  if (nothingChanged) {
    return
  }

  // reset pagination
  delete select.page

  // update filters
  state.selectedFilters = select
}
