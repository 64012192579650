import customSelect from 'custom-select'

import { pxToRem } from '../../site/js/utils/px-to-rem'

/**
 * Form Options
 * custom outgroup select
 */
const CustomSelect = dynamicSelects => {
  /**
   * Waits for window to load
   * This is needed to fix an issue in chrome.
   * E.g. When using the browsers back button
   * the select box doesn't reflect what was previously selected in history
   * before after the window load event
   */
  /**
   * Initializing custom select
   */
  const rootContainer = dynamicSelects || document
  const customSelectWrappers = rootContainer.querySelectorAll('select.cmp-form-options__field--drop-down')
  const customSelectOptgroupClass = '.c-custom-select-optgroup'

  customSelect(customSelectWrappers, {
    containerClass: 'c-custom-select-container',
    openerClass: 'c-custom-select-opener',
    panelClass: 'c-custom-select-panel',
    optionClass: 'c-custom-select-option',
    optgroupClass: 'c-custom-select-optgroup',
    isSelectedClass: 'is-selected',
    hasFocusClass: 'has-focus',
    isDisabledClass: 'is-disabled',
    isOpenClass: 'is-open',
  })

  const parents = {}

  /**
   * For every group it initializes custom classes for the groups
   * and add arrow element for parent group
   * @param {element} item group element in
   */
  const initGroups = (item, container, index) => {
    addGroupElements(item, `${container}-${index}`)

    const arrows = Array.from(item.getElementsByClassName('c-custom-select-arrow'))
    const selectElement = container.querySelector('select')

    arrows?.forEach(arrow => {
      const groupCategory = arrow.closest(customSelectOptgroupClass)
      groupCategory.addEventListener('click', e => {
        e.stopPropagation()
        arrow.classList.toggle('c-custom-select-arrow--down')
        openGroup(groupCategory, selectElement)
      })
    })
  }

  /**
   * Add group class to the group. If the group is the first one with class then is a parent
   * and contains an arrow. If not, then is a subgroup and is hidden
   * @param {element} item group element
   */
  const addGroupElements = (item, container) => {
    // add hidden class to subgroups
    if (item.classList.length > 2 || parents[container]?.indexOf(item.className) !== -1) {
      item.className += ' is-hidden'
    }

    // checking if group contains subgroups, then add an arrow
    // if not, add extra padding
    const siblingClass = item.nextSibling !== null ? item.nextSibling.className : 'no-sibling'
    const classCondition = siblingClass?.indexOf(item.className.replace(' is-hidden', '')) !== -1

    if (item.childNodes.length === 1 && classCondition) {
      item.childNodes[0].innerHTML = '<span class="c-custom-select-arrow icon-keyboard_arrow_right"></span>' + item.childNodes[0].innerHTML
      parents[container]?.push(item.className.replace(' is-hidden'))
    } else {
      item.childNodes.forEach(item => {
        if (item.classList) {
          item.classList.add('last-item-group')
        }
      })
    }
  }

  /**
   * When an arrow element is clicked, it keeps the select options list opened
   * @param {event} e event of the arrow that was clicked
   */
  const selectGroup = group => {
    group.closest('.c-custom-select-container').classList.add('is-open')
  }

  /**
   * When an arrow element is clicked, select list options opened
   * and toggle hidden class from the subgroup
   * @param {event} e event of the arrow that was clicked
   */
  const openGroup = (group, selectElement) => {
    const groupClass = '.' + group.className.replace(' is-hidden', '').replace(/ /g, '.')
    const groupClassIndex = group.classList.length

    const subgroups = group.parentNode.querySelectorAll(groupClass)
    const groupsClass = []

    const sortedSubGroups = Array.from(subgroups).filter(item => item !== group)

    if (nestedLevelsSortedAlphabetically(selectElement)) {
      sortElements(sortedSubGroups, groupClassIndex)
    } else {
      sortedSubGroups.reverse()
    }

    sortedSubGroups.forEach(el => subgroups[0].parentNode.insertBefore(el, group.nextSibling))

    // subgroup candidates to show/hide
    const subgroupsArray = [].filter.call(subgroups, function (item) {
      selectGroup(group)
      const itemClass = item.className.replace(' is-hidden', '').split(' ')
      const correctIndex = itemClass.length === groupClassIndex || itemClass.length === groupClassIndex + 1
      const sameOrClass = item !== group

      if (sameOrClass && groupsClass.indexOf(item.className) === -1 && correctIndex) {
        groupsClass.push(item.className)
        return item
      }
    })
    // from candidates, if it was hidden then show and viceversa
    // if the candidate will be hidden, then hide also children
    for (let i = 0; i < subgroupsArray.length; i++) {
      const subgroup = subgroupsArray[i]

      if (i > 0 && subgroupsArray[i - 1].className === subgroup.className) {
        continue
      }
      if (subgroup.className.indexOf('is-hidden') === -1) {
        hideChildren(subgroup)
        subgroup.classList.add('is-hidden')
      } else {
        subgroup.classList.remove('is-hidden')
      }
    }
  }

  const sortElements = (sortedSubGroups, groupClassIndex) =>
    sortedSubGroups.sort((a, b) => {
      // numOfChildElementExtraClasses - every child has 2 classes more than it's parent, including 'is-hidden' class
      const numOfChildElementExtraClasses = 2
      if (a.classList.length === groupClassIndex + numOfChildElementExtraClasses) {
        return b.innerText.toLowerCase().localeCompare(a.innerText.toLowerCase())
      }
    })

  /**
   * When a group is hidden, then hide also children. New candidates have the same class
   * @param {element} group group class for parent and children
   */
  const hideChildren = group => {
    const selector = '.' + group.className.replace(/ /g, '.') + ':not(.is-hidden)'
    rootContainer.querySelectorAll(selector).forEach((item, _index) => {
      const arrows = item.getElementsByClassName('c-custom-select-arrow')
      if (arrows) {
        Array.from(arrows).forEach(arrow => {
          arrow.classList.remove('c-custom-select-arrow--down')
        })
      }
      if (item.classList.length > group.classList.length) {
        item.classList.add('is-hidden')
      }
    })
  }

  /**
   * When keydown on optgroup select, open subgroups
   * @param {element} item container item where keydown is triggered
   */
  const handleKeyboardEvent = item => {
    item.addEventListener('keydown', e => {
      if (e.key !== 'Tab') {
        e.preventDefault()
      }
      const focusOption = item.querySelectorAll('.has-focus')[0]
      const optionGroupClass = focusOption.parentNode.classList
      if (optionGroupClass.contains('is-hidden')) {
        optionGroupClass.remove('is-hidden')
      }
    })
  }

  const nestedLevelsSortedAlphabetically = select => select.dataset['sorted'] === 'true'

  /**
   * initialize
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const init = function () {
    let index = 0
    const containers = rootContainer?.querySelectorAll('.c-custom-select-container')

    containers?.forEach(customSelectContainer => {
      // initialize the parents object with all the containers
      if (!parents[`${customSelectContainer}-${index}`]) {
        parents[`${customSelectContainer}-${index}`] = []
      }

      const optgroup = customSelectContainer.querySelectorAll('.c-custom-select-optgroup')

      optgroup?.forEach(item => {
        const group = item.dataset.label
        item.className += ' ' + group
      })

      let previousItem

      optgroup?.forEach(item => {
        let lastChildGroup = 0
        const previousSibilingCondition = previousItem !== undefined && previousItem.className?.indexOf(item.className) !== -1
        const nextSiblingCondition = item.nextSibling !== null && item.nextSibling.className?.indexOf(item.className) === -1

        if (previousSibilingCondition && nextSiblingCondition) {
          lastChildGroup = 10
        }

        item.childNodes?.forEach(child => {
          if (child.style) {
            child.style.paddingLeft = pxToRem(item.classList.length * 10 + lastChildGroup) || 0
          }
        })

        initGroups(item, customSelectContainer, index)

        previousItem = item
      })

      index++

      handleKeyboardEvent(customSelectContainer)
    })
  }

  return {
    initGroups,
    addGroupElements,
    selectGroup,
    openGroup,
    handleKeyboardEvent,
    init,
  }
}

export { CustomSelect }
