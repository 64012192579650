import { trackEvent } from '../../site/js/utils/tracking'

export const pushInteractionToolbarDataAnalytics = ({ componentName, title = 'interaction-toolbar' }) => {
  const eventData = {
    event: 'interaction',
    eventSource: 'aem',
    action: 'click',
    componentName,
    interactionType: 'button',
    title,
    href: '',
  }
  trackEvent(eventData)
}
