import { RefObject, useEffect, useState } from 'react'

interface FormattedLabelProps {
  labels: string
  days: number
  hours: number
  minutes: number
  seconds: number
}

type FormattedLabelValues = {
  dayLabel: string
  hourLabel: string
  minuteLabel: string
  secondLabel: string
}

type CountdownValues = { days: number; hours: number; minutes: number; seconds: number }

const useCountdown = (targetDate: string, wrapper: RefObject<HTMLBaseElement>): CountdownValues => {
  const countDownDate = new Date(targetDate).getTime()

  const [difference, setDifference] = useState(0)
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime()
      const tempDifference = countDownDate ? countDownDate - now : 0
      const parent = wrapper?.current?.parentElement?.parentElement
      const classNameVisible = 'countdown-wrapper--is-visible'

      setDifference(tempDifference)
      if (tempDifference > 0) {
        parent?.parentElement.classList.add(classNameVisible)
      }

      if (tempDifference < 1) {
        clearInterval(timer)
        parent?.parentElement.classList.remove(classNameVisible)
        parent?.remove()
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [countDownDate])

  const getReturnValues = (difference: number): CountdownValues => {
    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((difference % (1000 * 60)) / 1000)

    return { days, hours, minutes, seconds }
  }

  return getReturnValues(difference)
}

const useFormattedLabel = ({ labels, days, hours, minutes, seconds }: FormattedLabelProps): FormattedLabelValues => {
  const parsedLabels = JSON.parse(labels)
  return {
    dayLabel: days === 1 ? parsedLabels.day : parsedLabels.days,
    hourLabel: hours === 1 ? parsedLabels.hour : parsedLabels.hours,
    minuteLabel: minutes === 1 ? parsedLabels.minute : parsedLabels.minutes,
    secondLabel: seconds === 1 ? parsedLabels.second : parsedLabels.seconds,
  }
}

const useFormattedNumber = (number: number): string => (number < 10 ? `0${number}` : `${number}`)

export { useCountdown, useFormattedLabel, useFormattedNumber }
