import { parseJson, selectAll } from '../../site/js/utils/utils'
import { App } from './app'
import { DmAttributes } from './dmattributes/dm-attributes'
import { ListService } from './services/list.js'
import { MapService } from './services/map.js'
import { State } from './state'
import { UrlHandler } from './urlHandler.js'

/**
 * creates a map app
 * @param {htmlElement} el
 */
const MapApp = el => {
  const config = {
    ...el.dataset,
    translations: el.dataset.translations ? parseJson(el.dataset.translations) : {},
    ...DmAttributes(el),
    hashcode: el.dataset.hashcode || Math.floor(Math.random() * 1000000 + 1),
  }

  const state = State({})

  if (!config.urlHandler) {
    UrlHandler(state)
  }

  ListService({ url: config.listUrl, state })
  MapService({ url: config.mapUrl, state })

  App({ el, state, config })
}

/**
 * start
 */
selectAll('map-app').map(MapApp)
