import { printContextualErrorMessage } from './form-validation'
const allowedFileTypes = ['png', 'pdf', 'jpg', 'jpeg', 'docx', 'doc', 'xlsx', 'xls', 'wav', 'zip', 'eml', 'rar']
let fileInputErrorMessage = ''
const isInvalidClass = 'is-invalid'
const isCorrectClass = 'is-correct'

const convertToBase64 = fileInput => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(fileInput)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = error => {
      reject(error)
    }
  })
}

const getFileExtension = (fileName: string) => {
  return fileName?.split('.').pop()
}

export const getInputFormFiles = async (formInput: HTMLInputElement) => {
  let customFileInputObject = {}
  const file = formInput.files?.[0]
  if (file) {
    try {
      const convertedFile = await convertToBase64(file)
      const key = `${formInput.name}`
      customFileInputObject = {
        key,
        fileName: file.name as string,
        content: (convertedFile as string).split(',')[1].trim(),
      }
    } catch (e) {
      console.error(e)
    }
  }
  return customFileInputObject
}

const isValidFiles = files => files.every(file => allowedFileTypes.includes(getFileExtension((file as File).name)))

const printFileInputValidityStatus = (fileInputParent: HTMLElement, isValid: boolean): void => {
  if (isValid) {
    fileInputParent.classList.remove(isInvalidClass)
    fileInputParent.classList.remove('is-empty')
    fileInputParent.classList.add(isCorrectClass)
    fileInputParent.querySelector('.cmp-form-file__button').textContent = fileInputParent.getAttribute('data-replace')
  } else {
    fileInputParent.classList.remove(isCorrectClass)
    fileInputParent.querySelector('.cmp-form-file__button').textContent = fileInputParent.getAttribute('data-select-file')
    fileInputParent.querySelector('.cmp-form-file__text').textContent =
      fileInputParent.getAttribute('data-use-legend-as-placeholder') === 'true'
        ? fileInputParent.getAttribute('data-legend')
        : fileInputParent.getAttribute('data-no-file-selected')
  }
}

export const uploadFiles = event => {
  checkInputFileValidity(event.target)
}

export const checkInputFileValidity = (fileInput: HTMLInputElement) => {
  const files = Array.from(fileInput.files)
  let isValid = false
  const fileInputParent = fileInput.parentNode.parentNode as HTMLElement
  const maxFileSize: number = Number((fileInputParent as HTMLElement).dataset.filesize) || 5
  fileInputErrorMessage = ''
  if (files?.length) {
    if (isValidFiles(files)) {
      isValid = files.every(file => (file as File).size < maxFileSize * 1000000)
      if (!isValid) {
        fileInputErrorMessage = fileInputParent.getAttribute('data-cmp-filesize-message')
        fileInputParent.classList.add(isInvalidClass)
      }
    } else {
      fileInputErrorMessage = fileInputParent.getAttribute('data-cmp-constraint-message')
      fileInputParent.classList.add(isInvalidClass)
    }
  } else {
    if (fileInputParent.getAttribute('data-required') !== 'true') {
      isValid = true
    } else {
      fileInputErrorMessage = fileInputParent.getAttribute('data-cmp-required-message')
      fileInputParent.classList.add('is-empty')
    }
  }

  fileInputParent.querySelector('.cmp-form__error-message')?.remove()
  ;(fileInput.parentNode as HTMLElement).classList.remove(isCorrectClass)

  if (isValid) {
    fileInputParent.querySelector('.cmp-form-file__text').textContent = files.map(file => file.name).join(', ')
  } else {
    fileInput.value = ''
  }

  fileInput.setAttribute('aria-invalid', `${!isValid}`)
  printFileInputValidityStatus(fileInputParent, isValid)
  printContextualErrorMessage(fileInputParent, fileInputErrorMessage)
  fileInput.setCustomValidity(fileInputErrorMessage)
}
