import { trackEvent } from '@site/js/utils/tracking'

const ACTIVE_IMAGE_CAROUSEL_ID = 'data-active-image-carousel-id'

export class ImageCarousel {
  protected element: HTMLDivElement
  protected currentImageId: number
  protected carouselImages: NodeList
  protected thumbnailImages: NodeList
  protected carouselButtons: NodeList
  protected currentImageIdSpan: HTMLSpanElement

  constructor(element: HTMLDivElement, isSingleImage = false) {
    this.element = element
    if (isSingleImage) {
      this.registerSingleImageEvents()
    } else {
      this.init()
    }
  }

  private init() {
    this.initElements()
    this.setActiveImage(0, true)
    this.registerEvents()
  }

  private initElements() {
    this.carouselImages = this.element.querySelectorAll('.cmp-image-carousel__image')
    this.thumbnailImages = this.element.querySelectorAll('.cmp-image-carousel__thumbnail')
    this.carouselButtons = this.element.querySelectorAll('.cmp-image-carousel__arrow')
    this.currentImageIdSpan = this.element.querySelector('.cmp-image-carousel__active-image-id')
  }

  private registerSingleImageEvents() {
    this.registerFullScreenButtonsEvents()
    this.registerExitFullScreenOnEsc()
  }

  private setActiveImage(index: number, onInit = false) {
    this.currentImageId = index
    this.updateCarouselImages()
    this.updateThumbnailImages()
    this.currentImageIdSpan.textContent = `${index + 1}`

    if (!onInit) {
      this.setThumbnailPositionInView()
    }

    this.updateCarouselButtons()
  }

  private registerEvents() {
    Array.from(this.thumbnailImages).forEach((image: HTMLElement) =>
      image.addEventListener('click', () => this.setActiveImage(+image.dataset.thumbnailId)),
    )
    this.registerCarouselButtonsEvents()
    this.registerFullScreenButtonsEvents()
    this.registerExitFullScreenOnEsc()
  }

  private nextImage(button: HTMLElement) {
    if (this.currentImageId < this.carouselImages.length - 1) {
      this.setActiveImage(this.currentImageId + 1)
      this.handleTracking({ interactionType: 'next-image', title: button.ariaLabel })
    }
  }

  private previousImage(button: HTMLElement) {
    if (this.currentImageId > 0) {
      this.setActiveImage(this.currentImageId - 1)
      this.handleTracking({ interactionType: 'previous-image', title: button.ariaLabel })
    }
  }

  private updateCarouselImages() {
    Array.from(this.carouselImages).forEach((image: HTMLElement) => {
      image.classList.toggle('cmp-image-carousel__image--active', this.currentImageId === +image.dataset.imageId)
      if (image.classList.contains('cmp-video-wrapper')) {
        const video = image.querySelector('video')
        if (this.currentImageId !== +image.dataset.imageId && !video.paused) {
          video.pause()
        }
      }
    })
  }

  private updateThumbnailImages() {
    Array.from(this.thumbnailImages).forEach((image: HTMLElement) =>
      image.classList.toggle('cmp-image-carousel__thumbnail--active', this.currentImageId === +image.dataset.thumbnailId),
    )
  }
  private updateCarouselButtons() {
    this.carouselButtons.forEach((button: HTMLElement) => {
      if (button.classList.contains('cmp-image-carousel__arrow--next')) {
        button.classList.toggle('cmp-image-carousel__arrow--disabled', this.currentImageId === this.carouselImages.length - 1)
      } else {
        button.classList.toggle('cmp-image-carousel__arrow--disabled', this.currentImageId === 0)
      }
    })
  }

  private setThumbnailPositionInView() {
    const thumbnails = this.element.querySelector('.cmp-image-carousel__thumbnails')
    const activeThumbnail = this.element.querySelector('.cmp-image-carousel__thumbnail--active')
    const { width: activeThumbnailWidth } = activeThumbnail.getBoundingClientRect()
    thumbnails.scrollTo({
      left: (this.currentImageId - 1) * activeThumbnailWidth - activeThumbnailWidth / 2,
      behavior: 'smooth',
    })
  }

  private toggleFullScreen(fullScreen = true) {
    if (!document.fullscreenElement) {
      this.element.requestFullscreen()
      document.body.setAttribute(ACTIVE_IMAGE_CAROUSEL_ID, this.element.id)
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
    }
    this.element.classList.toggle('cmp-image-carousel__fullscreen', fullScreen)
  }

  private registerCarouselButtonsEvents() {
    Array.from(this.carouselButtons).forEach((button: HTMLElement) =>
      button.addEventListener('click', () => {
        if (button.classList.contains('cmp-image-carousel__arrow--next')) {
          this.nextImage(button)
        } else {
          this.previousImage(button)
        }
      }),
    )

    window.addEventListener('keydown', e => this.handleKeyBoardCarouselButtons(e))
  }

  private registerFullScreenButtonsEvents() {
    this.element.querySelector('.cmp-image-carousel__button--fullscreen').addEventListener('click', (event: Event) => {
      this.toggleFullScreen()
      const element = event.target as HTMLElement
      this.handleTracking({ title: element.ariaLabel, interactionType: 'enter-fullscreen' })
    })

    this.element.querySelector('.cmp-image-carousel__button--exit-fullscreen').addEventListener('click', () => {
      this.toggleFullScreen(false)
    })
  }

  private registerExitFullScreenOnEsc() {
    const events = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange', 'MSFullscreenChange']
    events.forEach(event =>
      document.addEventListener(event, () => {
        if (!document.fullscreenElement && document.body.getAttribute(ACTIVE_IMAGE_CAROUSEL_ID) === this.element.id) {
          this.element.classList.remove('cmp-image-carousel__fullscreen')
          this.handleTracking({
            title: this.element.querySelector('.cmp-image-carousel__button--exit-fullscreen').getAttribute('aria-label'),
            interactionType: 'exit-fullscreen',
          })
          document.body.removeAttribute(ACTIVE_IMAGE_CAROUSEL_ID)
        }
      }),
    )
  }

  private handleKeyBoardCarouselButtons(event: KeyboardEvent) {
    if (document.fullscreenElement && this.element.id === document.body.getAttribute(ACTIVE_IMAGE_CAROUSEL_ID)) {
      if (event.key === 'ArrowRight') {
        this.nextImage(this.element.querySelector('.cmp-image-carousel__arrow--next'))
      }
      if (event.key === 'ArrowLeft') {
        this.previousImage(this.element.querySelector('.cmp-image-carousel__arrow--previous'))
      }
    }
  }

  private handleTracking({ title = '', interactionType }: { title?: string; interactionType: string }) {
    trackEvent({
      event: 'interaction',
      eventSource: 'aem',
      action: 'click',
      componentName: 'imageCarousel',
      interactionType,
      title,
    })
  }
}
