import { cls } from '@react/utils/classname'
import { isMobile } from '@site/js/utils/breakpoint'

/**
 * Locations - list template
 * @param {object} state
 */

export const template = (state, config) => `
<div class="c-mapapp__list--locations">

  <div class="c-mapapp__list-global-headquarters">
    <a href="${config.globalhqpage}"  class="cmp-button u-display--inline-flex">
      <span class="cmp-button__text">See Hitachi Energy Global Headquarters</span>  
      <span class="cmp-button__link-icon"></span>
    </a>
  </div>

  <table class="c-mapapp__list-table">
      <thead class="${cls({
        'u-visually-hidden': isMobile,
      })}">
        <tr>
          <th scope="col">Location</th>
          <th scope="col">Address</th>
          <th scope="col">Phone</th>
          <th scope="col">Fax</th>
        </tr>
      </thead>
      <tbody>
      ${state.listData
        .map(
          item => `
        <tr>
          <td>
            <button class="u-color--primary u-display--inline-block u-vertical-align--middle js-mapapp-show-on-map" data-dm="pg-locations.link" data-id="${
              item.id
            }">${item.name}</button>
            ${item.headQuarter ? '<span class="c-mapapp__list--locations-hq">HQ</span>' : ''}
          </td>
          <td>
          ${
            item.address
              ? `
            ${item.address.street}<br>
            ${item.address.zipCode} ${item.address.city}<br>
            ${item.address.state}<br>
          `
              : ''
          }

            <button data-dm="pg-locations.link" data-id="${item.id}" class="cmp-button c-mapapp__list-show-on-map js-mapapp-show-on-map">
              <span class="cmp-button__icon icon-place"></span>
              <span class="cmp-button__text" aria-label="Show ${item.name} on map">Show on map</span>
            </button>

          </td>
          <td>
            <a href="tel:${item.phone.replace(/\s+/g, '')}">${item.phone}</a>
          </td>
          <td>
            ${item.fax}
          </td>
        </tr>
      `,
        )
        .join('')}
      </tbody>
    </table>
</div >
`
