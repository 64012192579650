import { CommentsTranslationType, CommentType, SortCommentsType, useCommentsType } from '@react/Comments/CommentsTypes'
import { getRequest, postRequest } from '@site/js/utils/request'
import { useCallback, useState } from 'react'

interface useCommentsProps {
  translation: string
  commentsUrl: string
  createCommentUrl: string
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useComments = ({ translation, createCommentUrl, commentsUrl }: useCommentsProps): useCommentsType => {
  const [offset, setOffset] = useState(0)
  const [numOfComments, setNumOfComments] = useState(0)
  const [numOfPages, setNumOfPages] = useState(0)
  const [comments, setComments] = useState<[] | CommentType[]>([])
  const [commentFieldValue, setCommentFieldValue] = useState('')

  let translationsData: CommentsTranslationType | null = null
  try {
    translationsData = JSON.parse(translation)
  } catch (error) {
    console.warn('Error parsing translation JSON', error)
  }

  const isLoadMoreVisible = numOfPages > 1 && numOfPages > offset + 1

  const translations: CommentsTranslationType = {
    loadMore: translationsData?.loadMore || 'load more',
    comment: translationsData?.comment || 'comment',
    comments: translationsData?.comments || 'comments',
    thankYou: translationsData?.thankYou || 'thank you',
    commentCannotBeAdded: translationsData?.commentCannotBeAdded || 'comment Cannot Be Added',
    sortbyoldest: translationsData?.sortbyoldest || 'sortbyoldest',
    sortbynewest: translationsData?.sortbynewest || 'sortbynewest',
    sortbymostliked: translationsData?.sortbymostliked || 'sortbymostliked',
    addComment: translationsData?.addComment || 'addComment',
    editComment: translationsData?.editComment || 'editComment',
    editCommentConfirm: translationsData?.editCommentConfirm || 'editCommentConfirm',
    deleteComment: translationsData?.deleteComment || 'deleteComment',
    deleteCommentConfirm: translationsData?.deleteCommentConfirm || 'deleteCommentConfirm',
    post: translationsData?.post || 'post',
    reply: translationsData?.reply || 'reply',
    replies: translationsData?.replies || 'replies',
    addReply: translationsData?.addReply || 'addReply',
    like: translationsData?.like || 'like',
    likes: translationsData?.likes || 'likes',
    cancel: translationsData?.cancel || 'cancel',
    temporal: {
      minute: translationsData?.temporal?.minute || 'minute',
      minutes: translationsData?.temporal?.minutes || 'minutes',
      hour: translationsData?.temporal?.hour || 'hour',
      justNow: translationsData?.temporal?.justNow || 'justNow',
      hours: translationsData?.temporal?.hours || 'hours',
      day: translationsData?.temporal?.day || 'day',
      days: translationsData?.temporal?.days || 'days',
      timeAgo: translationsData?.temporal?.timeAgo || 'timeAgo',
    },
  }

  const loadComments = useCallback(async (params = {}) => {
    try {
      const response = await getRequest(commentsUrl + '?' + new URLSearchParams(params).toString())
      const { content, totalCommentsAndRepliesNum, totalElements } = await response.json()
      if ('offset' in params) {
        setComments(prev => [...prev, ...content])
      } else {
        setComments(content || [])
        setOffset(0)
      }
      setNumOfComments(totalCommentsAndRepliesNum)
      setNumOfPages(Math.ceil(totalElements / 5))
    } catch (error) {
      console.error(error)
    }
  }, [])

  const addComment = async () => {
    if (commentFieldValue !== '') {
      const body = new FormData()
      body.append('commentContent', commentFieldValue)
      try {
        await postRequest(createCommentUrl, body)
        setCommentFieldValue('')
        await loadComments()
      } catch (error) {
        console.error(error)
      }
    }
  }

  const loadMore = (sortType: SortCommentsType) => {
    const newOffset = offset + 1
    setOffset(newOffset)
    loadComments({ offset: newOffset, ...sortType })
  }

  const handleChangeCommentText = useCallback(value => {
    setCommentFieldValue(value)
  }, [])

  const sortComments = (sortType: SortCommentsType) => {
    loadComments(sortType)
  }

  return {
    translations,
    loadComments,
    comments,
    numOfComments,
    numOfPages,
    loadMore,
    addComment,
    handleChangeCommentText,
    commentFieldValue,
    isLoadMoreVisible,
    sortComments,
  }
}
