import { getAnalyticsDataForDataLayer } from '@site/js/utils/cookies'
import { isInIframe, trackIframeEvent } from '@site/js/utils/iframe'
import { trackEvent } from '@site/js/utils/tracking'

import pageTracker from '../page-tracker/page-tracker'
import { getInputFormFiles } from './file-upload'

const excludedInputTypes = ['text', 'textarea', 'email', 'tel', 'hidden']

export function trackFormSuccess(form: HTMLFormElement) {
  const eventData = {
    event: 'formSuccess', // formSuccess, formError, formEngagement
    formStatus: 'form success', // form success, form error, form engagement
  }
  trackFormGeneric(form, eventData)
}

export function trackFormErrors(form: HTMLFormElement, _formValidationErrors) {
  const infoboxErrorDescription = form.querySelector('.cmp-infobox')?.getAttribute('data-props:error-description')

  const eventData = {
    event: 'formError', // formSuccess, formError, formEngagement
    formStatus: 'form error', // form success, form error, form engagement
    formError: infoboxErrorDescription,
  }
  trackFormGeneric(form, eventData)
}

export function initFormTracking(form: HTMLFormElement) {
  // Track click and focus on anything focusable
  // Assuming that anything clickable should be focusable and will get focused on click
  form.addEventListener('focus', _event => trackFormEngagement(form), true)
  trackFormView(form)
}

function trackFormEngagement(form: HTMLFormElement) {
  const eventData = {
    event: 'formEngagement', // formSuccess, formError, formEngagement
    formStatus: 'form engagement', // form success, form error, form engagement
  }
  trackFormGeneric(form, eventData)
}

function getReferrerOffering(): string {
  const previousUrl = pageTracker.previousUrl()
  return previousUrl ? new URL(previousUrl).pathname : ''
}

function getFormDataWithoutExcludedFields(form: HTMLFormElement): Record<string, FormDataEntryValue> {
  const formData: FormData = new FormData(form)
  const formDataEntries = { ...Object.fromEntries(formData) }

  Array.from(form).forEach((formInput: HTMLInputElement) => {
    if (excludedInputTypes.includes(formInput?.type)) {
      delete formDataEntries[formInput.name]
    } else {
      if (formInput?.type === 'radio') {
        formDataEntries[formInput.name] = (form.querySelector(`[name="${formInput.name}"]:checked`) as HTMLInputElement)?.value || null
      }
      if (formInput?.type === 'file') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formDataEntries[formInput.name] = getInputFormFiles(formInput) as any
      }
    }
  })

  return formDataEntries
}

function getFormData(form: HTMLFormElement): Record<string, string> {
  const dataset = { ...form.dataset }
  const formData = { ...getFormDataWithoutExcludedFields(form), ...getAnalyticsDataForDataLayer() }
  const referrerOffering = getReferrerOffering()
  return { ...dataset, ...formData, referrerOffering }
}

function trackFormView(form: HTMLFormElement) {
  const eventData = {
    event: 'formViewed', // formSuccess, formError, formEngagement
    formStatus: 'form viewed', // form success, form error, form engagement
  }
  trackFormGeneric(form, eventData)
}

function trackFormGeneric(form: HTMLFormElement, specificEventData) {
  const eventData = {
    event: specificEventData.event,
    formStatus: specificEventData.formStatus,
    formId: form.id || '',
    formName: form.getAttribute('name') || '',
    formTitle: form.title || form.getAttribute('name') || '',
    formAction: form.getAttribute('action') || '',
    formClasses: form.className || '',
    formData: getFormData(form),
    formError: specificEventData.formError || '',
  }

  if (isInIframe()) {
    trackIframeEvent(eventData)
  } else {
    trackEvent(eventData)
  }
}
