const ELQ_FORM_SUBMISSION_TOKEN = 'elqFormSubmissionToken'
const ELQ_ACTION_URL_FORM_DATA_ATTR = 'data-elq-action-url'
const ELQ_SITE_ID_FORM_DATA_ATTR = 'data-elq-site-id'
const ELQ_HONEYPOT_FIELD_SELECTOR = '#elq-FormHoneypotField'
const SALESFORCE_CASE_REFERER_DATA_ATTR = 'data-salesforce-case-referer'
const SALESFORCE_CASE_REFERER_SELECTOR = '#web_referer'
const SUPPORT_TYPE_REFERER_DATA_ATTR = 'data-support-type'
const SUPPORT_TYPE_SELECTOR = '#support_type'

function initElqSpamProtection(form) {
  hideHoneypotFormField(form)
  getElqFormSubmissionToken(form)
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function getElqFormSubmissionToken(form) {
  const httpRequest = new XMLHttpRequest()
  const elqActionUrl = form.getAttribute(ELQ_ACTION_URL_FORM_DATA_ATTR)
  if (elqActionUrl && form.elements.namedItem(ELQ_FORM_SUBMISSION_TOKEN)) {
    const domain = getHostName(elqActionUrl)
    const siteId = form.getAttribute(ELQ_SITE_ID_FORM_DATA_ATTR)
    if (domain && siteId) {
      const elqUrl = 'https://' + domain + '/e/formsubmittoken?elqSiteID=' + siteId
      httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          if (httpRequest.status === 200) {
            form.elements.namedItem(ELQ_FORM_SUBMISSION_TOKEN).value = httpRequest.responseText
          } else {
            form.elements.namedItem(ELQ_FORM_SUBMISSION_TOKEN).value = ''
          }
        }
      }
      httpRequest.open('GET', elqUrl, true)
      httpRequest.send()
    } else {
      form.elements.namedItem(ELQ_FORM_SUBMISSION_TOKEN).value = ''
    }
  }
}

function getHostName(action) {
  if (typeof window.URL === 'function') {
    return new window.URL(action).hostname
  } else {
    const parts = action.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)
    if (parts !== null && parts.length > 2 && typeof parts[2] === 'string' && parts[2].length > 0) {
      return parts[2]
    } else {
      return null
    }
  }
}

function hideHoneypotFormField(form) {
  const honeypotFormField = form.querySelector(ELQ_HONEYPOT_FIELD_SELECTOR)
  if (honeypotFormField) {
    honeypotFormField.style.display = 'none'
    honeypotFormField.parentElement.style.display = 'none'
  }
}

document.querySelectorAll('form[data-elq-spam-protection]').forEach(form => {
  try {
    initElqSpamProtection(form)
    appendSupportTypeValue(form)
  } catch (e) {
    console.error(e)
  }
})

document.querySelectorAll('form[data-salesforce-case-container]').forEach(form => {
  try {
    appendRefererValue(form)
    appendSupportTypeValue(form)
  } catch (e) {
    console.error(e)
  }
})

function appendRefererValue(form) {
  const refererField = form.querySelector(SALESFORCE_CASE_REFERER_SELECTOR)
  const refererValue = form.getAttribute(SALESFORCE_CASE_REFERER_DATA_ATTR)
  if (refererField && refererValue) {
    refererField.value = refererValue
  }
}

function appendSupportTypeValue(form) {
  const supportTypeField = form.querySelector(SUPPORT_TYPE_SELECTOR)
  const supportTypeValue = form.getAttribute(SUPPORT_TYPE_REFERER_DATA_ATTR)
  if (supportTypeField && supportTypeValue) {
    supportTypeField.value = decodeURI(supportTypeValue)
  }
}
