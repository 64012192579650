/**
 * Filter template
 * @param {object} filter data for the filter
 */
export const Filter = config => filter =>
  `<div class="c-mapapp__filter ${filter.property}">
      <select id="select-${filter.property}-${config.hashcode}" data-dm="${config.dmAttributes.select}${
    filter.property
  }" class="c-mapapp__filter-select" name="${filter.property}" aria-label="${filter.property}" ${filter.multiple ? ' multiple' : ''} ${
    filter.options.length === 1 ? ' disabled' : ''
  }>
        ${filter.options
          .map(
            option => `
          <option value="${option.key}" ${option.selected ? 'selected' : ''}>${option.value}</option>
        `,
          )
          .join('')}
      </select>
    </div>
  `
