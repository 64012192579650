import { isMobile } from '@site/js/utils/breakpoint'
import { pxToRem } from '@site/js/utils/px-to-rem'
import { vhToPixels } from '@site/js/utils/vh-to-px'

import { header, navigationWrapper, stickyHeaderClassName } from '../header/headerConstants'

const init = (containers: NodeListOf<HTMLElement>, desktopOnly = false) => {
    const eventTypes = ['scroll', 'load']

    eventTypes.forEach((eventType: string) => {
        document.addEventListener(eventType, () => {
            containers.forEach((container: HTMLElement) => {
                const containerHeight = getContainerHeight(desktopOnly)
                if (pxToRem(container.offsetHeight) !== containerHeight) {
                    container.style.height = containerHeight
                }
            })
        })
    })
}

const getContainerHeight = (desktopOnly: boolean) => {
    if (isMobile && desktopOnly) {
        return
    }

    const headerHeight: number = (header as HTMLElement)?.offsetHeight || 0
    const navigationWrapperHeight: number = (navigationWrapper as HTMLElement)?.offsetHeight || 0
    const mobileHeaderWrapper = (navigationWrapper?.querySelector('.cmp-navigation__link') as HTMLElement)?.offsetHeight || 0

    return header?.classList.contains(stickyHeaderClassName)
        ? isMobile
            ? `${pxToRem(vhToPixels() - mobileHeaderWrapper)}`
            : `${pxToRem(vhToPixels() - navigationWrapperHeight)}`
        : `${pxToRem(vhToPixels() - headerHeight)}`
}

document.addEventListener('DOMContentLoaded', () => {
    const peekabooContainers: NodeListOf<HTMLElement> = document.querySelectorAll('.cmp-peekaboo-video-teaser')
    const fullWidthHorizontalTeasers: NodeListOf<HTMLElement> = document.querySelectorAll('.cmp-full-width-horizontal-teaser__media')

    if (peekabooContainers.length > 0) {
        init(peekabooContainers)
    }

    if (fullWidthHorizontalTeasers.length > 0) {
        init(fullWidthHorizontalTeasers, true)
    }
})