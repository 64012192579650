const dataItemsInRowToLoadSelector = '[data-itemsinrowtoload]'
/**
 * TeaserContainer Component
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const TeaserContainer = teaserContainer => {
  const loadMoreButton = teaserContainer.querySelector('.cmp-teasercontainer__button')
  const teasers = teaserContainer.querySelectorAll('.teaser, .basicpage')
  const itemsInRowToLoad = teaserContainer.querySelector(dataItemsInRowToLoadSelector)
    ? parseInt(teaserContainer.querySelector(dataItemsInRowToLoadSelector).dataset.itemsinrowtoload, 10)
    : 0
  const rowsToLoad = teaserContainer.querySelector(dataItemsInRowToLoadSelector)
    ? parseInt(teaserContainer.querySelector('[data-rowstoload]').dataset.rowstoload, 10)
    : 0

  const hiddenButtonClassName = 'cmp-teasercontainer__button--hidden'
  const hiddenTeaserClassName = 'cmp-teaser--hidden'

  let visibleTeasersNumber = itemsInRowToLoad * rowsToLoad

  /**
   * Show necessary number of teasers on page load or on the 'Load more' button click
   */
  const updateTeasersVisibility = () => {
    teasers.forEach((teaser, index) => {
      if (teaser.querySelector('.cmp-teaser')) {
        if (index < visibleTeasersNumber) {
          teaser.classList.remove(hiddenTeaserClassName)
        } else {
          teaser.classList.add(hiddenTeaserClassName)
        }
      } else {
        visibleTeasersNumber += 1
        teaser.classList.add(hiddenTeaserClassName)
      }
    })
  }

  /**
   * Hide the 'Load more' button if all teasers are visible, otherwise display it
   */
  const updateLodeMoreButtonVisibility = () => {
    const teaserSpacingOnLastPage = 'cmp-teasercontainer--is-on-last-page'
    const isLoadMoreButtonVisible = teaserContainer.querySelectorAll('.cmp-teaser').length > itemsInRowToLoad * rowsToLoad
    if (isLoadMoreButtonVisible) {
      loadMoreButton.classList.remove(hiddenButtonClassName)
    } else {
      loadMoreButton.classList.add(hiddenButtonClassName)
      teaserContainer.classList.add(teaserSpacingOnLastPage)
    }
  }

  /**
   * Show necessary number of teasers and the 'Load more' button if it's needed
   */
  const updateVisibility = () => {
    updateTeasersVisibility()
    if (loadMoreButton) {
      updateLodeMoreButtonVisibility()
    }
  }

  /**
   * Load more teasers on the 'Load more' button click
   */
  const loadMoreTeasers = () => {
    visibleTeasersNumber += itemsInRowToLoad * rowsToLoad
    updateVisibility()
  }

  /**
   * initialize
   */
  const init = function () {
    updateVisibility()
    if (loadMoreButton) {
      loadMoreButton.addEventListener('click', () => {
        loadMoreTeasers()
        if (visibleTeasersNumber > teasers.length) {
          loadMoreButton.classList.add(hiddenButtonClassName)
        }
      })
    }
  }

  return {
    init,
  }
}

export { TeaserContainer }
