import { breakpoint } from '../../site/js/utils/breakpoint'

const selector = {
  contact: '.contact',
}

const className = {
  alignLeft: 'cmp-contact__alignment--left',
  imgLeft: 'cmp-contact__image--left',
  layoutContainerColumn: 'cmp-layoutcontainer__column',
}

// flag for media query breakpoint
const mediaQuery = {
  isMobile: false,
}

/**
 * resets height of element
 * @param {htmlElement} el
 */
export const resetHeight = el => (el.style.height = '')

/**
 * gets the highest height
 * @param {*} acc
 * @param {*} cur
 */
export const getHighest = (acc, cur) => (acc < cur.clientHeight ? cur.clientHeight : acc)

/**
 * check for left aligned policy
 * @param {htmlElement} el
 */
export const hasLeftAlignedPolicy = el => el.classList.contains(className.alignLeft) || el.classList.contains(className.imgLeft)

/**
 * check if element is inside of layout container column
 * @param {htmlElement} el
 */
export const isInsideLayoutContainerColumn = el => el.parentElement.parentElement.classList.contains(className.layoutContainerColumn)

/**
 * sets height of element
 * @param {number} height
 */
export const setHeight = (height, el) => (el.style.height = height / 16 + 'rem')

/**
 * sets Height If Not left Aligned Or If Inside Layout Container Column
 * @param {*} height
 */
export const setContactCardHeight = height => el => hasLeftAlignedPolicy(el) || !isInsideLayoutContainerColumn(el) ? null : setHeight(height, el)

/**
 * fix for contact component equal height requirement
 */
export const fixHeight = () => {
  const contacts = Array.from(document.querySelectorAll(selector.contact))

  // reset heights from previous runs
  contacts.forEach(resetHeight)

  // bails if in mobile view - contacts has the stacked style, so no need to set the height
  if (mediaQuery.isMobile) {
    return
  }

  // get highest height
  const newHeight = contacts.reduce(getHighest, 0)

  // set height - if not left aligned
  if (newHeight > 0) {
    contacts.forEach(setContactCardHeight(newHeight))
  }
}

/**
 * contact component
 */
export const contactCmp = () => {
  if (!document.querySelector(selector.contact)) {
    return
  }

  // listen for breakpoint change event - and set the is mobile flag
  breakpoint('down-md').onchange = e => (e.matches ? (mediaQuery.isMobile = true) : (mediaQuery.isMobile = false))

  // handle resize event
  window.addEventListener('resize', fixHeight)

  // initial run
  fixHeight()
}

// init
contactCmp()
