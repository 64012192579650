import { getIsMobile } from '../../site/js/utils/breakpoint'
import { TsComponentRegistry } from '../ts-component-registry'

class SpecialEventPageStage extends TsComponentRegistry.Component<HTMLDivElement> {
  specialEventContent: HTMLElement
  specialEventShapeOutside: HTMLElement

  constructor(element: HTMLDivElement) {
    super(element)
    this.specialEventContent = document.querySelector('.cmp-specialevent-stage__content')
    this.specialEventShapeOutside = document.querySelector('.cmp-specialevent-stage__content-shape-outside')
    this.init()
  }

  private init(): void {
    window.addEventListener('load', () => {
      this.adjustShapeOutsideHeight()
    })
    window.addEventListener('resize', () => {
      this.adjustShapeOutsideHeight()
    })
  }

  private adjustShapeOutsideHeight(): void {
    const isMobile = getIsMobile()

    const specialEventContentStyle = window.getComputedStyle(this.specialEventContent)

    const specialEventContentVerticalSpacing =
      Number(specialEventContentStyle.paddingBottom.slice(0, -2)) + Number(specialEventContentStyle.paddingTop.slice(0, -2))

    this.specialEventShapeOutside.style.height = '0px'
    this.specialEventShapeOutside.style.display = 'none'

    if (!isMobile) {
      this.specialEventShapeOutside.style.display = 'block'
      this.specialEventShapeOutside.style.height = `${this.specialEventContent.offsetHeight - specialEventContentVerticalSpacing}px`
    }
  }
}

const selector = '.cmp-specialevent-stage'
TsComponentRegistry.registerComponent(selector, SpecialEventPageStage)
