import './list.scss'

import { template as careers } from './templates/careers'
import { template as locations } from './templates/locations'
import { template as partners } from './templates/partners'

/**
 * Templates map
 */
const templates = {
  careers,
  locations,
  partners,
}

/** Empty state template */
const emptyState = config => `<p>${config.translations.noResults || 'The list is empty.'}</p>`

/** Error state template */
const errorState = config => `<p>${config.translations.error || 'Ups! something went wrong.'}</p>`

/**
 * creates a list component
 * @param {object} param0
 */
export const List = ({ el, state, config }) => {
  /**
   * renders the list
   * - with the correct template state
   */
  const render = () => {
    if (state.listData === 'error') {
      el.innerHTML = errorState(config)
      return
    } else if (state.listData.length === 0) {
      el.innerHTML = emptyState(config)
      return
    }
    el.innerHTML = templates[config.type](state, config)
  }

  // watch for updates
  state.watch('listData', render)

  // render initial
  render()
}
